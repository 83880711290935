import React, {useEffect, useState} from 'react';
import {connect, ReactReduxContext} from 'react-redux';
import {AccountBalance, MeetingRoom, PlayArrow, TrendingUp, Visibility} from '@material-ui/icons';
import {useContextBridge} from '@react-three/drei';
import FeatureDetails from './FeatureDetails';
import CharacterDetails from './CharacterDetails';
import MonsterDetails from './MonsterDetails';
import {Canvas} from 'react-three-fiber';
import EditScene from './EditScene';
import Hud from '../hud/Hud';
import {selectFirebaseFeatures, selectFirebaseScenes} from '../../selectors/state/firebase';
import {selectSelectedCharacter, selectSelectedFeature, selectSelectedMonster} from '../../selectors/state/edit';
import {selectAppAuthentication, selectViewMode} from '../../selectors/state/app';
import {setEditing, setSelected} from '../../actions/editActions';
import {setViewMode} from '../../actions/appActions';
import {addFeature, removeFeature, trackFeatures, untrackFeatures} from '../../actions/featureActions';
import {updateFeature} from '../../actions/featureActions';

const rangesIntersect = (lhs, rhs, index) => {
	const min = lhs.position[index].start < rhs.position[index].start ? lhs : rhs;
	const max = min === lhs ? rhs : lhs;

	return min.position[index] + min.size[index] >= max.position[index];
};
const featuresIntersect = (lhs, rhs) => {
	return rangesIntersect(lhs, rhs, 0) || rangesIntersect(lhs, rhs, 1) ||
		rangesIntersect(lhs, rhs, 2);
};
// True if the offset of feature dimension falls within the dimension range of test
const boxDimensionOverlaps = (feature, test, box, offset, dimension) => {
	const adjacentBox = feature.position[dimension] + box + offset;

	return adjacentBox >= test.position[dimension] && adjacentBox < test.position[dimension] + test.size[dimension];
};
const boxDirections = {
	east: { opposite: 'west', dimension: 0, difference: 1},
	west: { opposite: 'east', dimension: 0, difference: -1},
	north: { opposite: 'south', dimension: 1, difference: 1},
	south: { opposite: 'north', dimension: 1, difference: -1},
	ceiling: { opposite: 'floor', dimension: 2, difference: 1},
	floor: { opposite: 'ceiling', dimension: 2, difference: -1}
};
function EditView(props) {
	const ContextBridge = useContextBridge(ReactReduxContext);
	const scene = props.scenes[props.viewMode.data.sceneKey];
	const getFeatureIntersections = (selectedKey, selected) => {
		const results = {};
		const setResults = (adjacent, featureKey, featureBoxKey, testBoxPosition) => {
			const boxDirection = boxDirections[adjacent];

			testBoxPosition[boxDirection.dimension] += boxDirection.difference;

			const testBoxKey = `${testBoxPosition[0]}_${testBoxPosition[1]}_${testBoxPosition[2]}`;
			const testOverlap = boxDirection.opposite;

			results[featureKey] = { boxes: {}, ...results[featureKey]};
			results[featureKey].boxes[featureBoxKey] =
				{sides: [], ...results[featureKey].boxes[featureBoxKey]};
			results[featureKey].boxes[featureBoxKey].sides =
				[...results[featureKey].boxes[featureBoxKey].sides, adjacent];
			results[selectedKey] = { boxes: {}, ...results[selectedKey]};
			results[selectedKey].boxes[testBoxKey] =
				{sides: [], ...results[selectedKey].boxes[testBoxKey]};
			results[selectedKey].boxes[testBoxKey].sides =
				[...results[selectedKey].boxes[testBoxKey].sides, testOverlap];
		};

		Object.keys(props.features).filter(featureKey => featureKey !== props.selectedFeature).forEach(featureKey => {
			const feature = props.features[featureKey];

			if (featuresIntersect(selected, feature)) {
				const overlaps = {};
				const adjacency = {};

				for (let x = 0; x < feature.size[0]; ++x) {
					overlaps.east = boxDimensionOverlaps(feature, selected, x, 0, 0);
					overlaps.west = boxDimensionOverlaps(feature, selected, x, 0, 0);
					adjacency.east = boxDimensionOverlaps(feature, selected, x, 1, 0);
					adjacency.west = boxDimensionOverlaps(feature, selected, x, -1, 0);
					for (let y = 0; y < feature.size[1]; ++y) {
						overlaps.north = boxDimensionOverlaps(feature, selected, y, 0, 1);
						overlaps.south = boxDimensionOverlaps(feature, selected, y, 0, 1);
						adjacency.north = boxDimensionOverlaps(feature, selected, y, 1, 1);
						adjacency.south = boxDimensionOverlaps(feature, selected, y, -1, 1);
						for (let z = 0; z < feature.size[2]; ++z) {
							overlaps.ceiling = boxDimensionOverlaps(feature, selected, z, 0, 2);
							overlaps.floor = boxDimensionOverlaps(feature, selected, z, 0, 2);
							adjacency.ceiling = boxDimensionOverlaps(feature, selected, z, 1, 2);
							adjacency.floor = boxDimensionOverlaps(feature, selected, z, -1, 2);

							Object.keys(adjacency).filter(direction => adjacency[direction]).forEach(adjacent => {
								if (Object.keys(overlaps).filter(direction => direction !== adjacent &&
									direction !== boxDirections[adjacent].opposite).every(direction => overlaps[direction])) {
									setResults(adjacent, featureKey, `${x}_${y}_${z}`, [
										feature.position[0] + x - selected.position[0],
										feature.position[1] + y - selected.position[1],
										feature.position[2] + z - selected.position[2]
									]);
								}
							});
						}
					}
				}
			}
		});
		return results;
	};
	const handleCutIntersections = insert => {
		const intersections = getFeatureIntersections(props.selectedFeature, props.features[props.selectedFeature]);

		Object.keys(intersections).forEach(intersectionKey => {
			const intersection = intersections[intersectionKey];
			const boxes = props.features[intersectionKey].boxes || {};

			Object.keys(intersection.boxes).forEach(boxKey => {
				boxes[boxKey] = { ...boxes[boxKey] };
				boxes[boxKey].sides = { ...boxes[boxKey].sides };
				intersection.boxes[boxKey].sides.forEach(side => {
					boxes[boxKey].sides[side] = intersectionKey === props.selectedFeature ? insert : 'void';
				});
			});
			props.updateFeature(intersectionKey, 'boxes', boxes);
		});
	};
	const removeIntersections = featureKey => {
		const intersections = getFeatureIntersections(featureKey, props.features[props.selectedFeature]);

		Object.keys(intersections).forEach(intersectionKey => {
			const intersection = intersections[intersectionKey];
			const boxes = props.features[intersectionKey].boxes || {};

			Object.keys(intersection.boxes).forEach(boxKey => {
				if (boxes[boxKey] && boxes[boxKey].sides) {
					intersection.boxes[boxKey].sides.forEach(side => {
						delete boxes[boxKey].sides[side];
					});
				}
			});
			props.updateFeature(intersectionKey, 'boxes', boxes);
		});
	};
	const handleCutReset = () => {
		removeIntersections(props.selectedFeature);
	};
	const addIntersections = feature => {
		const intersections = getFeatureIntersections('new', feature);
		let newBoxes = {};

		Object.keys(intersections).forEach(featureKey => {
			const intersection = intersections[featureKey];
			const boxes = {};

			Object.keys(intersection.boxes).forEach(boxKey => {
				boxes[boxKey] = {sides: {}};
				intersection.boxes[boxKey].sides.forEach(side => {
					boxes[boxKey].sides[side] = 'void';
				});
			});
			if (featureKey === 'new') {
				newBoxes = boxes;
			} else {
				props.updateFeature(featureKey, 'boxes', {...props.features[featureKey].boxes, ...boxes});
			}
		});
		return newBoxes;
	};
	const handleAddFeature = feature => {
		props.addFeature({
			position: feature.position,
			scene: props.viewMode.data.sceneKey,
			size: feature.size,
			theme: scene.theme || 'default',
			type: feature.type,
			user: props.auth.key,
			boxes: addIntersections(feature),
			orientation
		});
	};
	const handleRemoveFeature = featureKey => {
		const intersections = getFeatureIntersections(featureKey, props.features[featureKey]);

		Object.keys(intersections).filter(intersectionKey => intersectionKey !== featureKey)
			.forEach(intersectionKey => {
				const intersection = intersections[intersectionKey];
				const boxes = props.features[intersectionKey].boxes || {};
	
				Object.keys(intersection.boxes).forEach(boxKey => {
					if (boxes[boxKey] && boxes[boxKey].sides) {
						intersection.boxes[boxKey].sides.forEach(side => {
							delete boxes[boxKey].sides[side];
						});
					}
				});
				props.updateFeature(intersectionKey, 'boxes', boxes);
			});
		props.removeFeature(featureKey);
	};
	const handleUpdateFeature = (featureKey, member, value) => {
		removeIntersections(featureKey);
		const boxes = addIntersections({
			...props.features[featureKey],
			[member]: value
		});
		props.updateFeature(featureKey, member, value);
		props.updateFeature(featureKey, 'boxes', boxes);
	};
	const [orientation, setOrientation] = useState('x+');
	const handleSetOrientation = orientation => {
		setOrientation(orientation);
	};

	useEffect(() => {
		props.trackFeatures(props.viewMode.data.sceneKey);
		return () => {
			props.setSelected(undefined, []);
			props.untrackFeatures(props.viewMode.data.sceneKey);
		};
	}, []);
	return (
		<div className={props.viewMode.name === 'edit' ? 'View EditView' : 'View'}>
			<div className='PlayContents'>
				{props.selectedFeature &&
					<FeatureDetails
						scene={scene} onCutReset={handleCutReset} onCutIntersections={handleCutIntersections}/>}
				{props.selectedCharacter && <CharacterDetails scene={scene} character={props.selectedCharacter}/>}
				{props.selectedMonster && <MonsterDetails scene={scene} monster={props.selectedMonster}/>}
				<Canvas shadowMap>
					<ContextBridge>
						<EditScene
							scene={scene} {...props}
							onAddFeature={handleAddFeature} onRemoveFeature={handleRemoveFeature}
							onUpdateFeature={handleUpdateFeature} onSetOrientation={handleSetOrientation}/>
					</ContextBridge>
				</Canvas>
			</div>
			<Hud title={scene.name} tools={[
				{label: 'Corridor', icon: <MeetingRoom/>, onClick: () => props.setEditing('corridor')},
				{label: 'Room', icon: <AccountBalance/>, onClick: () => props.setEditing('room')},
				{label: 'Staircase', icon: <TrendingUp/>, onClick: () => props.setEditing('staircase')},
				{divider: true},
				{label: 'Referee', icon: <Visibility/>, onClick: () => props.setViewMode('referee', props.viewMode.data)},
				{label: 'Play', icon: <PlayArrow/>, onClick: () => props.setViewMode('play', props.viewMode.data)}
			]}/>
		</div>
	);	
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	features: selectFirebaseFeatures(store),
	scenes: selectFirebaseScenes(store),
	selectedCharacter: selectSelectedCharacter(store),
	selectedFeature: selectSelectedFeature(store),
	selectedMonster: selectSelectedMonster(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	addFeature,
	removeFeature,
	setEditing,
	setSelected,
	setViewMode,
	trackFeatures,
	untrackFeatures,
	updateFeature
})(EditView);
