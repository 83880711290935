import {Canvas} from 'react-three-fiber';
import React, {useEffect, useState} from 'react';
import {connect, ReactReduxContext } from 'react-redux';
import {useContextBridge} from '@react-three/drei';
import {setViewMode} from '../../actions/appActions';
import {selectViewMode} from '../../selectors/state/app';
import {selectFirebaseCampaigns, selectFirebaseCharacters, selectFirebaseFeatures, selectFirebaseFriendCharacters,
	selectFirebaseModels, selectFirebaseMonsters, selectFirebaseScenes} from '../../selectors/state/firebase';
import {firebaseTrackDecorationModels} from '../../actions/firebaseActions';
import {addFeatureDecoration, trackFeatures, untrackFeatures} from '../../actions/featureActions';
import {addCharacterToScene, addMonsterToScene} from '../../actions/sceneActions';
import {Edit, EmojiObjects, Person, Pets, PlayArrow} from '@material-ui/icons';
import {setEditing, setSelected, setSelectedCharacter} from '../../actions/editActions';
import {selectSelectedBoxes, selectSelectedCharacter, selectSelectedFeature, selectSelectedMonster}
	from '../../selectors/state/edit';
import RefereeScene from './RefereeScene';
import Hud from '../hud/Hud';
import CharacterDetails from '../edit/CharacterDetails';
import ModalSelectMonster from '../modal/ModalSelectMonster';
import MonsterDetails from '../edit/MonsterDetails';
import ModalSelect from '../modal/ModalSelect';
import '../../css/referee.css';
import '../../css/view.css';
import Chat from '../chat/Chat';
import Flash from '../screenshot/Flash';
import Review from '../screenshot/Review';
import ScreenShot from '../screenshot/ScreenShot';
import Initiative from './Initiative';
import {Box, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	body: {
		display: 'flex',
		flexDirection: 'row',
		flexGrow: 1
	},
	center: {
		flexGrow: 1
	},
	footer: {
		display: 'flex',
		height: 150
	},
	header: { height: 64 },
	left: { display: 'flex' },
	right: { display: 'flex' },
	view: {
		display: 'flex',
		flexDirection: 'column',
		pointerEvents: 'none',
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	}
}));

function RefereeView(props) {
	// This is required to pass the context down into a canvas
	const ContextBridge = useContextBridge(ReactReduxContext);
	const addCharacter = characterKey => {
		if (props.selectedBoxes?.length >= 1) {
			props.addCharacterToScene(props.viewMode.data.sceneKey, characterKey,
				props.friendCharacters[characterKey], 'Idle', props.selectedBoxes[0], 0);
			props.setSelectedCharacter(characterKey);
		}
	};
	const [campaignCharacters, setCampaignCharacters] = useState([]);
	const [characters, setCharacters] = useState({});
	const [scene, setScene] = useState();
	const [selectMonster, setSelectMonster] = useState();
	const [selectDecoration, setSelectDecoration] = useState();
	const [selectPlayer, setSelectPlayer] = useState();
	const [tools, setTools] = useState();
	const addMonster = monster => {
		props.selectedBoxes.forEach(box => {
			props.addMonsterToScene(props.viewMode.data.sceneKey, monster, 'Idle', box, 0);
		});
	};
	const addDecoration = modelKey => {
		if (props.selectedFeature) {
			const feature = props.features[props.selectedFeature];
			const model = props.models[modelKey];

			props.selectedBoxes.forEach(box => {
				const position = [
					box[0] - feature.position[0], box[1] - feature.position[1], box[2] - feature.position[2]
				];

				props.addFeatureDecoration(props.selectedFeature, {
					position,
					type: model.path ? 'model' : 'wisp',
					model
				});
			});
		}
	};
	const classes = useStyles();

	useEffect(() => {
		props.trackFeatures(props.viewMode.data.sceneKey);
		return () => {
			props.untrackFeatures(props.viewMode.data.sceneKey);
			props.setSelectedCharacter(undefined);
			props.setSelected(undefined, []);
		};
	}, []);
	useEffect(() => {
		if (!props.scenes || !props.scenes[props.viewMode.data.sceneKey]) return;

		const scene = {
			characters: {},
			theme: 'default',
			...props.scenes[props.viewMode.data.sceneKey]
		};
		const campaign = props.campaigns[scene.campaign];

		setCampaignCharacters(Object.values(campaign.players || {}).reduce((characters, player) => {
			return characters.concat(Object.keys(player.characters || []));
		}, []));
		setCharacters(scene.characters);
		setScene(scene);
	}, [props.scenes]);
	useEffect(() => {
		if (!props.characters) return;
		if (props.viewMode.name === 'referee') {
			setTools([
				{
					label: 'Add Character',
					disabled: props.selectedBoxes.length === 0 || (campaignCharacters || []).filter(characterKey => {
						return !characters[characterKey] && props.friendCharacters[characterKey];
					}).length === 0,
					icon: <Person/>,
					onClick: () => setSelectPlayer(true)
				},
				{
					label: 'Add Monster',
					disabled: props.selectedBoxes.length === 0,
					icon: <Pets/>,
					onClick: () => setSelectMonster(true)
				},
				{
					label: 'Add Decoration',
					disabled: props.selectedBoxes.length === 0,
					icon: <EmojiObjects/>,
					onClick: () => setSelectDecoration(true)
				},
				{divider: true},
				{label: 'Edit', icon: <Edit/>, onClick: () => props.setViewMode('edit', props.viewMode.data)},
				{label: 'Play', icon: <PlayArrow/>, onClick: () => props.setViewMode('play', props.viewMode.data)}
			]);
		}
	}, [props.viewMode, props.selectedBoxes, props.characters]);
	if (!scene) return null;
	return (
		<>
			<div className='View PlayContents'>
				<Canvas id='mainCanvas' shadowMap>
					<fog attach='fog' args={['black', 6]}/>
					<ContextBridge>
						<RefereeScene scene={scene} {...props}/>
						<ScreenShot/>
					</ContextBridge>
				</Canvas>
				<Box className={classes.view}>
					<Box className={classes.header}/>
					<Box className={classes.body}>
						<Box className={classes.left}>
							<Chat/>
						</Box>
						<Box className={classes.center}/>
						<Box className={classes.right}>
							{props.selectedCharacter && <CharacterDetails scene={scene} character={props.selectedCharacter}/>}
							{props.selectedMonster && <MonsterDetails scene={scene} monster={props.selectedMonster}/>}
						</Box>
						
					</Box>
					<Box className={classes.footer}>
						<Initiative/>
					</Box>
				</Box>
			</div>
			<>
				{selectPlayer &&
				<ModalSelect
					label='Add character to scene'
					options={campaignCharacters.filter(characterKey => {
						return !characters[characterKey] && props.friendCharacters[characterKey];
					}).map(characterKey => {
						return {
							label: props.friendCharacters[characterKey].name,
							value: characterKey
						};
					})}
					confirm={addCharacter}
					cancel={() => setSelectPlayer(false)}
				/>
				}
				{selectMonster &&
				<ModalSelectMonster
					label='Add Monster to Scene' confirm={addMonster} cancel={() => setSelectMonster(false)}/>
				}
				{selectDecoration &&
				<ModalSelect
					label='Add Decoration to Scene'
					options={Object.keys(props.models)
						.filter(modelKey => props.models[modelKey].type === 'decoration')
						.map(modelKey => {
							return {
								label: props.models[modelKey].name,
								value: modelKey
							};
						})}
					confirm={addDecoration}
					cancel={() => setSelectDecoration(false)}/>
				}
				<Flash/>
				<Review prefix={scene.name}/>
			</>
			<Hud title={scene.name} tools={tools}/>
		</>
	);
}

const mapStateToProps = store => ({
	campaigns: selectFirebaseCampaigns(store),
	characters: selectFirebaseCharacters(store),
	features: selectFirebaseFeatures(store),
	friendCharacters: selectFirebaseFriendCharacters(store),
	monsters: selectFirebaseMonsters(store),
	models: selectFirebaseModels(store),
	scenes: selectFirebaseScenes(store),
	selectedBoxes: selectSelectedBoxes(store),
	selectedCharacter: selectSelectedCharacter(store),
	selectedMonster: selectSelectedMonster(store),
	selectedFeature: selectSelectedFeature(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	addFeatureDecoration,
	addCharacterToScene,
	addMonsterToScene,
	firebaseTrackDecorationModels,
	setEditing,
	setSelected,
	setSelectedCharacter,
	setViewMode,
	trackFeatures,
	untrackFeatures
})(RefereeView);
