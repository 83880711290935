import React from 'react';
import {TextField} from '@material-ui/core';
import {connect} from 'react-redux';
import Cover from './Cover';

function ContentCover(props) {
	const handleNameBlur = event => {
		props.setName(event.target.value);
	};
	const handleDescriptionBlur = event => {
		props.setDescription(event.target.value);
	};

	return (
		<Cover {...props} format='banner' editImage={true}>
			<TextField
				fullWidth onBlur={handleNameBlur} defaultValue={props.name} label='Name'
				margin='dense'
			>
				{props.name}
			</TextField>
			<TextField
				onBlur={handleDescriptionBlur} defaultValue={props.description} label='Description'
				margin='dense' fullWidth multiline rows={4}>
				{props.description}
			</TextField>
			{props.children}
		</Cover>
	);
}

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(ContentCover);
