import {Box, Button, makeStyles} from '@material-ui/core';
import React, {} from 'react';

function Modal(props) {
	const useStyles = makeStyles(theme => ({
		blur: {
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			position: 'fixed',
			backgroundColor: 'rgba(0,0,0,0.4)'
		},
		confirmCancel: {float: 'right'},
		contents: {
			zIndex: theme.zIndex.modal,
			background: theme.palette.background.paper,
			display: 'flex',
			alignItems: 'center',
			padding: theme.spacing(2),
			border: 'solid 1px',
			borderRadius: theme.shape.borderRadius,
			width: '50%'
		},
		modal: {
			zIndex: theme.zIndex.modal,
			position: 'fixed',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}
	}));
	const classes = useStyles();

	return (
		<Box className={classes.modal}>
			<div className={classes.blur} onClick={() => {
				props.cancel();
			}}/>
			<Box b={1} p={1} className={classes.contents} display='flex' flexDirection={props.flexDirection}>
				{props.children}
				<div className={classes.confirmCancel}>
					<Button color='inherit' onClick={props.cancel}>{props.cancelText || 'Cancel'}</Button>
					{props.confirm &&
						<Button color='inherit' disabled={props.disabled === true} onClick={props.confirm}>
							{props.confirmText || 'Confirm'}
						</Button>
					}
				</div>
			</Box>
		</Box>
	);
}

export default Modal;
