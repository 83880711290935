import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectAppUser} from '../../../selectors/state/app';
import Favourites from '../Favourites';
import {Tab, Tabs} from '@material-ui/core';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/running.jpg`;

const tabs = [
	{
		label: 'All favourites',
		types: [
			'campaign',
			'scenario',
			'scene',
			'shopItem',
			'model',
			'rules'
		]
	},
	{ label: 'Campaigns you are running', types: ['campaign'] },
	{ label: 'Scenarios you are running', types: ['scenario'] },
	{ label: 'Scenes you are running', types: ['scene'] },
	{ label: 'Shop items', types: ['shopItem'] },
	{ label: 'Models', types: ['model'] },
	{ label: 'Rules', types: ['rules'] }
];

function RefereePanel(props) {
	if (props.view !== 'referee') return null;

	const [tab, setTab] = useState(0);
	const handleTabChange = (event, newTab) => {
		setTab(newTab);
	};
	const toolbar = [
		<Tabs key='tabs' variant='scrollable' value={tab} onChange={handleTabChange} style={{flexGrow: 2}}>
			{tabs.map(tab => <Tab key={tab.label} label={tab.label}/>)}
		</Tabs>
	];

	useEffect(() => {
		props.setTitle('Referee', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={toolbar}/>
			<Favourites types={tabs[tab].types} setView={props.setView}/>
		</Panel>
	);
}

const mapStateToProps = store => ({
	user: selectAppUser(store)
});

export default connect(mapStateToProps, {
})(RefereePanel);
