import {connect} from 'react-redux';
import React, {useRef} from 'react';
import * as THREE from 'three';
import {selectHighlightState, selectHighlightTile} from '../../selectors/state/app';
import {selectSelectedDecoration} from '../../selectors/state/edit';
import {useGLTF} from '@react-three/drei';
import {selectFirebaseModels, selectFirebaseThemes} from '../../selectors/state/firebase';
import {Content} from '../../config';

const ceiling = new THREE.TextureLoader().load('/images/concrete.png');
const ceilingNormal = new THREE.TextureLoader().load('/images/concrete_normal.png');

const buildTheme = (() => {
	const isObject = test => typeof test === 'object';
	const merge = (target, source) => {
		const output = Object.assign({}, target);

		if (isObject(target) && isObject(source)) {
			Object.keys(source).forEach(key => {
				if (isObject(source[key])) {
					if (! (key in target)) {
						Object.assign(output, {[key]: source[key]});
					} else {
						output[key] = merge(target[key], source[key]);
					}
				} else {
					Object.assign(output, {[key]: source[key]});
				}
			});
		}
		return output;
	};

	return (key, themes) => {
		let theme = {};

		if (themes[key]) {
			theme = merge(buildTheme(themes[key].extends, themes), themes[key]);
		}
		return theme;
	};
})();
function Tile(props) {
	const material = type => {
		if (type === 'ceiling') {
			return (
				<meshPhongMaterial attach='material' normalMap={ceilingNormal} map={ceiling} shininess={10}/>
			);
		}
		return <meshStandardMaterial
			attach="material"
			color="white"
			roughness={0.3}
			metalness={0.3}
		/>;
	};
	const highlightTileRef = useRef();
	const theme = buildTheme(props.theme, props.themes);
	const stairModel = props.models[theme.models.stair];
	const stair = useGLTF(`${Content}/${stairModel.path}`);
	const modelDefaults = {rotation: [0, 0, 0], position: [0, 0, 0]};
	const models = Object.keys(props.sides).filter(sideKey => props.sides[sideKey] !== 'void').map(sideKey => {
		const highlight = {};
		const sideDetails = theme.box[sideKey];
		const side = {...modelDefaults, ...props.models[theme.models[props.sides[sideKey]]]};
		const handleMeshEvent = event => {
			if (sideKey === 'floor' && props.selectedDecoration === undefined) {
				if (event.type === 'pointerdown' && props.onPointerDown) {
					props.onPointerDown(event, props.position);
				}
				else if (event.type === 'pointerup' && props.onPointerDown) {
					props.onPointerUp(event, props.position);
				}
				else if (event.type === 'pointermove' && props.onPointerDown) {
					props.onPointerMove(event, props.position);
				}
				event.stopPropagation();
			}
		};
		let rotation = sideDetails.rotation;
		let position = sideDetails.position;
		let geometry;

		if (side.path === undefined && sideKey !== 'ceiling') {
			console.error(`Failed to find model for ${sideKey} in ${JSON.stringify(side)}`);
		} else if (sideKey === 'floor') {
			if (props.highlightState === 'highlight' && props.highlightTile &&
				props.highlightTile.every((coordinate, index) => coordinate === props.position[index])) {
				highlight.ref = highlightTileRef;
			}
			if (props.staircase) {
				rotation = stairModel.rotation || [0, 0, 0];
				position = stairModel.position || [0, 0, 0];
				if (props.orientation === 'x+') {
					rotation[2] = Math.PI / 2;
				} else if (props.orientation === 'x-') {
					rotation[2] = - Math.PI / 2;
				} else if (props.orientation === 'y+') {
					rotation[2] = Math.PI;
				} else if (props.orientation === 'y-') {
					rotation[2] = 0;
				}
				geometry = <primitive object={stair.scene.clone(true)} dispose={null}/>;
			} else {
				const model = useGLTF(`${Content}/${side.path}`);

				rotation = side.rotation.map((coordinate, index) => coordinate + rotation[index]);
				position = side.position.map((coordinate, index) => coordinate + position[index]);
				geometry = <primitive object={model.scene.clone(true)} dispose={null}/>;
			}
		} else if (sideKey === 'ceiling') {
			geometry = <planeBufferGeometry {...highlight} args={[1, 1, 32]}/>;
		} else {
			const model = useGLTF(`${Content}/${side.path}`);

			rotation = side.rotation.map((coordinate, index) => coordinate + rotation[index]);
			position = side.position.map((coordinate, index) => coordinate + position[index]);
			geometry = <primitive object={model.scene.clone(true)} dispose={null}/>;
		}
		return (
			<mesh
				label={sideKey} data={{ side: sideKey }}
				castShadow receiveShadow key={sideKey} position={position} rotation={rotation}
				onPointerDown={handleMeshEvent} onPointerMove={handleMeshEvent} onPointerUp={handleMeshEvent}>
				{geometry}
				{material(sideKey)}
			</mesh>
		);
	});

	return (
		<group position={props.position}>
			{models}
			{
				highlightTileRef.current && props.highlightState === 'highlight' &&
				<lineSegments position={[0, 0, -0.5]}>
					<edgesGeometry args={[highlightTileRef.current]} attach='geometry'/>
					<lineBasicMaterial attach='material' color='cyan'/>
				</lineSegments>
			}
		</group>
	);
}

const mapStateToProps = store => ({
	highlightState: selectHighlightState(store),
	highlightTile: selectHighlightTile(store),
	models: selectFirebaseModels(store),
	selectedDecoration: selectSelectedDecoration(store),
	themes: selectFirebaseThemes(store)
});

export default connect(mapStateToProps, {})(Tile);
