import {FIREBASE_PUSH_CHILD, FIREBASE_REMOVE, FIREBASE_TRACK} from './firebaseActions';

export function trackModels() {
	return {
		type: FIREBASE_TRACK,
		path: 'models',
		name: 'models'
	};
}

export function addUserModel(userKey, model, name) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `userModels/${userKey}`,
		child: {
			model,
			name
		}
	};
}

export function removeUserModel(userKey, model) {
	return {
		type: FIREBASE_REMOVE,
		path: `userModels/${userKey}/${model}`
	};
}
