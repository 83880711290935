import React from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import ImageLeftItem from './ImageLeftItem';
import {ArrowDownward, ArrowUpward} from '@material-ui/icons';
import {connect} from 'react-redux';
import {updateScenario} from '../../../actions/scenarioActions';
import {selectAppAuthentication} from "../../../selectors/state/app";
import {Images} from '../../../config';

const useStyles = makeStyles(() => ({
	date: {textAlign: 'right', height: 0},
	description: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		maxWidth: 300
	}
}));

export const DefaultImage = `${Images}/ui/scenario.jpg`;

function ScenarioItem(props) {
	const classes = useStyles();
	const index = props.scenarios?.findIndex(scenario => scenario.key === props.scenarioKey);
	const handleClickMoveUp = event => {
		const target = props.scenarios[index - 1];

		props.updateScenario(target.key, 'order', index);
		props.updateScenario(props.scenarioKey, 'order', index - 1);
		event.stopPropagation();
	};
	const handleClickMoveDown = event => {
		const target = props.scenarios[index + 1];

		props.updateScenario(target.key, 'order', index);
		props.updateScenario(props.scenarioKey, 'order', index + 1);
		event.stopPropagation();
	};
	const toolbar = [];

	if (props.scenarios && index !== 0) {
		toolbar.push(<IconButton key='moveUp' title='Move Up' aria-label='move up' onClick={handleClickMoveUp}>
			<ArrowUpward/>
		</IconButton>);
	}
	if (props.scenarios && index !== props.scenarios.length - 1) {
		toolbar.push(<IconButton key='moveDown' title='Move Down' aria-label='move down' onClick={handleClickMoveDown}>
			<ArrowDownward/>
		</IconButton>);
	}
	return (
		<ImageLeftItem
			image={props.scenario.image || DefaultImage} target={props.scenarioKey} onClick={props.onClick}
			toolbar={toolbar} title={props.scenario.name} description={props.scenario.description}
			date={props.scenario.created} favourite={{
				key: props.scenarioKey,
				view: props.auth.key === props.scenario.user ? 'scenario' : 'scenarioPlayer',
				data: {
					campaign: props.campaignKey,
					scenario: props.scenarioKey
				}
			}}/>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
});

export default connect(mapStateToProps, {updateScenario})(ScenarioItem);
