import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import {selectFirebaseUserModels} from '../../../selectors/state/firebase';
import Cover from '../items/Cover';
import SearchTool from '../items/SearchTool';
import ModelItem from '../items/ModelItem';
import {PersonAdd} from '@material-ui/icons';
import {IconButton} from '@material-ui/core';
import {addUserModel, removeUserModel} from '../../../actions/modelActions';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/characters.jpg`;

function ModelsPanel(props) {
	if (props.view !== 'models') return null;

	const [filter, setFilter] = useState('');
	const handleSearchChange = event => {
		setFilter(event.target.value.toLowerCase());
	};
	const handleModelClick = event => {
		props.setView('model', { model: event.currentTarget.dataset.target});
	};
	const handleAddModelClick = () => {
		props.addUserModel(props.auth.key, null, 'New Model');
	};
	const handleModelDelete = modelKey => {
		props.removeUserModel(props.auth.key, modelKey);
	};

	useEffect(() => {
		props.setTitle('Models', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover
				image={defaultImage}
				toolbar={[
					<SearchTool key={'search'} placeholder='Filter models' onChange={handleSearchChange}/>, <IconButton
						key='add' aria-label='add user model' title='Add user model' color='inherit'
						onClick={handleAddModelClick}>
						<PersonAdd/>
					</IconButton>
				]}/>
			{Object.keys(props.userModels || {})
				.filter(modelKey => filter === '' || props.userModels[modelKey].name.toLowerCase().includes(filter))
				.map(modelKey => {
					return <ModelItem
						key={modelKey} modelKey={modelKey} model={props.userModels[modelKey]} user={props.auth.key}
						onClick={handleModelClick} delete={handleModelDelete}
					/>;
				})}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {
	addUserModel,
	removeUserModel
})(ModelsPanel);
