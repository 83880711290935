import React, {useEffect, useRef} from 'react';
import {useFrame, useThree} from 'react-three-fiber';
import {Vector3} from 'three';

const intensityRange = 0.5;
const offsetRange = 0.02;
const offsetEpsilon = 0.0001;

function Lantern(props) {
	const lanternPosition = new Vector3();
	const flickerOffset = new Vector3();
	const {distance, intensity, position} = props;
	const min = intensity - intensityRange / 2;
	const max = intensity + intensityRange / 2;
	const pointLightRef = useRef();
	const lanternRef = useRef();
	const three = useThree();

	lanternPosition.fromArray(position);
	flickerOffset.fromArray(position);
	useFrame((state, delta) => {
		const intensityDelta = Math.random() - 0.5;
		const pointLight = pointLightRef.current;
		const lantern = lanternRef.current;

		if (pointLight) {
			pointLight.intensity = Math.min(max, Math.max(min, pointLight.intensity + intensityDelta));
		}
		if (lantern) {
			if (lantern.position.distanceToSquared(flickerOffset) < offsetEpsilon) {
				flickerOffset.random().multiplyScalar(offsetRange).add(lanternPosition);
			} else {
				lantern.position.lerp(flickerOffset, delta);
			}
		}
	});
	useEffect(() => {
		const id = pointLightRef.current.uuid;

		if (three.userData === undefined) {
			three.userData = {};
		}
		if (three.userData.lights === undefined) {
			three.userData.lights = {};
		}
		three.userData.lights[id] = {
			position: pointLightRef.current.getWorldPosition(new Vector3()),
			light: pointLightRef.current
		};
		return () => {
			delete three.userData.lights[id];
		};
	}, []);
	return (
		<group ref={lanternRef} position={position} >
			<mesh {...props}>
				<sphereBufferGeometry args={props.size || [0.005, 16, 16]}/>
				<meshBasicMaterial
					attach="material" 
					color={props.color}
					flatShading={true}
				/>
			</mesh>
			<mesh {...props}>
				<sphereBufferGeometry args={props.size || [0.01, 16, 16]}/>
				<meshBasicMaterial
					transparent={true}
					opacity={0.2}
					attach="material" 
					color={props.color}
					flatShading={true}
				/>
			</mesh>
			<pointLight
				ref={pointLightRef}
				distance={distance}
				color={props.color}
				castShadow={false}
				intensity={intensity}
				shadow-mapSize-height={1024}
				shadow-mapSize-width={1024}
				shadow-camera-near={0.01}
				shadow-camera-far={50}
				shadow-camera-left={-100}
				shadow-camera-right={100}
				shadow-camera-top={100}
				shadow-camera-bottom={-100}
			/>
		</group>
	);
}

export default Lantern;
