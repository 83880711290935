import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectFirebaseUserFiles} from '../../../selectors/state/firebase';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Grid, IconButton, makeStyles, Slider, TextField} from '@material-ui/core';
import {updatePicture} from '../../../actions/firebaseActions';
import {Crop32, Crop75, CropPortrait, Face, Settings, Undo, ZoomIn, ZoomOut} from '@material-ui/icons';

const useStyles = makeStyles(() => ({
	slider: {color: 'white'}
}));
const defaultScale = 1;
const defaultTranslate = {x: 0, y: 0};

function PicturePanel(props) {
	if (props.view !== 'picture') return null;

	const classes = useStyles();
	const formats = {
		banner: {
			next: 'full',
			nextIcon: <Crop32/>,
			nextTitle: 'Full format'
		},
		full: {
			nextIcon: <CropPortrait/>,
			next: 'tile',
			nextTitle: 'Avatar format'
		},
		tile: {
			nextIcon: <Face/>,
			next: 'avatar',
			nextTitle: 'Tile format'
		},
		avatar: {
			nextIcon: <Crop75/>,
			next: 'banner',
			nextTitle: 'Banner format'
		}
	};
	const defaultImageScale = {
		avatar: defaultScale,
		banner: defaultScale,
		full: defaultScale,
		tile: defaultScale
	};
	const defaultImageTranslate = {
		avatar: defaultTranslate,
		banner: defaultTranslate,
		full: defaultTranslate,
		tile: defaultTranslate
	};
	const picture = props.userFiles[props.data];
	const [format, setFormat] = useState('banner');
	const [settings, setSettings] = useState(false);
	const [scale, setScale] = useState({...defaultImageScale, ...picture.scale});
	const [translate, setTranslate] = useState({...defaultImageTranslate, ...picture.translate});
	const handleNameBlur = event => {
		props.updatePicture(props.auth.key, props.data, 'name', event.currentTarget.value);
	};
	const handleClickSetFormat = () => {
		setFormat(formats[format].next);
	};
	const handleClickSettings = () => {
		setSettings(! settings);
	};
	const handleScaleChangeCommitted = () => {
		props.updatePicture(props.auth.key, props.data, 'scale', scale);
	};
	const handleTranslateSet = newValue => {
		props.updatePicture(props.auth.key, props.data, 'translate', {
			...translate,
			[format]: newValue
		});
	};
	const handleScaleChange = (event, newValue) => {
		setScale({
			...scale,
			[format]: newValue
		});
	};
	const handleClickReset = () => {
		props.updatePicture(props.auth.key, props.data, 'translate', { ...translate, [format]: defaultTranslate});
		props.updatePicture(props.auth.key, props.data, 'scale', { ...scale, [format]: defaultScale});
	};
	const toolbar = [
		<IconButton
			key='reset' aria-label='reset' title='Reset'
			color='inherit' onClick={handleClickReset}>
			<Undo/>
		</IconButton>,
		<IconButton
			key='setSettings' aria-label='settings' title='Settings'
			color='inherit' onClick={handleClickSettings}>
			<Settings/>
		</IconButton>,
		<IconButton
			key='setFormat' aria-label={formats[format].nextTitle} title={formats[format].nextTitle}
			color='inherit' onClick={handleClickSetFormat}>
			{formats[format].nextIcon}
		</IconButton>
	];

	if (settings) {
		toolbar.unshift(
			<Grid key='scale' container spacing={2}>
				<Grid item><ZoomOut/></Grid>
				<Grid item xs>
					<Slider
						className={classes.slider} value={scale[format]} min={1} max={4} step={0.1}
						onChangeCommitted={handleScaleChangeCommitted} onChange={handleScaleChange}/>
				</Grid>
				<Grid item><ZoomIn/></Grid>
			</Grid>
		);
	}
	useEffect(() => {
		const picture = props.userFiles[props.data];

		setScale({...defaultImageScale, ...picture.scale});
		setTranslate({...defaultImageTranslate, ...picture.translate});
	}, [props.userFiles, format]);
	useEffect(() => {
		props.setTitle(picture.name, ['gallery', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<Cover
				image={props.data} format={format} scale={scale[format]} translate={translate[format]}
				toolbar={toolbar} edit={settings} onSetTranslate={handleTranslateSet}>
				<TextField
					fullWidth onBlur={handleNameBlur} defaultValue={picture.name} label='Name'
					margin='dense'
				>
					{props.name}
				</TextField>
			</Cover>
		</Panel>
	);
}
const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	userFiles: selectFirebaseUserFiles(store)
});

export default connect(mapStateToProps, {
	updatePicture
})(PicturePanel);
