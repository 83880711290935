import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCampaigns} from '../../../selectors/state/firebase';
import {selectAppAuthentication} from '../../../selectors/state/app';
import Panel from './Panel';
import CampaignItem from '../items/CampaignItem';
import Cover from '../items/Cover';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/running.jpg`;

function PlayingPanel(props) {
	if (props.view !== 'playing') return null;

	const handleCampaignClick = event => {
		props.setView('campaignPlayer', event.currentTarget.dataset.target);
	};

	useEffect(() => {
		props.setTitle('Campaigns you are playing in', 'player');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={[]}/>
			{Object.keys(props.campaigns || {})
				.filter(campaignKey => props.campaigns[campaignKey].user !== props.auth.key)
				.map(campaignKey =>
					<CampaignItem
						key={campaignKey} campaignKey={campaignKey} campaign={props.campaigns[campaignKey]}
						onClick={handleCampaignClick}

					/>
				)}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store)
});

export default connect(mapStateToProps, {})(PlayingPanel);
