import React, {useRef} from 'react';
import {AppBar, Divider, IconButton, Menu, MenuItem, Toolbar, Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {firebaseSignOut} from '../../actions/firebaseActions';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {AccountCircle, AddAPhoto, Home} from '@material-ui/icons';
import {setViewMode, takeScreenShot} from '../../actions/appActions';
import ModalAccountDetails from '../modal/ModalAccountDetails';
import {selectAppAuthentication, selectViewMode} from '../../selectors/state/app';
import {Stats} from '@react-three/drei';
import '../../css/hud.css';

const useStyles = makeStyles((theme) => ({
	iconButton: {'&:disabled': { opacity: 0.5 }},
	menuButton: {marginRight: theme.spacing(2),},
	root: {flexGrow: 1,},
	stats: { position: 'relative!important' },
	title: {flexGrow: 1,}
}));

function Hud(props) {
	const [openMenu, setOpenMenu] = React.useState('');
	const [anchorMenu, setAnchorMenu] = React.useState(null);
	const classes = useStyles();
	const handleMenuClick = (event, label) => {
		setOpenMenu(label);
		setAnchorMenu(event.currentTarget);
	};
	const handleMenuClose = () => {
		setOpenMenu('');
		setAnchorMenu(null);
	};
	const handleClickHome = () => {
		props.setViewMode('lobby');
	};
	const menuIcon = (menuId, label, icon, options) => {
		return (
			<div key={label}>
				<IconButton
					className={classes.iconButton} color='inherit' aria-label={label} aria-controls={menuId}
					aria-haspopup="true" onClick={event => handleMenuClick(event, label)}>
					{icon}
				</IconButton>
				<Menu
					id={menuId} anchorEl={anchorMenu} keepMounted anchorOrigin={{ vertical: 'top', horizontal: 'right', }}
					transformOrigin={{ vertical: 'top', horizontal: 'right', }}
					open={openMenu === label} onClose={handleMenuClose}
				>
					{options.map(option => {
						return (
							<MenuItem key={option.label} color='inherit' onClick={() => {
								handleMenuClose();
								option.onClick(option.value);
							}}>
								{option.label}
							</MenuItem>
						);
					})}
				</Menu>
			</div>
		);
	};
	const buildTool = (tool, index) => {
		if (tool.divider) {
			return <Divider key={'divider' + index} orientation='vertical' flexItem/>;
		}
		if (tool.menu) {
			return menuIcon(tool.label, tool.label, tool.icon, tool.menu);
		}
		return (
			<IconButton
				key={tool.label} className={classes.iconButton} color='inherit'
				onClick={tool.onClick} title={tool.label} disabled={tool.disabled}>
				{tool.icon}
			</IconButton>
		);
	};
	const tools = props.tools ? props.tools.map(buildTool) : [];
	const toolBar = useRef();
	const handlePhotoClick = () => {
		props.takeScreenShot();
	};

	return (
		<AppBar className='Hud' position='static'>
			<Toolbar ref={toolBar}>
				<IconButton
					color='inherit'
					edge='start' className={classes.menuButton} aria-label='menu' onClick={handleClickHome}>
					<Home/>
				</IconButton>
				<Typography className={classes.title} variant='h5'>{props.title}</Typography>
				<Stats className={classes.stats} parent={toolBar}/>
				{tools}
				<Divider orientation='vertical' flexItem/>
				<IconButton color='inherit' className={classes.iconButton} onClick={handlePhotoClick}>
					<AddAPhoto/>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	firebaseSignOut,
	setViewMode,
	takeScreenShot
})(Hud);
