import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCampaigns} from '../../../selectors/state/firebase';
import Panel from './Panel';
import CampaignItem from '../items/CampaignItem';
import Cover from '../items/Cover';
import {IconButton} from '@material-ui/core';
import {createCampaign} from '../../../actions/campaignActions';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Queue} from '@material-ui/icons';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/running.jpg`;

function RunningPanel(props) {
	if (props.view !== 'running') return null;

	const handleCampaignClick = event => {
		props.setView('campaign', event.currentTarget.dataset.target);
	};
	const handleAddCampaign = () => {
		props.createCampaign('New Campaign');
	};

	useEffect(() => {
		props.setTitle('Campaigns you are running', 'referee');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={[
				<IconButton key='addCampaign' color='inherit' onClick={handleAddCampaign} title='Add Campaign'>
					<Queue/>
				</IconButton>
			]}/>
			{Object.keys(props.campaigns || {})
				.filter(campaignKey => props.campaigns[campaignKey].user === props.auth.key)
				.map(campaignKey => 
					<CampaignItem
						key={campaignKey} campaignKey={campaignKey} campaign={props.campaigns[campaignKey]}
						onClick={handleCampaignClick}
					/>
				)}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store)
});

export default connect(mapStateToProps, {
	createCampaign
})(RunningPanel);
