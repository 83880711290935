import React from 'react';
import {connect} from 'react-redux';
import {AppBar as MuiAppBar, Badge, IconButton, Toolbar, Typography} from '@material-ui/core';
import {AccountCircle, Menu, Notifications} from '@material-ui/icons';
import {firebaseSignOut} from '../../actions/firebaseActions';
import {styled} from '@material-ui/core/styles';

const AppBar = styled(MuiAppBar)(({ theme, open, drawer }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawer,
		width: `calc(100% - ${drawer}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

function TitleBar(props) {
	const handleMessagesClick = () => props.setView('messages');
	const handleShowAccount = () => {
		props.firebaseSignOut();
	};

	return (
		<AppBar position='absolute' open={props.open} drawer={props.drawerWidth}>
			<Toolbar style={{ pr: '24px' }}>
				{! props.open && <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={props.toggleOpen}>
					<Menu/>
				</IconButton>}
				<Typography component="h1" variant="h6" color="inherit" noWrap style={{ flexGrow: 1 }}>
					{props.title}
				</Typography>
				<IconButton color="inherit" onClick={handleMessagesClick}>
					{props.messages ?
						<Badge badgeContent={Object.keys(props.messages).length} color="secondary">
							<Notifications/>
						</Badge> :
						<Notifications/>
					}
				</IconButton>
				<IconButton color="inherit" onClick={handleShowAccount}>
					<AccountCircle/>
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
const mapStateToProps = store => ({});

export default connect(mapStateToProps, {firebaseSignOut})(TitleBar);
