import * as THREE from 'three';
import React from 'react';

const turbidity = 2;
const maxwellCoefficient = 0.005;
const sunlight = new THREE.Vector3(680E-9, 550E-9, 450E-9);
const simplifiedRayleigh = new THREE.Vector3(0.0005 / 94.0, 0.0005 / 40.0, 0.0005 / 18.0);
const rayleighAtmosphereHeight = 8.4E3;
const simplifiedMaxwell = new THREE.Vector3(0.686, 0.678, 0.666);
const maxwellAtmosphereHeight = 1.25E3;
const sunlightDegrees = new THREE.Vector3(Math.PI * 2, Math.PI * 2, Math.PI * 2).divide(sunlight);
const turbidityFactor = 0.2 * turbidity * 10.0e-18;
const maxwellFactor = sunlightDegrees.multiply(sunlightDegrees).multiply(simplifiedMaxwell)
	.multiplyScalar(0.434 * turbidityFactor * Math.PI)
	.multiplyScalar(maxwellCoefficient);
export default function Sunlight(props) {
	const inverseSDenominator = 1.0 / (Math.cos(props.zenith) +
		0.15 * Math.pow(93.885 - (props.zenith * 180.0 / Math.PI), -1.253));
	const elevation = (Math.PI / 2) - props.zenith;
	const minElevation = 0.5;
	const sunFade = 1.0 - Math.min(Math.max(Math.exp(elevation + minElevation) - 1, 0.0), 1.0);
	const sunStrength = 1.0 - sunFade;
	const reflectedLight = 0.6;
	const raleighScatter = simplifiedRayleigh.clone().multiplyScalar(rayleighAtmosphereHeight * inverseSDenominator);
	const maxwellScatter = simplifiedMaxwell.clone().multiply(maxwellFactor)
		.multiplyScalar(maxwellAtmosphereHeight * inverseSDenominator);
	const combinedExtinction = new THREE.Vector3(
		1 - Math.exp(- raleighScatter.x - maxwellScatter.x),
		1 - Math.exp(- raleighScatter.y - maxwellScatter.y),
		1 - Math.exp(- raleighScatter.z - maxwellScatter.z)
	);
	const extinctionColor = new THREE.Color(combinedExtinction.x, combinedExtinction.y, combinedExtinction.z);
	const sunColor = new THREE.Color().sub(extinctionColor).multiplyScalar(10);
	const skyColor = new THREE.Color().add(extinctionColor);

	return (
		<>
			<hemisphereLight args={[sunColor, skyColor, sunStrength * reflectedLight]}/>
			<directionalLight
				args={[sunColor, sunStrength]} position={props.position.toArray()}
			/>
		</>
	);
}
