import * as THREE from 'three';
import Lantern from '../../features/Lantern';
import {OrbitControls, PerspectiveCamera} from '@react-three/drei';
import React, {useEffect, useRef, useState} from 'react';
import {useFrame} from 'react-three-fiber';
import {setCharacterAnimation, setCharacterDetails, setCharacterPosition, setCharacterRotation}
	from '../../../actions/characterActions';
import {connect} from 'react-redux';
import {selectViewMode} from '../../../selectors/state/app';
import {FloorHeight} from '../../features/Features';
import {selectFirebaseCharacters, selectFirebaseModels} from '../../../selectors/state/firebase';
import {DefaultAnimations} from '../AnimatedModel';

const orbitRangeMax = 6;
const orbitRangeMin = 1.5;
const epsilon = 0.001;
const rotationAxis = new THREE.Vector3(0, 0, 1);
const forwardVector = new THREE.Vector3(0, 1, 0);
const zeroQuaternion = new THREE.Quaternion(0, 0, 0, 0);
const defaultHeadOffset = new THREE.Vector3(0.04, 0.25, 0.34);
const orbitOffset = new THREE.Vector3(0, 0, 0.2);
const orbitTarget = orbitOffset.clone();
const cameraOffset = new THREE.Vector3(2, 2, 2);
const avatarPosition = new THREE.Vector3();
const quaternion = new THREE.Quaternion();

function AvatarController(props) {
	const [ animation, setAnimation ] = useState('Idle');
	const [ avatarGoal, setAvatarGoal ] = useState();
	const [ progress, setProgress ] = useState(0);
	const [ clock ] = useState(new THREE.Clock());
	const [ firstPerson, setFirstPerson ] = useState(false);
	const groupRef = useRef();
	const controlsRef = useRef();
	const cameraRef = useRef();
	const lanternOffset = [ 0.3, 0.3, 0.2 ];
	const leftTurn = {
		animation: 'Walking',
		speed: 2,
		distance: 0,
		rotation: Math.PI * 0.5
	};
	const rightTurn = {
		animation: 'Walking',
		speed: 2,
		distance: 0,
		rotation: -Math.PI * 0.5
	};
	const forward = {
		animation: 'Walking',
		speed: 1,
		distance: 1,
		rotation: 0
	};
	const reverse = {
		animation: 'Walking',
		speed: 1,
		distance: -1,
		rotation: 0
	};
	const goalPosition = new THREE.Vector3();
	const goalOffset = new THREE.Vector3();
	const goalQuaternion = new THREE.Quaternion();
	const idleAnimation = 'Idle';
	const setOrbitTarget = () => {
		if (groupRef.current) {
			orbitTarget.copy(orbitOffset);
			groupRef.current.localToWorld(orbitTarget);
			controlsRef.current.target.copy(orbitTarget);
			controlsRef.current.update();
		}
	};

	useEffect(() => {
		const keydown = event => {
			const updateGoal = (intent) => {
				const rotation = (props.details.rotation + intent.rotation) % (2 * Math.PI);

				setProgress(0);
				goalQuaternion.setFromAxisAngle(rotationAxis, rotation);
				goalPosition.fromArray(props.details.position);
				if (intent.distance !== 0) {
					goalOffset.copy(forwardVector).multiplyScalar(intent.distance)
						.applyAxisAngle(rotationAxis, props.details.rotation).add(goalPosition).round();

					const goalFeature = props.getTileDetails(goalPosition, goalOffset);

					if (goalFeature) {
						goalPosition.copy(goalOffset);
						goalPosition.z = goalFeature.position[2] + FloorHeight(goalFeature,
							goalPosition.x - goalFeature.position[0],
							goalPosition.y - goalFeature.position[1]);
					}
				}
				setAvatarGoal({
					animation: intent.animation,
					position: goalPosition,
					rotation: rotation,
					quaternion: goalQuaternion,
					speed: intent.speed
				});
				props.setCharacterAnimation(props.viewMode.data.sceneKey, props.characterKey, intent.animation || 'Walking');
				clock.start();
			};
			if (animation !== idleAnimation)
				return;

			if (event.keyCode === 37 || event.keyCode === 65) { // Left / A
				updateGoal(leftTurn);
			} else if (event.keyCode === 38 || event.keyCode === 87) { // Up / W
				updateGoal(forward);
			} else if (event.keyCode === 39 || event.keyCode === 68) { // Right / D
				updateGoal(rightTurn);
			} else if (event.keyCode === 40 || event.keyCode === 83) { // Down / S 
				updateGoal(reverse);
			} else if (event.keyCode === 17) {
				if (! firstPerson) {
					setFirstPerson(true);
					controlsRef.current.minDistance = epsilon;
					controlsRef.current.maxDistance = epsilon;
					cameraRef.current.position.copy(forwardVector).multiplyScalar(epsilon)
						.applyAxisAngle(rotationAxis, props.details.rotation + Math.PI).add(controlsRef.current.target);
				} else {
					setFirstPerson(false);
					controlsRef.current.minDistance = orbitRangeMin;
					controlsRef.current.maxDistance = orbitRangeMax;
					cameraRef.current.position.copy(controlsRef.current.target).add(cameraOffset);
				}
			} else if (event.keyCode >= 49 && event.keyCode <= 58) {
				const numberKey = event.keyCode - 48;
				const animation = Object.keys(DefaultAnimations).find(animation => {
					return DefaultAnimations[animation].shortcut === numberKey;
				}) || 'Idle';

				props.setCharacterAnimation(props.viewMode.data.sceneKey, props.characterKey, animation);
			}
		};

		window.addEventListener('keydown', keydown);
		return () => {
			window.removeEventListener('keydown', keydown);
		};
	}, [props.details, props.model]);
	useEffect(() => {
		if (! props.model) return;
		if (props.model.features?.head) {
			orbitOffset.fromArray(props.model.features.head.position);
		} else {
			orbitOffset.copy(defaultHeadOffset);
		}
		setOrbitTarget();
	}, [props.model]);
	useEffect(() => {
		avatarPosition.fromArray(props.details.position);
		quaternion.copy(zeroQuaternion);
		quaternion.setFromAxisAngle(rotationAxis, props.details.rotation);
	}, [props.details]);
	useFrame((state, delta) => {
		if (avatarGoal) {
			const step = avatarGoal.speed * delta;

			if (1 - Math.abs(avatarGoal.quaternion.dot(groupRef.current.quaternion)) <
				epsilon && groupRef.current.position.distanceTo(avatarGoal.position) < epsilon) {
				groupRef.current.quaternion.copy(avatarGoal.quaternion);
				groupRef.current.position.copy(avatarGoal.position);
				setOrbitTarget();
				setAnimation(idleAnimation);
				setAvatarGoal(undefined);
				props.setCharacterDetails(props.viewMode.data.sceneKey, props.characterKey,
					avatarGoal.position.toArray(),
					avatarGoal.rotation,
					'Idle');
				if (firstPerson) {
					cameraRef.current.position.copy(forwardVector).multiplyScalar(- epsilon)
						.applyQuaternion(avatarGoal.quaternion).add(controlsRef.current.target);
				}
			} else {
				groupRef.current.quaternion.copy(quaternion);
				groupRef.current.quaternion.slerp(avatarGoal.quaternion, progress);
				groupRef.current.position.copy(avatarPosition);	
				groupRef.current.position.lerp(avatarGoal.position, progress);
				if (firstPerson) {
					cameraRef.current.position.copy(forwardVector).multiplyScalar(- epsilon)
						.applyQuaternion(groupRef.current.quaternion).add(controlsRef.current.target);
				}
				setOrbitTarget();
			}
			setProgress(Math.min(progress + step, 1));
			controlsRef.current.update();
		}
		setOrbitTarget();
	});
	return (
		<>
			<group ref={groupRef} {...props} position={props.details.position} quaternion={quaternion.toArray()}>
				<Lantern intensity={2} distance={6} position={lanternOffset}/>
				{ props.children }
			</group>
			<OrbitControls
				camera={cameraRef} ref={controlsRef} target={orbitTarget} enableZoom={true} enablePan={false}
				minDistance={orbitRangeMin} maxDistance={orbitRangeMax}/>
			<PerspectiveCamera
				ref={cameraRef} fov={55} aspec={window.innerWidth / window.innerHeight}
				near={0.1} far={12} up={[0, 0, 1]} position={cameraOffset} makeDefault/>
		</>
	);

}

const mapStateToProps = store => ({
	characters: selectFirebaseCharacters(store),
	models: selectFirebaseModels(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	setCharacterDetails,
	setCharacterPosition,
	setCharacterRotation,
	setCharacterAnimation
})(AvatarController);
