import {Select, MenuItem, FormControl, InputLabel, makeStyles} from '@material-ui/core';
import React, {useState} from 'react';
import Modal from './Modal';

function ModalSelect(props) {
	const useStyles = makeStyles(theme => ({select: {flexGrow: 1}}));
	const classes = useStyles();
	const [value, setValue] = useState(props.default);
	const confirm = () => {
		props.cancel();
		props.confirm(value);
	};
	const onChange = event => {
		setValue(event.target.value);
	};

	return (
		<Modal cancel={props.cancel} disabled={!value} confirm={confirm}>
			<FormControl className={classes.select}>
				<InputLabel id='ModalSelectLabel'>{props.label}</InputLabel>
				<Select
					labelId='ModalSelectLabel' label={props.label} onChange={onChange} value={value || ''}>
					<MenuItem key='' value=''><em>None</em></MenuItem>
					{props.options.map(option => {
						return (
							<MenuItem key={option.value} value={option.value}>
								{option.label}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>
		</Modal>
	);
}

export default ModalSelect;
