import React, {useEffect} from 'react';
import {selectFirebaseModels, selectAvailableShopItems} from '../../../selectors/state/firebase';
import {connect} from 'react-redux';
import Panel from './Panel';
import Cover from '../items/Cover';
import {Button} from '@material-ui/core';
import {buyModel, buyRules} from '../../../actions/firebaseActions';
import {selectAppAuthentication, selectAppUser} from '../../../selectors/state/app';
import ShopItemAdmin from '../admin/ShopltemAdmin';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/running.jpg`;

function ShopItemPanel(props) {
	if (props.view !== 'shopItem') return null;

	const shopItem = props.shopItems[props.data];
	const handleBuyClick = () => {
		if (shopItem.type === 'model') {
			const model = props.models[shopItem.model];

			props.buyModel(props.auth.key, props.data, shopItem, model);
			props.setView('shop');
		} else if (shopItem.type === 'rules') {
			props.buyRules(props.auth.key, props.data, shopItem, shopItem.rules);
			props.setView('shop');
		}
	};

	useEffect(() => {
		props.setTitle(shopItem.name, 'shop');
	}, []);
	return (
		<Panel visible={true}>
			<Cover format='banner' image={shopItem.image || defaultImage} name={shopItem.name} toolbar={[
				<Button key='buy' variant='contained' color='secondary' onClick={handleBuyClick}>
					{`Buy for ${shopItem.price}sp`}
				</Button>
			]}/>
			<ShopItemAdmin shopItemKey={props.data} shopItem={shopItem}/>
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	shopItems: selectAvailableShopItems(store),
	user: selectAppUser(store)
});

export default connect(mapStateToProps, {
	buyModel,
	buyRules
})(ShopItemPanel);
