
export const EDIT_SET_EDITING = 'EDIT_SET_EDITING';

export function setEditing(adding) {
	return {
		type: EDIT_SET_EDITING,
		data: {
			adding: adding,
			state: 'placing'
		}
	};
}

export function setEditingState(state) {
	return {
		type: EDIT_SET_EDITING,
		data: { state: state }
	};
}

export function setSelected(feature, boxes) {
	return {
		type: EDIT_SET_EDITING,
		data: { selectedFeature: feature, selectedBoxes: boxes }
	};
}

export function setSelectedCharacter(selectedCharacter) {
	return {
		type: EDIT_SET_EDITING,
		data: { selectedCharacter }
	};
}

export function setSelectedDecoration(selectedDecoration, decorationFeature) {
	if (selectedDecoration === undefined) {
		return {
			type: EDIT_SET_EDITING,
			data: {selectedDecoration: undefined}
		};
	}
	return {
		type: EDIT_SET_EDITING,
		data: {
			selectedDecoration: {
				feature: decorationFeature,
				decoration: selectedDecoration
			}
		}
	};
}

export function setSelectedMonster(selectedMonster) {
	return {
		type: EDIT_SET_EDITING,
		data: { selectedMonster }
	};
}
