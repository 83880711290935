import React from 'react';
import {ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup} from '@material-ui/core';
import {selectFirebaseModels, selectFirebaseUserModels} from '../../../selectors/state/firebase';
import {connect} from 'react-redux';
import {DefaultImage} from '../items/ModelItem';
import Avatar from '../Avatar';
import {selectAppAdmin} from '../../../selectors/state/app';

function ModelList(props) {
	const models = (props.admin ? props.models : props.userModels) || {};

	return (
		<RadioGroup value={props.value || ''} onChange={props.onChange}>
			{Object.keys(models)
				.filter(key => {
					return props.filter === undefined || props.filter === '' ||
						models[key].name.toLowerCase().includes(props.filter);
				})
				.map(key => {
					const model = models[key];

					return <ListItem key={key} alignItems="flex-start">
						<ListItemAvatar>
							<Avatar alt={model.name} image={model.image || DefaultImage}/>
						</ListItemAvatar>
						<ListItemText primary={model.name}/>
						<Radio value={key}/>
					</ListItem>;
				})}
		</RadioGroup>
	);
}

const mapStateToProps = store => ({
	admin: selectAppAdmin(store),
	models: selectFirebaseModels(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {
})(ModelList);
