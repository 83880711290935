import * as THREE from 'three';
import React from 'react';
import {connect} from 'react-redux';
import {selectSelectedCharacter} from '../../selectors/state/edit';
import {Ring} from '@react-three/drei';

const rotationAxis = new THREE.Vector3(0, 0, 1);
const zeroQuaternion = new THREE.Quaternion(0, 0, 0, 0);

function MonsterController(props) {
	return (
		<group {...props} quaternion={zeroQuaternion.setFromAxisAngle(rotationAxis, props.rotation).toArray()}>
			{ props.children }
			{ props.selectedCharacter && props.selectedCharacter === props.character &&
				<Ring args={[0.2, 0.5, 16]} position={[0,0,-0.49]}/>}
		</group>
	);

}

const mapStateToProps = store => ({
	selectedCharacter: selectSelectedCharacter(store),
});

export default connect(mapStateToProps, { })(MonsterController);
