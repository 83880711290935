import * as THREE from 'three';
import {Sky as ThreeSky} from 'three/examples/jsm/objects/Sky';
import {useThree} from 'react-three-fiber';

const sun = new THREE.Vector3();
export default function Sky(props) {
	const { scene } = useThree();
	const sky = new ThreeSky();
	const uniforms = sky.material.uniforms;
	const phi = THREE.MathUtils.degToRad(props.elevation);
	const theta = THREE.MathUtils.degToRad(props.azimuth);

	sun.setFromSphericalCoords(1, phi, theta);
	sky.scale.setScalar(450000);
	uniforms.up.value.set(0, 0, 1);
	uniforms.turbidity.value = 10;
	uniforms.rayleigh.value = 4;
	uniforms.mieCoefficient.value = 0.005;
	uniforms.mieDirectionalG.value = 0.7;
	uniforms.sunPosition.value.copy(sun);
	scene.add(sky);
	return null;
}
