import {Box, Divider, makeStyles, Typography} from '@material-ui/core';
import React, { } from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCharacters, selectFirebaseFriendCharacters} from '../../selectors/state/firebase';

const useStyles = makeStyles(theme => ({
	body: {
		color: theme.palette.common.white,
		display: 'flex',
		pointerEvents: 'all',
		width: 330
	}
}));

function CharacterDetails(props) {
	const characterDetails = props.scene.characters[props.character];
	const character = props.friendCharacters[props.character];
	const classes = useStyles();

	return (
		<Box className={classes.body} m={1} p={1} border={1} borderRadius={16}>
			<Box p={1}>
				<Typography variant='h6'>{character.name}</Typography>
				<Typography variant='body1'>Animation: {characterDetails?.animation}</Typography>
			</Box>
			<Divider/>
		</Box>
	);
}

const mapStateToProps = store => ({
	characters: selectFirebaseCharacters(store),
	friendCharacters: selectFirebaseFriendCharacters(store)
});

export default connect(mapStateToProps, {})(CharacterDetails);
