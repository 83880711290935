import React, {} from 'react';
import {makeStyles} from '@material-ui/core';
import {connect} from 'react-redux';
import {selectScreenShot} from '../../selectors/state/app';

const useStyles = makeStyles(() => ({
	flash: {
		backgroundColor: 'white',
		height: '100%',
		pointerEvents: 'none',
		position: 'absolute',
		top: 0,
		width: '100%',
		zIndex: 2000
	},
	off: { opacity: 0, transition: 'opacity 0.5s ease-out' },
	on: { opacity: 1 }
}));

function Flash(props) {
	const classes = useStyles();
	const className = [classes.flash, props.screenShot.state === 'pending' ? classes.on : classes.off].join(' ');

	return <div className={className}/>;
}
const mapStateToProps = store => ({screenShot: selectScreenShot(store)});

export default connect(mapStateToProps, {})(Flash);
