import React, {useRef, useState} from 'react';
import {OrbitControls, PerspectiveCamera } from '@react-three/drei';
import {connect} from 'react-redux';
import EditControls from './EditControls';
import {selectFirebaseFeatures, selectFirebaseScenes} from '../../selectors/state/firebase';
import {selectViewMode} from '../../selectors/state/app';
import EditPlane from './EditPlane';
import BluePrint from '../features/BluePrint';

function Blueprints(props) {
	return Object.keys(props.features || {}).map(key => {
		const feature = props.features[key];

		return <BluePrint
			key={key} label={key} type={feature.type} boxes={feature.boxes}
			size={feature.size} position={feature.position} orientation={feature.orientation}
			showTransform={props.showTransform} onUpdateFeature={props.onUpdateFeature}/>;
	});
}

function EditScene(props) {
	const scene = props.scenes[props.viewMode.data.sceneKey];
	const camera = useRef();
	const controls = useRef();
	const [showTransform, setShowTransform] = useState('none');
	const [zPlane, setZPlane] = useState(0);
	const handleChangeZPlane = change => {
		setZPlane(zPlane => zPlane + change);
	};
	const handleShowTransform = (event, state) => {
		controls.current.enabled = state === 'none';
		event.preventDefault();
		setShowTransform(state);
	};

	return (
		<>
			<OrbitControls ref={controls}/>
			<EditControls
				canvasRef={props.canvasRef} scene={scene} camera={camera}
				zPlane={zPlane} changeZPlane={handleChangeZPlane}
				onAddFeature={props.onAddFeature} onRemoveFeature={props.onRemoveFeature}
				onShowTransform={handleShowTransform} onSetOrientation={props.onSetOrientation}
				onUpdateFeature={props.onUpdateFeature}/>
			<EditPlane zPlane={zPlane}/>
			<PerspectiveCamera
				fov={35} aspec={window.innerWidth / window.innerHeight} near={1} far={100}
				up={[0, 0, 1]} position={[-15, -15, 15]} ref={camera} makeDefault/>
			<Blueprints
				features={props.features} showTransform={showTransform} theme={scene.theme || 'default'}
				onUpdateFeature={props.onUpdateFeature}/>
		</>
	);
}

const mapStateToProps = store => ({
	features: selectFirebaseFeatures(store),
	scenes: selectFirebaseScenes(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {})(EditScene);
