import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCampaigns, selectFirebaseCharacters, selectFirebaseFriends, selectFirebaseScenarios,
	selectFirebaseUserPublic} from '../../../selectors/state/firebase';
import Panel from './Panel';
import {DefaultImage as DefaultCampaignImage} from '../items/CampaignItem';
import {DefaultImage as DefaultCharacterImage} from '../items/CharacterItem';
import ScenarioItem from '../items/ScenarioItem';
import {addCharacterToCampaign, removeCharacterFromCampaign} from '../../../actions/campaignActions';
import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {AddCircle, RemoveCircle} from '@material-ui/icons';
import ContentCoverStatic from '../items/ContentCoverStatic';
import UserListItem from '../lists/UserListItem';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function Scenarios(props) {
	return props.scenarios.map(scenario => {
		return <ScenarioItem
			key={scenario.key} campaignKey={props.campaignKey} scenarioKey={scenario.key} scenario={scenario.scenario}
			scenarios={props.scenarios} onClick={props.onClick}
		/>;
	});
}

function CampaignCharacters(props) {
	return Object.keys(props.campaignCharacters || {})
		.map(characterKey => {
			const character = props.characters[characterKey];

			return <ListItem key={characterKey} alignItems="flex-start">
				<ListItemAvatar>
					<Avatar alt={character.name} src={character.image || DefaultCharacterImage}/>
				</ListItemAvatar>
				<ListItemText primary={character.name} secondary={
					`Playing since ${new Date(props.campaignCharacters[characterKey].timestamp).toLocaleDateString()}`
				}/>
				<IconButton data-character={characterKey} aria-label="add character" onClick={props.onClickDelete}>
					<RemoveCircle/>
				</IconButton>
			</ListItem>;
		});
}

function OtherCharacters(props) {
	return Object.keys(props.characters || {})
		.filter(characterKey => props.campaignCharacters[characterKey] === undefined)
		.map(characterKey => {
			const character = props.characters[characterKey];

			return <ListItem key={characterKey} alignItems="flex-start">
				<ListItemAvatar>
					<Avatar alt={character.name} src={character.image || DefaultCharacterImage}/>
				</ListItemAvatar>
				<ListItemText primary={character.name}/>
				<IconButton
					data-character={characterKey} aria-label="add character"
					onClick={props.onClickAdd}>
					<AddCircle/>
				</IconButton>
			</ListItem>;
		});
}

function CampaignPlayers(props) {
	return Object.keys(props.players || {}).map(userKey => <UserListItem key={userKey} user={userKey}/>);
}
function CampaignPlayerPanel(props) {
	if (props.view !== 'campaignPlayer') return null;

	const campaign = props.campaigns[props.data];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleScenarioClick = event => {
		props.setView('scenarioPlayer', {
			campaign: props.data,
			scenario: event.currentTarget.dataset.target
		});
	};
	const handleClickAddCharacter = event => {
		props.addCharacterToCampaign(props.data, props.auth.key, event.currentTarget.dataset.character);
	};
	const handleClickRemoveCharacter = event => {
		props.removeCharacterFromCampaign(props.data, props.auth.key, event.currentTarget.dataset.character);
	};
	const handleSetTab = tab => setSelectedTab(tab);
	const campaignCharacters = campaign.players[props.auth.key].characters || {};
	const scenarios = Object.keys(props.scenarios || {}).filter(scenarioKey => {
		return props.scenarios[scenarioKey].campaign === props.data;
	})
		.map(scenarioKey => ({ key: scenarioKey, scenario: props.scenarios[scenarioKey]}))
		.sort((lhs, rhs) =>
			lhs.scenario.order - rhs.scenario.order
		);
	const tabs = {
		Notes: {
			content: <Notes key='notes' notes={campaign.notes} owner={campaign.user} players={campaign.players}/>,
			tools: []
		},
		Scenarios: {
			content: <Scenarios
				key='scenarios' campaignKey={props.data} scenarios={scenarios} onClick={handleScenarioClick}/>,
			tools: []
		},
		Playing: {
			content: <CampaignCharacters
				key='campaignCharacters' campaignCharacters={campaignCharacters} characters={props.characters}
				onClickDelete={handleClickRemoveCharacter}/>,
			tools: []
		},
		'Not playing': {
			content: <OtherCharacters
				key='otherCharacters' campaignCharacters={campaignCharacters} characters={props.characters}
				onClickAdd={handleClickAddCharacter}/>,
			tools: []
		},
		Players: {
			content: <CampaignPlayers key='campaignPlayers' players={campaign.players || {}}/>,
			tools: []
		}
	};

	useEffect(() => {
		props.setTitle(campaign.name, ['playing', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCoverStatic
				image={campaign.image || DefaultCampaignImage} name={campaign.name} description={campaign.description}
				toolbar={[<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>]}/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store),
	characters: selectFirebaseCharacters(store),
	friends: selectFirebaseFriends(store),
	scenarios: selectFirebaseScenarios(store),
	userPublic: selectFirebaseUserPublic(store)
});

export default connect(mapStateToProps, {
	addCharacterToCampaign,
	removeCharacterFromCampaign
})(CampaignPlayerPanel);
