import React, {useEffect, useRef, useState} from 'react';
import {
	AppBar, Box, Card, CardContent, Checkbox, IconButton, List, ListItem, ListItemText,
	makeStyles, Toolbar
} from '@material-ui/core';
import {Edit} from '@material-ui/icons';
import {connect} from 'react-redux';
import {selectUserImages} from '../../../selectors/state/firebase';
import SearchTool from './SearchTool';
import Scrollbars from '../Scrollbars';
import CardImage from '../CardImage';

const useStyles = makeStyles(() => ({
	avatar: { },
	banner: {height: 240},
	card: { marginBottom: 16 },
	checkbox: { color: 'white' },
	content: { flex: '1 0 auto'},
	details: { flexGrow: 2, paddingBottom: 8},
	editImage: {
		position: 'absolute',
		color: 'white',
		display: 'flex',
		flexDirection: 'column',
		top: 0,
		width: '100%',
		height : 240
	},
	frame: {overflow: 'hidden'},
	full: {height: 400},
	selection: {flex: 1},
	textField: { width: '100%'},
	toolbar: { justifyContent: 'flex-end'}
}));
const searchTime = 1000;

function EditImage(props) {
	const classes = useStyles();
	const [selectImage, setSelectImage] = useState(false);
	const [search, setSearch] = useState('');
	const handleEditImageClick = () => {
		setSelectImage(! selectImage);
	};
	const handleSearchChange = event => {
		const searchTerm = event.target.value;
		const updateSearch = () => {
			setSearch(searchTerm);
		};

		if (searchTerm !== '') {
			setTimeout(updateSearch, searchTime);
		} else {
			setSearch(searchTerm);
		}
	};
	const handleClickImage = event => {
		props.setImage(event.currentTarget.dataset.image);
	};

	return (
		<div className={classes.editImage}>
			<Toolbar className={classes.toolbar}>
				{selectImage &&
					<SearchTool key='search' placeholder='Search for image…' onChange={handleSearchChange}/>}
				<IconButton color='inherit' onClick={handleEditImageClick}>
					<Edit/>
				</IconButton>
			</Toolbar>
			{selectImage && <div className={classes.selection}>
				<Scrollbars>
					<List dense disablePadding>
						{Object.keys(props.images || {}).filter(imageKey => {
							return search.length === 0 || props.images[imageKey].name.includes(search);
						}).map(imageKey => {
							const image = props.images[imageKey];
		
							return (
								<ListItem
									button dense key={imageKey} alignItems="flex-start"
									data-image={imageKey} onClick={handleClickImage}>
									<ListItemText color='inherit' primary={image.name}/>
									<Checkbox className={classes.checkbox} checked={imageKey===props.image}/>
								</ListItem>
							);
						})}
					</List>
				</Scrollbars>
			</div>}
		</div>
	);
}

function ShowImage(props) {
	const classes = useStyles();
	const defaultTranslate = { x: 0, y: 0 };
	const [dragStart, setDragStart] = useState(defaultTranslate);
	const [dragOffset, setDragOffset] = useState(props.translate || defaultTranslate);
	const [translate, setTranslate] = useState(props.translate);
	const [dragging, setDragging] = useState(false);
	const frameRef = useRef();
	const handleFrameMouseDown = event => {
		if (! props.edit) return;
		setDragging(true);
		setDragStart({ x: event.screenX, y: event.screenY });
	};
	const handleFrameMouseMove = event => {
		if (! dragging) return;

		const deltaX = Math.round((event.screenX - dragStart.x) * 100 / frameRef.current.clientWidth);
		const deltaY = Math.round((event.screenY - dragStart.y) * 100 / frameRef.current.clientHeight);

		setTranslate({x: deltaX + dragOffset.x, y: deltaY + dragOffset.y});
	};
	const handleFrameMouseUp = () => {
		if (! dragging) return;
		setDragging(false);
		props.onSetTranslate && props.onSetTranslate(translate);
	};
	const format = props.format || 'banner';

	useEffect(() => {
		if (! props.translate) return;
		setTranslate(props.translate);
		setDragOffset(props.translate);
	}, [props.format, props.translate]);
	return (
		<div
			ref={frameRef} className={[classes[format], classes.frame].join(' ')}
			onMouseDown={handleFrameMouseDown} onMouseMove={handleFrameMouseMove} onMouseUp={handleFrameMouseUp}>
			<CardImage format={format} image={props.image} translate={translate} scale={props.scale}/>
			{props.editImage && props.setImage && <EditImage {...props}/>}
		</div>
	);
}

function Cover(props) {
	const classes = useStyles();

	return (
		<Card className={classes.card} variant='outlined'>
			<ShowImage {...props}/>
			{props.children &&
				<CardContent className={classes.content}>
					{props.children}
				</CardContent>
			}
			{props.toolbar &&
				<AppBar position='static'>
					<Toolbar className={classes.toolbar}>
						{Object.values(props.toolbar)}
					</Toolbar>
				</AppBar>
			}
		</Card>
	);
}

const mapStateToProps = store => ({images: selectUserImages(store)});

export default connect(mapStateToProps, {})(Cover);
