import React, {} from 'react';
import {connect} from 'react-redux';
import {Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import {DefaultImage as DefaultUserImage} from '../items/FriendItem';

function MonsterListItem(props) {
	const handleClickMonster = () => {
		props.onClick(props.monsterKey);
	};

	return (
		<ListItem button style={props.style} onClick={handleClickMonster}>
			<ListItemAvatar>
				<Avatar alt={props.monster.name} src={props.monster.image || DefaultUserImage}/>
			</ListItemAvatar>
			<ListItemText primary={props.monster.name}/>
			{props.action && <ListItemSecondaryAction>{props.action}</ListItemSecondaryAction>}
		</ListItem>
	);
}
const mapStateToProps = store => ({
});

export default connect(mapStateToProps, {
})(MonsterListItem);
