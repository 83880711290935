import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Card, CardActions, CardContent, IconButton, TextField, Typography, makeStyles, Box} from '@material-ui/core';
import {ArrowDownward, ArrowUpward, Delete, Edit, People, Visibility, VisibilityOff} from '@material-ui/icons';
import {selectAppAuthentication} from '../../../selectors/state/app';

const useStyles = makeStyles(theme => ({
	card: {
		display: 'flex',
		flexDirection: 'column',
		margin: theme.spacing(2),
	},
	cardHighlight: { flexGrow: 1 },
	cardContent: {
		flexGrow: 1,
		whiteSpace: 'pre-wrap'
	},
	contentLowlight: {
		height: 160,
		overflow: 'hidden',
		whiteSpace: 'pre-wrap'
	},
	visibilityButtons: {
		backgroundColor: theme.palette.grey.A100
	},
	selected: {
		color: theme.palette.primary.main
	}
}));

function VisibilityIcon(props) {
	const handleClick = event => {
		props.setVisibility(props.label);
		event.stopPropagation();
	};
	const classes = useStyles();
	const className = props.value === props.label ? classes.selected : undefined;

	return <IconButton className={className} title={props.title} onClick={handleClick}>
		{props.children}
	</IconButton>;
}
function Note(props) {
	const [edit, setEdit] = useState(false);
	const handleRemoveNote = () => props.removeNote(props.noteKey);
	const handleTitleBlur = event => note.title !== event.currentTarget.value &&
		props.updateNote(props.noteKey, {
			title: event.currentTarget.value,
			contents: note.contents
		});
	const handleContentsBlur = event => note.contents !== event.currentTarget.value &&
		props.updateNote(props.noteKey, {
			title: note.title,
			contents: event.currentTarget.value
		});
	const handleClickEdit = () => {
		setEdit(!edit);
	};
	const handleCardClick = event => {
		if (props.highlight) {
			props.onCardClick(event, -1);
		} else {
			props.onCardClick(event, props.index);
		}
	};
	const handleClickMoveUp = event => {
		props.onMoveUp(event, props.noteKey);
	};
	const handleClickMoveDown = event => {
		props.onMoveDown(event, props.noteKey);
	};
	const handleSetVisibility = visibility => {
		props.updateNote(props.noteKey, {
			...note,
			visibility
		});
	};
	const classes = useStyles();
	const note = props.notes && props.notes[props.noteKey];
	const owner = props.auth.key === props.owner;

	if (note === undefined) return null;
	return (
		<Card
			raised={props.highlight} onClick={handleCardClick}
			className={[classes.card, props.highlight && classes.cardHighlight].join(' ')}>
			<CardContent className={classes.cardContent}>
				{edit ?
					<TextField fullWidth label='Title' defaultValue={note.title} onBlur={handleTitleBlur}/> :
					<Typography variant='h5'>{note.title}</Typography>
				}
				{edit ?
					<TextField
						fullWidth label='Contents' multiline rows={6} defaultValue={note.contents}
						onBlur={handleContentsBlur}/> :
					<Typography className={props.highlight ? '' : classes.contentLowlight} variant='body2'>
						{note.contents}
					</Typography>
				}
			</CardContent>
			{owner &&
			<CardActions>
				<Box display='flex'>
					<IconButton title='Edit note' onClick={handleClickEdit}><Edit/></IconButton>
					<Box className={classes.visibilityButtons} borderRadius={4}>
						<VisibilityIcon
							title='Public' label='public' value={note.visibility} setVisibility={handleSetVisibility}>
							<Visibility/>
						</VisibilityIcon>
						<VisibilityIcon
							title='Players' label='players' value={note.visibility} setVisibility={handleSetVisibility}>
							<People/>
						</VisibilityIcon>
						<VisibilityIcon
							title='Private' label='private' value={note.visibility} setVisibility={handleSetVisibility}>
							<VisibilityOff/>
						</VisibilityIcon>
					</Box>
					{props.index > 0 &&
						<IconButton title='Move up' aria-label='move up' onClick={handleClickMoveUp}>
							<ArrowUpward/>
						</IconButton>}
					{props.index < Object.keys(props.notes).length - 1 &&
						<IconButton title='Move down' aria-label='move down' onClick={handleClickMoveDown}>
							<ArrowDownward/>
						</IconButton>}
					<Box flexGrow={1}/>
					<IconButton title='Delete note' onClick={handleRemoveNote}><Delete/></IconButton>
				</Box>
			</CardActions>
			}
		</Card>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store)
});

export default connect(mapStateToProps, {})(Note);
