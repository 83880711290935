import React from 'react';
import {connect} from 'react-redux';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {MenuItem, TextField} from '@material-ui/core';
import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import Admin from './Admin';
import {adminSetShopItem} from '../../../actions/firebaseActions';
import {selectFirebaseModels} from "../../../selectors/state/firebase";

function ShopItemAdmin(props) {
	const updateShopItem = (member, value) => {
		if (value !== props.shopItem['member']) {
			props.adminSetShopItem(props.shopItemKey, member, value);
		}
	};
	const handleItemTypeChange = event => {
		updateShopItem('type', event.target.value);
	};
	const handleItemNameBlur = event => {
		updateShopItem('name', event.target.value);
	};
	const handlePriceBlur = event => {
		updateShopItem('price', event.target.value);
	};
	const handleModelBlur = event => {
		updateShopItem('model', event.target.value);
	};
	const handleRulesBlur = event => {
		updateShopItem('rules', event.target.value);
	};
	const handleAvailableChange = date => {
		updateShopItem('available', date.getTime());
	};

	return (
		<Admin>
			<TextField label='Name' defaultValue={props.shopItem.name} fullWidth onBlur={handleItemNameBlur}/>
			<TextField label='Item Type' select value={props.shopItem.type} fullWidth onChange={handleItemTypeChange}>
				<MenuItem aria-label='None' value=''/>
				<MenuItem value=''>None</MenuItem>
				<MenuItem value='model'>Model</MenuItem>
				<MenuItem value='rules'>Rules</MenuItem>
			</TextField>
			<TextField
				label='Price' type='number' defaultValue={props.shopItem.price} fullWidth onBlur={handlePriceBlur}/>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					margin='normal' label='Available from' value={new Date(props.shopItem.available)}
					fullWidth format="dd/MM/yyyy" onChange={handleAvailableChange}/>
			</MuiPickersUtilsProvider>
			{props.shopItem.type === 'model' &&
			<TextField label='Model' select value={props.shopItem.model} fullWidth onBlur={handleModelBlur}>
				{Object.keys(props.models).map(modelKey => (
					<MenuItem key={modelKey} value={modelKey}>{modelKey}</MenuItem>
				))}
			</TextField>
			}
			{props.shopItem.type === 'rules' &&
			<TextField label='Rules' defaultValue={props.shopItem.rules} fullWidth onBlur={handleRulesBlur}/>
			}
		</Admin>
	);
}
const mapStateToProps = store => ({
	models: selectFirebaseModels(store)
});

export default connect(mapStateToProps, {
	adminSetShopItem
})(ShopItemAdmin);
