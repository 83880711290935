import React from 'react';

export default function SelectedFigure(props) {
	return (
		<group rotation={[Math.PI / 2, 0, 0]} position={[props.position[0], props.position[1], props.position[2] - 0.5]}>
			<mesh>
				<cylinderBufferGeometry args={[0.5, 0.5, 0.1, 32]} />
				<meshBasicMaterial attach="material" transparent={true} opacity={props.opacity || 0.3}/>
			</mesh>
		</group>
	);
}
