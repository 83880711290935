import React from 'react';
import {connect} from 'react-redux';
import {Card, CardActionArea, CardContent, CardMedia, Typography as MuiTypography} from '@material-ui/core';
import {styled} from '@material-ui/core/styles';

const Typography = styled(MuiTypography)(() => ({
	whiteSpace: 'initial'
}));

function Advert(props) {
	return (
		<Card style={{maxWidth: 280}} variant='outlined'>
			<CardActionArea onClick={props.onClick}>
				<CardMedia style={{height: 140}} image={props.image}/>
				<CardContent>
					<Typography variant='h5' component='h2'>
						{props.title}
					</Typography>
					<Typography variant='body2' color='textSecondary'>
						{props.body}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(Advert);

