import React, {  } from 'react';
import TiledRoom from './TiledRoom';

export default function Corridor(props) {
	return <TiledRoom
		label={props.label} orientation={props.orientation} position={props.position} size={props.size}
		boxes={props.boxes} theme={props.theme} models={props.models}
		type={props.type} sceneKey={props.sceneKey} featureKey={props.featureKey} decorations={props.decorations}
		onDraggingChanged={props.onDraggingChanged} onTileSelected={props.onTileSelected}
		onPointerDown={props.onPointerDown} onPointerUp={props.onPointerUp}
		onPointerMove={props.onPointerMove} onTileHover={props.onTileHover}
	/>;
}
