import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
	selectFirebaseAssignments, selectFirebaseModels,
	selectFirebaseUserModels,
	selectFirebaseUserRules
} from '../../../selectors/state/firebase';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectRules} from '../../../selectors/state/jsonSelector';
import MonsterListItem from '../lists/MonsterListItem';
import {FixedSizeList} from 'react-window';
import SearchTool from '../items/SearchTool';
import ToolbarTabs from '../items/ToolbarTabs';
import AssignmentList from '../lists/AssignmentList';
import UserAssignments from '../../monsters/UserAssignments';
import {selectAppAuthentication, selectAppUser} from '../../../selectors/state/app';
import {IconButton} from '@material-ui/core';
import {Queue} from '@material-ui/icons';
import {addAssignment, removeAssignment} from '../../../actions/monsterActions';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/rules.jpg`;

function MonstersPanel(props) {
	if (props.view !== 'monsters' || props.rules === undefined) return null;

	const rules = props.userRules[props.data];
	const monsters = Object.keys(props.rules[props.data].monsters).map(key =>
		({key, monster: props.rules[props.data].monsters[key]}));
	const userAssignments = new UserAssignments(props.auth.key, props.user, props.assignments);
	const [filter, setFilter] = useState('');
	const [filtered, setFiltered] = useState(monsters);
	const [selectedTab, setSelectedTab] = useState('Monsters');
	const [dirty, setDirty] = useState(0);
	const handleSetTab = tab => setSelectedTab(tab);
	const handleSearchChange = event => {
		setFilter(event.target.value.toLowerCase());
	};
	const handleMonsterClick = monster => {
		props.setView('monster', { rules: props.data, monster});
	};
	const handleAddAssignment = () => {
		props.addAssignment(props.auth.key, props.data);
	};
	const handleRemoveAssignment = event => {
		props.removeAssignment(event.currentTarget.dataset.key);
		setDirty(Date.now());
	};
	const renderItem = (props) => {
		const {index, style} = props;

		return (
			<MonsterListItem
				key={index} style={style} monsterKey={filtered[index].key} monster={filtered[index].monster}
				onClick={handleMonsterClick}/>
		);
	};
	const itemHeight = 56;
	const tabs = {
		Monsters: {
			content: (
				<FixedSizeList
					height={itemHeight * filtered.length} width='100%' itemSize={itemHeight} itemCount={filtered.length}>
					{renderItem}
				</FixedSizeList>
			),
			tools: [<SearchTool key={'search'} placeholder='Filter monsters' onChange={handleSearchChange}/>]
		},
		Rules: {
			content: (
				<AssignmentList
					assignments={userAssignments.assignments} removeAssignment={handleRemoveAssignment}
					monsters={monsters} setView={props.setView} dirty={dirty}/>
			),
			tools: [
				<IconButton key='addRule' title='Add rule' color='inherit' onClick={handleAddAssignment}>
					<Queue/>
				</IconButton>
			]
		}
	};

	useEffect(() => {
		props.setTitle(rules.name, 'rules');
	}, []);
	useEffect(() => {
		setFiltered(monsters.filter(test => filter === '' || test.monster.name.toLowerCase().includes(filter)));
	}, [filter]);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={[
				<ToolbarTabs key={'toolbar'} tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>, ...tabs[selectedTab].tools
			]}/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	assignments: selectFirebaseAssignments(store),
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	rules: selectRules(store),
	user: selectAppUser(store),
	userModels: selectFirebaseUserModels(store),
	userRules: selectFirebaseUserRules(store)
});

export default connect(mapStateToProps, {
	addAssignment,
	removeAssignment
})(MonstersPanel);
