import React from 'react';
import {Box, Divider, Grid, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';

export default function MonsterStats(props) {
	const stats = ['str', 'dex', 'con', 'int', 'wis', 'cha'];
	const useStyles = makeStyles(theme => ({
		bold: {fontWeight: 600},
		heading: {fontWeight: 600, paddingTop: theme.spacing(1)},
		italic: {fontStyle: 'italic'},
		stat: {textTransform: 'uppercase', width: '100%'},
		save: {textTransform: 'capitalize'},
		separator: { height: 2, marginBottom: theme.spacing(1), marginTop: theme.spacing(1)},
		divider: {marginBottom: theme.spacing(1)}
	}));
	const classes = useStyles();
	const showSign = value => {
		return value >= 0 ? '+' + value : value;
	};
	const buildMovement = movement => {
		return (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>Speed </Typography>
				<Typography variant='body2' component='span'>{`${movement.value} ft.`}</Typography>
				{Object.keys(movement).length > 1 && Object.keys(movement)
					.filter(key => ! ['value', 'hover'].includes(key))
					.map(key => {
						return (
							<Typography key={key} variant='body2' component='span'>
								{`, ${key} ${movement[key]} ft.` +
								(key === 'fly' && movement.hover ? ' (hover)' : '')}
							</Typography>
						);
					})}
			</div>
		);
	};
	const buildStats = monster => {
		return (
			<Grid container>
				{stats.map(stat => {
					return (
						<Grid container key={stat} item xs={2}>
							<Typography className={`${classes.bold} ${classes.stat}`} variant='body2' align='center'>
								{stat}
							</Typography>
							<Typography className={classes.stat} variant='body2' align='center'>
								{`${monster[stat].value} (${monster[stat].modifier >= 0 ? '+' : ''}${monster[stat].modifier})`}
							</Typography>
						</Grid>
					);
				})}
			</Grid>
		);
	};
	const buildValue = (label, value) => {
		return value && (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>{label} </Typography>
				<Typography variant='body2' component='span'>{value}</Typography>
			</div>
		);
	};
	const buildValues = (label, values) => {
		return values && (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>{label} </Typography>
				{Object.keys(values).map((key, index) => {
					return (
						<Typography className={classes.save} key={key} variant='body2' component='span'>
							{`${index > 0 ? ', ' : ''}${key} ${showSign(values[key])}`}
						</Typography>
					);
				})}
			</div>
		);
	};
	const buildValueList = (label, values) => {
		return values && (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>{label} </Typography>
				{Object.values(values).map((value, index) => {
					return (
						<Typography className={classes.save} key={value} variant='body2' component='span'>
							{`${index > 0 ? ', ' : ''}${value}`}
						</Typography>
					);
				})}
			</div>
		);
	};
	const buildSenses = senses => {
		return (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>Senses </Typography>
				{Object.keys(senses).filter(sense => sense !== 'passivePerception')
					.map((sense) => {
						return (
							<Typography key={sense} variant='body2' component='span'>
								{`${sense} ${senses[sense]} ft., `}
							</Typography>
						);
					})
				}
				<Typography variant='body2' component='span'>
					{`passive Perception ${senses.passivePerception}`}
				</Typography>
			</div>
		);
	};
	const buildLanguages = languages => {
		if (languages === undefined) {
			return (
				<div>
					<Typography className={classes.bold} variant='body2' component='span'>Languages </Typography>
					<Typography variant='body2' component='span'> -</Typography>
				</div>
			);
		}
		return (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>Languages </Typography>
				<Typography variant='body2' component='span'>
					{Object.values(languages.spoken || {}).join(', ')}
				</Typography>
				{languages.telepathy &&
					<Typography variant='body2' component='span'>, telepathy {languages.telepathy} ft. </Typography>}
				{languages.special &&
					<Typography variant='body2' component='span'>{languages.special}</Typography>}
			</div>
		);
	};
	const buildChallenge = challenge => {
		return (
			<div>
				<Typography className={classes.bold} variant='body2' component='span'>Challenge </Typography>
				<Typography variant='body2' component='span'>{`${challenge.value} (${challenge.xp} XP)`}</Typography>
			</div>
		);
	};
	const buildTraits = traits => {
		return traits && Object.values(traits).map((trait, index) => {
			if (trait.name) {
				return (
					<div key={index}>
						<Typography className={classes.bold} variant='body2' component='span'>{trait.name}</Typography>
						<Typography variant='body2' component='span'>{trait.details}</Typography>
					</div>
				);
			}
			return <Typography key={index} variant='body2'>{trait}</Typography>;
		});
	};
	const buildAttack = action => {
		return (
			<div key={action.name}>
				<Typography className={classes.bold} variant='body2' component='span'>{action.name} </Typography>
				<Typography className={classes.italic} variant='body2' component='span'>{action.attack.type} </Typography>
				<Typography variant='body2' component='span'>{action.attack.toHit} to hit, </Typography>
				<Typography variant='body2' component='span'>reach {action.attack.reach} ft., </Typography>
				<Typography variant='body2' component='span'>{action.attack.target} target. </Typography>
				<Typography className={classes.italic} variant='body2' component='span'>Hit: </Typography>
				{Object.values(action.attack.damage).map((damage, index) => {
					return (
						<Typography key={index} variant='body2' component='span'>
							{index > 0 && 'plus '} {damage.mean} {damage.dice} {damage.type} damage {damage.special}
						</Typography>
					);
				})}
				
			</div>
		);
	};
	const buildActions = actions => {
		return actions && Object.values(actions).map((action, index) => {
			if (action.name) {
				if (action.details) {
					return (
						<div key={index}>
							<Typography className={classes.bold} variant='body2' component='span'>{action.name} </Typography>
							<Typography variant='body2' component='span'>{action.details}</Typography>
						</div>
					);
				}
				return buildAttack(action);
			}
			return <Typography key={index} variant='body2'>{action}</Typography>;
		});
	};
	const buildLegendaryActions = actions => {
		return actions && Object.values(actions).map((action, index) => {
			if (action.name) {
				if (action.details) {
					return (
						<div key={index}>
							<Typography className={classes.bold} variant='body2' component='span'>{action.name}</Typography>
							<Typography variant='body2' component='span'>{action.details}</Typography>
						</div>
					);
				}
				return buildAttack(action);
			}
			return <Typography key={index} variant='body2'>{action}</Typography>;
		});
	};

	return (
		<Box>
			<Typography className={classes.italic} variant='body2'>{props.monster.size} {props.monster.type}
				{props.monster.subtype && `(${props.monster.subtype})`}, {props.monster.alignment}</Typography>
			<Divider className={classes.separator}/>
			{buildValue('Armor Class', `${props.monster.ac.value} ${props.monster.ac.type ? '(' + props.monster.ac.type + ')' : ''}`)}
			{buildValue('Hit Points', `${props.monster.hp.value} (${props.monster.hp.calculation})`)}
			{buildMovement(props.monster.movement)}
			<Divider className={classes.separator}/>
			{buildStats(props.monster)}
			<Divider className={classes.separator}/>
			{buildValues('Saving Throws', props.monster.saves)}
			{buildValues('Skills', props.monster.skills)}
			{buildValueList('Damage Resistances', props.monster.resistances)}
			{buildValueList('Damage Immunities', props.monster.immunities)}
			{buildValueList('Condition Immunities', props.monster.conditionImmunities)}
			{buildSenses(props.monster.senses)}
			{buildLanguages(props.monster.languages)}
			{buildChallenge(props.monster.challenge)}
			<Divider className={classes.separator}/>
			{buildTraits(props.monster.traits)}
			{props.monster.actions &&
				<>
					<Typography className={classes.heading} variant='body1'>Actions</Typography>
					<Divider className={classes.divider}/>
					{buildActions(props.monster.actions)}
				</>}
			{props.monster.reactions && 
				<>
					<Typography className={classes.heading} variant='body1'>Reactions</Typography>
					<Divider className={classes.divider}/>
					{buildActions(props.monster.reactions)}
				</>}
			{props.monster.legendaryActions && 
				<>
					<Typography className={classes.heading} variant='body1'>Legendary Actions</Typography>
					<Divider className={classes.divider}/>
					{buildLegendaryActions(props.monster.legendaryActions)}
				</>}
		</Box>
	);
}
