import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCampaigns, selectFirebaseFriends, selectFirebaseScenarios, selectFirebaseUserPublic}
	from '../../../selectors/state/firebase';
import Panel from './Panel';
import {DefaultImage} from '../items/CampaignItem';
import ContentCover from '../items/ContentCover';
import ScenarioItem from '../items/ScenarioItem';
import {updateCampaign} from '../../../actions/firebaseActions';
import {inviteCampaignPlayer, removeCampaignPlayer, deleteCampaign} from '../../../actions/campaignActions';
import {addCampaignNote, removeCampaignNote, updateCampaignNote} from '../../../actions/noteActions';
import {createScenario} from '../../../actions/scenarioActions';
import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {AddCircle, Delete, Queue, RemoveCircle} from '@material-ui/icons';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function Scenarios(props) {
	return (
		props.scenarios.map(scenario => {
			return <ScenarioItem
				key={scenario.key} campaignKey={props.campaignKey} scenarioKey={scenario.key}
				scenario={scenario.scenario} scenarios={props.scenarios} onClick={props.onClick}/>;
		})
	);
}

function Players(props) {
	return Object.keys(props.campaign?.players || {}).map(userKey => {
		const user = userKey === props.auth.key ? props.userPublic : props.friends[userKey];
		const player = props.campaign.players[userKey];

		return <ListItem key={userKey} alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={user.nickname} src={user.image || DefaultImage}/>
			</ListItemAvatar>
			<ListItemText primary={user.nickname} secondary={
				(player.status === 'invited' ? 'Invited on ' : 'Player since ') +
				new Date(player.timestamp).toLocaleDateString()
			}/>
			<IconButton
				data-player={userKey} data-invite={player.invite}
				aria-label="remove friend" onClick={props.onClick}>
				<RemoveCircle/>
			</IconButton>
		</ListItem>;
	});
}

function Friends(props) {
	return Object.keys(props.friends || {})
		.filter(userKey => props.campaign.players === undefined || props.campaign.players[userKey] === undefined)
		.map(userKey => {
			const friend = props.friends[userKey];

			return <ListItem key={userKey} alignItems="flex-start">
				<ListItemAvatar>
					<Avatar alt={friend.nickname} src={friend.image || DefaultImage}/>
				</ListItemAvatar>
				<ListItemText primary={friend.nickname} secondary={friend.bio}/>
				<IconButton
					data-player={userKey} aria-label="add friend"
					onClick={props.onClick}>
					<AddCircle/>
				</IconButton>
			</ListItem>;
		});
}
function CampaignPanel(props) {
	if (props.view !== 'campaign') return null;

	const campaign = props.campaigns[props.data];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleSetImage = value => {
		props.updateCampaign(props.data, 'image', value);
	};
	const handleSetName = value => {
		props.updateCampaign(props.data, 'name', value);
	};
	const handleSetDescription = value => {
		props.updateCampaign(props.data, 'description', value);
	};
	const handleScenarioClick = event => {
		props.setView('scenario', {
			campaign: props.data,
			scenario: event.currentTarget.dataset.target
		});
	};
	const handleArchiveCampaign = () => {
		props.deleteCampaign(props.data, campaign);
		props.setView('running');
	};
	const handleAddScenario = () => {
		props.createScenario(props.data, 'New Scenario', props.auth.key);
	};
	const handleSetTab = tab => setSelectedTab(tab);
	const handleClickInviteFriend = event => {
		props.inviteCampaignPlayer(props.auth.key, props.data, campaign.name,
			event.currentTarget.dataset.player);
	};
	const handleClickRemovePlayer = event => {
		props.removeCampaignPlayer(props.data, event.currentTarget.dataset.player,
			event.currentTarget.dataset.invite);
	};
	const handleAddNote = () => {
		props.addCampaignNote(props.data);
	};
	const handleUpdateNote = (noteKey, update) => {
		props.updateCampaignNote(props.data, noteKey, update);
	};
	const handleRemoveNote = noteKey => {
		props.removeCampaignNote(props.data, noteKey);
	};
	const scenarios = Object.keys(props.scenarios || {}).filter(scenarioKey => {
		return props.scenarios[scenarioKey].campaign === props.data;
	})
		.map(scenarioKey => ({ key: scenarioKey, scenario: props.scenarios[scenarioKey]}))
		.sort((lhs, rhs) =>
			lhs.scenario.order - rhs.scenario.order
		);
	const tabs = {
		Notes: {
			content: <Notes
				key='notes' campaignKey={props.data} notes={campaign.notes} owner={campaign.user}
				players={campaign.players} updateNote={handleUpdateNote} removeNote={handleRemoveNote}/>,
			tools: [<IconButton key='addNotes' title='Add note' color='inherit' onClick={handleAddNote}>
				<Queue/>
			</IconButton>]
		},
		Scenarios: {
			content: <Scenarios
				key='scenarios' campaignKey={props.data} scenarios={scenarios} onClick={handleScenarioClick}/>,
			tools: [<IconButton key='addScenario' title='Add scenario' color='inherit' onClick={handleAddScenario}>
				<Queue/></IconButton>]
		},
		Players: {
			content: <Players
				key='players' campaign={campaign} friends={props.friends} auth={props.auth}
				onClick={handleClickRemovePlayer}/>,
			tools: []
		},
		Friends: {
			content: <Friends
				key={'friends'} campaign={campaign} friends={props.friends} onClick={handleClickInviteFriend}/>,
			tools: []
		}
	};
	const commonTabTools = [
		<IconButton key='archive' color='inherit' onClick={handleArchiveCampaign} disabled={
			Object.values(props.scenarios || {}).some(scenario => scenario.campaign === props.data)
		}><Delete/></IconButton>
	];

	useEffect(() => {
		props.setTitle(campaign.name, ['running', 'referee']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCover
				image={campaign.image || DefaultImage} setImage={handleSetImage}
				name={campaign.name} setName={handleSetName}
				description={campaign.description} setDescription={handleSetDescription}
				toolbar={[
					<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>,
					...tabs[selectedTab].tools,
					...commonTabTools
				]}>
			</ContentCover>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store),
	friends: selectFirebaseFriends(store),
	scenarios: selectFirebaseScenarios(store),
	userPublic: selectFirebaseUserPublic(store)
});

export default connect(mapStateToProps, {
	addCampaignNote,
	createScenario,
	deleteCampaign,
	inviteCampaignPlayer,
	removeCampaignPlayer,
	removeCampaignNote,
	updateCampaignNote,
	updateCampaign
})(CampaignPanel);
