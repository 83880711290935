import React from 'react';
import {Typography} from '@material-ui/core';
import Cover from './Cover';

export default function ContentCoverStatic(props) {
	return (
		<Cover {...props}>
			<Typography variant='h5'>{props.name}</Typography>
			<Typography variant='body1'>{props.description}</Typography>
			{props.children}
		</Cover>
	);
}
