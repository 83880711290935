import React, {  } from 'react';
import TiledRoom from './TiledRoom';

export default function Staircase(props) {
	return <TiledRoom
		label={props.label} orientation={props.orientation} position={props.position} size={props.size}
		type={props.type} sceneKey={props.sceneKey} featureKey={props.featureKey} decorations={props.decorations}
		boxes={props.boxes} theme={props.theme} models={props.models}
		onDraggingChanged={props.onDraggingChanged} onTileSelected={props.onTileSelected}
		onPointerDown={props.onPointerDown} onPointerUp={props.onPointerUp}
		onPointerMove={props.onPointerMove} onTileHover={props.onTileHover}
	/>;
}

export function ExcludesBox(staircase, box) {
	if (staircase.size[2] < 3)
		return false;
	if (staircase.orientation === 'x+') {
		return box[0] - box[2] > 0 || box[0] - box[2] < -2;
	} else if (staircase.orientation === 'x-') {
		return staircase.size[0] - box[0] - box[2] > 1 || staircase.size[0] - box[0] - box[2] < -1;
	} else if (staircase.orientation === 'y+') {
		return box[1] - box[2] > 0 || box[1] - box[2] < -2;
	} else if (staircase.orientation === 'y-') {
		return staircase.size[1] - box[1] - box[2] > 1 || staircase.size[1] - box[1] - box[2] < -1;
	}
	return false;
}

export function FloorHeight(staircase, x, y) {
	if (staircase.orientation === 'x+') {
		// noinspection JSSuspiciousNameCombination
		return x;
	} else if (staircase.orientation === 'x-') {
		return staircase.size[0] - (x + 1);
	} else if (staircase.orientation === 'y+') {
		return y;
	} else if (staircase.orientation === 'y-') {
		return staircase.size[1] - (y + 1);
	}
	return 0;
}

export function CeilingHeight(staircase, x, y) {
	return Math.min(staircase.size[2] - 1, FloorHeight(staircase, x, y) + 2);
}
