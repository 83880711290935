import React, {Suspense, useEffect, useState} from 'react';
import * as THREE from 'three';
import { selectSelectedFeature } from '../../selectors/state/edit';
import { connect } from 'react-redux';
import {selectFirebaseCharacters, selectFirebaseFeatures, selectFirebaseMonsters, selectFirebaseScenes}
	from '../../selectors/state/firebase';
import {selectAppAuthentication, selectHighlightState, selectHighlightTile, selectViewMode}
	from '../../selectors/state/app';
import {setHighlightState, setHighlightTile} from '../../actions/appActions';
import Features from '../features/Features';
import Characters from './Characters';
import Monsters from '../monsters/Monsters';
import AvatarController from './player/AvatarController';
import Character from './Character';
import {useThree} from 'react-three-fiber';

function PlayScene(props) {
	const [avatarDetails, setAvatarDetails] = useState({});
	const [avatarKey, setAvatarKey] = useState();
	const [avatarModel, setAvatarModel] = useState();
	const [characterDetails, setCharacterDetails] = useState({});
	const [monsters, setMonsters] = useState({});
	const [nonPlayerCharactersDetails, setNonPlayerCharactersDetails] = useState({});
	const getTileDetails = (from, to) => {
		const featureContainingPosition = position => Object.values(props.features).find(feature => {
			return feature.position[0] <= position.x && feature.position[0] + feature.size[0] > position.x &&
				feature.position[1] <= position.y && feature.position[1] + feature.size[1] > position.y &&
				feature.position[2] <= position.z && feature.position[2] + feature.size[2] > position.z;
		});
		const fromFeature = featureContainingPosition(from);

		if (fromFeature.type === 'staircase') {
			if (fromFeature.orientation === 'x+' && to.x < from.x) {
				to.z -= 1;
			} else if (fromFeature.orientation === 'x-' && to.x > from.x) {
				to.z -= 1;
			} else if (fromFeature.orientation === 'y+' && to.y < from.y) {
				to.z -= 1;
			} else if (fromFeature.orientation === 'y-' && to.y > from.y) {
				to.z -= 1;
			}
		}
		return featureContainingPosition(to);
	};
	const onTileHover = position => {
		if (props.highlightTile !== position) {
			props.setHighlightTile(position);
		}
	};
	const { scene } = useThree();

	// This can be varied with the characters ability to see in the dark and the dungeon theme
	scene.fog = new THREE.FogExp2('black', 0.1);
	useEffect(() => {
		setCharacterDetails(props.scene.characters || {});
		setMonsters(props.scene.monsters || {});
	}, [props.scene]);
	useEffect(() => {
		if (! props.characters) return;
		const avatarKey = Object.keys(characterDetails).find(characterKey =>
			props.characters[characterKey]?.user === props.authentication.key);
		const nonPlayerCharacterDetails = Object.keys(characterDetails).reduce((collect, characterKey) => {
			if (characterKey !== avatarKey) {
				collect[characterKey] = characterDetails[characterKey];
			}
			return collect;
		}, {});

		setAvatarKey(avatarKey);
		setAvatarDetails(characterDetails[avatarKey]);
		setNonPlayerCharactersDetails(nonPlayerCharacterDetails);
	}, [characterDetails, props.characters]);
	useEffect(() => {
		const keydown = event => {
			if (event.keyCode === 16) { // Shift
				props.setHighlightState('highlight');
			}
		};
		const keyup = event => {
			if (event.keyCode === 16) { // Shift
				props.setHighlightState('noHighlight');
			}
		};

		window.addEventListener('keydown', keydown);
		window.addEventListener('keyup', keyup);
		return () => {
			window.removeEventListener('keydown', keydown);
			window.removeEventListener('keyup', keyup);
		};
	}, [props.user]);
	if (!props.scene) return null;
	return (
		<group>
			<Features readonly={true} theme={props.scene.theme || 'default'} onTileHover={onTileHover}/>
			<Characters details={nonPlayerCharactersDetails}/>
			<Monsters monsters={props.monsters} details={monsters}/>
			{avatarKey &&
			<AvatarController
				{...props}
				model={avatarModel}
				details={avatarDetails}
				characterKey={avatarKey}
				getTileDetails={getTileDetails}>
				<Suspense fallback={null}>
					<Character
						animation={avatarDetails.animation} character={avatarKey}
						onSetModel={model => setAvatarModel(model)}/>
				</Suspense>
			</AvatarController>}
		</group>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	characters: selectFirebaseCharacters(store),
	features: selectFirebaseFeatures(store),
	highlightState: selectHighlightState(store),
	highlightTile: selectHighlightTile(store),
	monsters: selectFirebaseMonsters(store),
	scenes: selectFirebaseScenes(store),
	selectedFeature: selectSelectedFeature(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	setHighlightState,
	setHighlightTile
})(PlayScene);
