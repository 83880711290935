import {createReducer} from './index';
import {JSON_REMOVE, JSON_SET} from '../actions/jsonActions';

const initialState = {};
const jsonRemove = (store, action) => {
	const newStore = {...store};

	if (Array.isArray(action.name)) {
		let path = newStore;

		action.name.some((member, index) => {
			if (index === action.name.length - 1) {
				delete path[member];
			} else if (path[member] === undefined) {
				return true;
			}
			path = store[member];
		});
	} else {
		delete newStore[action.name];
	}
	return newStore;
};
const jsonSet = (store, action) => {
	const newStore = {...store};

	if (Array.isArray(action.name)) {
		let path = newStore;

		action.name.forEach((member, index) => {
			if (index === action.name.length - 1) {
				path[member] = action.value;
			} else if (path[member] === undefined) {
				path[member] = {};
			}
			path = path[member];
		});
	} else {
		newStore[action.name] = action.value;
	}
	return newStore;
};

export default createReducer(initialState, {
	[JSON_REMOVE]: jsonRemove,
	[JSON_SET]: jsonSet
});
