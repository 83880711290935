import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import AnimatedModel from './AnimatedModel';
import {selectFirebaseModels, selectFirebaseUserModels} from '../../selectors/state/firebase';

function Monster(props) {
	const [model, setModel] = useState();

	useEffect(() => {
		const assignmentKey = props.assignments.getModel(props.monsterKey, props.monster);
		const modelKey = props.userModels[assignmentKey]?.model || assignmentKey;

		setModel(props.models[modelKey]);
		props.onSetModel && props.onSetModel(props.models[modelKey], props.monsterKey);
	}, [props.assignments, props.monster]);
	if (model === undefined) return null;
	return (
		<AnimatedModel
			onPointerDown={props.onPointerDown} selected={props.selected}
			model={model} animation={props.animation} name={props.monster.name}/>
	);
}

const mapStateToProps = store => ({
	models: selectFirebaseModels(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {})(Monster);
