import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectFirebaseUserFiles, selectUserImages} from '../../../selectors/state/firebase';
import {selectAppAuthentication} from '../../../selectors/state/app';
import GalleryItem from '../items/GalleryItem';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/characters.jpg`;

function GalleryPanel(props) {
	if (props.view !== 'gallery') return null;

	const handleGalleryClick = event => {
		props.setView('picture', event.currentTarget.dataset.target);
	};

	useEffect(() => {
		props.setTitle('Gallery', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage}/>
			{Object.keys(props.userImages || {}).map(imageKey => {
				return <GalleryItem
					key={imageKey} name={props.userImages[imageKey].name} imageKey={imageKey}
					onClick={handleGalleryClick}/>;
			})}
		</Panel>
	);
}
const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	userImages: selectUserImages(store)
});

export default connect(mapStateToProps, {})(GalleryPanel);
