import firebase from '@firebase/app';
import '@firebase/database';
import 'firebase/app-check';
import {
	firebaseDelete,
	firebaseSave,
	firebaseTrackAuthedUser,
	firebaseUpdate,
	FIREBASE_PUSH_CHILD,
	FIREBASE_GET,
	FIREBASE_REMOVE,
	FIREBASE_SET,
	FIREBASE_TRACK,
	FIREBASE_UNTRACK,
	FIREBASE_REMOVE_FEATURE,
	FIREBASE_AUTHENTICATE,
	FIREBASE_SIGN_OUT,
	FIREBASE_TRACK_AUTHED_USER,
	FIREBASE_TRACK_SCENE,
	FIREBASE_TRACK_SCENE_CHARACTERS,
	FIREBASE_TRACK_USER_CAMPAIGNS,
	FIREBASE_SELECT_CAMPAIGN,
	FIREBASE_SELECT_SCENARIO,
	FIREBASE_UNTRACK_CHARACTERS,
	FIREBASE_TRACK_CHARACTER,
	FIREBASE_UNTRACK_CHARACTER,
	FIREBASE_CREATE_CAMPAIGN,
	FIREBASE_CREATE_SCENARIO,
	FIREBASE_DELETE_CAMPAIGN,
	FIREBASE_TRACK_CAMPAIGN,
	FIREBASE_TRACK_SCENARIO,
	FIREBASE_UNTRACK_SCENARIO,
	FIREBASE_CREATE_SCENE,
	FIREBASE_SET_ACTIVE_CAMPAIGN_SCENE,
	FIREBASE_DELETE_CHARACTER,
	FIREBASE_CREATE_CHARACTER,
	FIREBASE_UNTRACK_SCENE,
	FIREBASE_UPDATE_USERS,
	FIREBASE_INVITE_CAMPAIGN_PLAYER,
	FIREBASE_REMOVE_CAMPAIGN_PLAYER,
	FIREBASE_ACCEPT_INVITE,
	FIREBASE_TRACK_BY_CHILD,
	FIREBASE_TRACK_BY_CHILDREN,
	FIREBASE_TRACK_MONSTERS,
	FIREBASE_TRACK_CAMPAIGN_SCENARIOS_AND_SCENES,
	FIREBASE_EXTEND,
	FIREBASE_TRACK_FRIENDS,
	FIREBASE_UNTRACK_BY_CHILD,
	FIREBASE_TRACK_USER_SCENARIOS,
	FIREBASE_TRACK_USER_SCENES,
	FIREBASE_TRACK_FRIEND_CHARACTERS, FIREBASE_ADD_USER_FILE, FIREBASE_TRACK_USER_RULES
} from '../actions/firebaseActions';
import config from '../data/firebase.json';
import {setUser} from '../actions/appActions';
import {jsonFetch, jsonRemove} from '../actions/jsonActions';
import {FIREBASE_TRACK_ASSIGNMENTS, TRACK_ASSIGNMENTS} from "../actions/monsterActions";

const cache = (() => {
	const references = {};
	const addReference = (path, type) => {
		if (references[path]) return false; // Already got a reference
		references[path] = {
			type: type,
			created: Date.now(),
			reference: firebase.database().ref(path)
		};
		return true;
	};

	return {
		on: (path, name, store) => {
			if (addReference(path, 'on')) {
				references[path].on('value', snapshot => {
					store.dispatch(firebaseSave({[name]: snapshot.val()}));
				});
			}
		},
		once: (path, name, store) => {
			if (addReference(path, 'once')) {
				references[path].once('value', snapshot => {
					store.dispatch(firebaseSave({[name]: snapshot.val()}));
				});
			}
		}
	};
})();

const track = (store, path, name) => {
	firebase.database().ref(path).on('value', snapshot => {
		const state = store.getState();

		if (Array.isArray(name)) {
			if (state.firebase[name[0]] && JSON.stringify(state.firebase[name[0]][name[1]]) === JSON.stringify(snapshot.val())) {
				return;
			}
			store.dispatch(firebaseUpdate(name[0], name[1], snapshot.val()));
		} else {
			if (state.firebase[name] && JSON.stringify(state.firebase[name]) === JSON.stringify(snapshot.val())) {
				return;
			}
			store.dispatch(firebaseSave({[name]: snapshot.val()}));
		}
	});
};

const untrack = (store, path) => {
	if (Array.isArray(path)) {
		firebase.database().ref(path.join('/')).off('value');
		store.dispatch(firebaseDelete(path));
	} else {
		firebase.database().ref(path).off('value');
		store.dispatch(firebaseDelete(path));
	}
};
const firebaseService = () => {
	const get = (store, path, name) => {
		firebase.database().ref(path).once('value', snapshot => {
			store.dispatch(firebaseSave({[name]: snapshot.val()}));
		}).catch(OnFirebaseException);
	};
	const set = (store, path, value) => {
		firebase.database().ref(path).set(value).catch(OnFirebaseException);
	};
	const extend = (store, path, member, value) => {
		const existing = member.reduce((parent, member) => parent[member] || {}, store.getState());

		firebase.database().ref(path).set({
			...existing,
			...value
		}).catch(OnFirebaseException);
	};
	const remove = (store, path) => {
		firebase.database().ref(path).remove().catch(OnFirebaseException);
	};
	const pushChild = (store, path, value) => {
		firebase.database().ref(path).push().set(value).catch(OnFirebaseException);
	};
	const removeFeature = (store, path, feature, connections) => {
		firebase.database().ref(`${path}/${feature}`).remove().catch(OnFirebaseException).then(() => {
			connections.forEach(connection => {
				remove(store, `${path}/${connection.feature}/decorations/${connection.decoration}`);
			});
		});
	};
	const authenticate = store => {
		firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(auth => {
			store.dispatch(firebaseTrackAuthedUser(auth));
		});
	};
	const trackAuthedUser = (store, auth) => {
		firebase.database().ref(`users/${auth.user.uid}`).on('value', snapshot => {
			store.dispatch(setUser(snapshot.val(), { displayName: auth.user.displayName, key: auth.user.uid }));
		});
	};
	const signOut = store => {
		firebase.auth().signOut().then(() => {
			store.dispatch(setUser());
		});
	};
	const trackCampaigns = (store, user) => {
		const updateCampaign = snapshot => {
			const campaign = snapshot.val();

			if (snapshot.exists()) {
				if (campaign.user !== user && campaign.image) {
					// Not my campaign and it has an image so track
					track(store, `userFiles/${campaign.user}/${campaign.image}`, ['userFiles', campaign.image]);
				}
				store.dispatch(firebaseSave({
					campaigns:{
						...store.getState().firebase.campaigns,
						[snapshot.key]: campaign
					}
				}));
			}
		};
		const removeCampaign = snapshot => {
			const campaigns = store.getState().firebase.campaigns;

			delete campaigns[snapshot.key];
			store.dispatch(firebaseSave({campaigns:{...campaigns}}));
		};
		const userCampaigns = firebase.database().ref(`userCampaigns/${user}`);
		const campaigns = firebase.database().ref('campaigns').orderByChild('user').startAt(user).endAt(user);

		userCampaigns.on('child_added', addedSnapshot => {
			firebase.database().ref(`campaigns/${addedSnapshot.key}`).on('value', updateCampaign);
		});
		userCampaigns.on('child_changed', addedSnapshot => {
			firebase.database().ref(`campaigns/${addedSnapshot.key}`).on('value', updateCampaign);
		});
		userCampaigns.on('child_removed', removedSnapshot => {
			firebase.database().ref(`campaigns/${removedSnapshot.key}`).off();
			removeCampaign(removedSnapshot);
		});
		campaigns.on('child_added', updateCampaign);
		campaigns.on('child_changed', updateCampaign);
		campaigns.on('child_removed', removeCampaign);
	};
	const trackScenarios = (store, user) => {
		const updateScenario = snapshot => {
			const scenario = snapshot.val();

			if (scenario.user !== user && scenario.image) {
				// Not my campaign and it has an image so track
				track(store, `userFiles/${scenario.user}/${scenario.image}`, ['userFiles', scenario.image]);
			}
			store.dispatch(firebaseSave({
				scenarios:{
					...store.getState().firebase.scenarios,
					[snapshot.key]: scenario
				}
			}));
		};
		const removeScenario = snapshot => {
			const scenarios = store.getState().firebase.scenarios;

			delete scenarios[snapshot.key];
			store.dispatch(firebaseSave({scenarios:{...scenarios}}));
		};
		const userCampaigns = firebase.database().ref(`userCampaigns/${user}`);
		const scenarios = firebase.database().ref('scenarios').orderByChild('user').startAt(user).endAt(user);

		userCampaigns.on('child_added', campaignAddedSnapshot => {
			const scenariosRef = firebase.database().ref('scenarios').orderByChild('campaign')
				.startAt(campaignAddedSnapshot.key).endAt(campaignAddedSnapshot.key);

			scenariosRef.on('child_added', scenarioAddedSnapshot => {
				updateScenario(scenarioAddedSnapshot);
			});
			scenariosRef.on('child_changed', scenarioAddedSnapshot => {
				updateScenario(scenarioAddedSnapshot);
			});
			scenariosRef.on('child_removed', scenarioRemovedSnapshot => {
				removeScenario(scenarioRemovedSnapshot);
			});
		});
		userCampaigns.on('child_removed', campaignRemovedSnapshot => {
			firebase.database().ref('scenarios').orderByChild('campaign')
				.startAt(campaignRemovedSnapshot.key).endAt(campaignRemovedSnapshot.key).off();
		});
		scenarios.on('child_added', updateScenario);
		scenarios.on('child_changed', updateScenario);
		scenarios.on('child_removed', removeScenario);
	};
	const trackScenes = (store, user) => {
		const updateScene = snapshot => {
			const scene = snapshot.val();

			if (scene.user !== user && scene.image) {
				// Not my campaign and it has an image so track
				track(store, `userFiles/${scene.user}/${scene.image}`, ['userFiles', scene.image]);
			}
			Object.keys(scene.characters || {}).forEach(characterKey => {
				if (scene.characters[characterKey].user !== user) {
					trackCharacter(store, characterKey);
				}
			});
			store.dispatch(firebaseSave({
				scenes:{
					...store.getState().firebase.scenes,
					[snapshot.key]: scene
				}
			}));
		};
		const removeScene = snapshot => {
			const scenes = store.getState().firebase.scenes;

			delete scenes[snapshot.key];
			store.dispatch(firebaseSave({scenes:{...scenes}}));
		};
		const userCampaigns = firebase.database().ref(`userCampaigns/${user}`);
		const scenes = firebase.database().ref('scenes').orderByChild('user').startAt(user).endAt(user);

		userCampaigns.on('child_added', campaignAddedSnapshot => {
			const scenesRef = firebase.database().ref('scenes').orderByChild('campaign')
				.startAt(campaignAddedSnapshot.key).endAt(campaignAddedSnapshot.key);

			scenesRef.on('child_added', sceneAddedSnapshot => {
				updateScene(sceneAddedSnapshot);
			});
			scenesRef.on('child_changed', sceneUpdatedSnapshot => {
				updateScene(sceneUpdatedSnapshot);
			});
			scenesRef.on('child_removed', sceneRemovedSnapshot => {
				removeScene(sceneRemovedSnapshot);
			});
		});
		userCampaigns.on('child_removed', campaignRemovedSnapshot => {
			firebase.database().ref('scenes').orderByChild('campaign')
				.startAt(campaignRemovedSnapshot.key).endAt(campaignRemovedSnapshot.key).off();
		});
		scenes.on('child_added', updateScene);
		scenes.on('child_changed', updateScene);
		scenes.on('child_removed', removeScene);
	};
	const trackSceneCharacters = (store, sceneKey) => {
		firebase.database().ref(`scenes/${sceneKey}/characters`).once('value', sceneSnapshot => {
			const characters = sceneSnapshot.val();

			Object.keys(characters).forEach(characterKey => {
				firebase.database().ref(`characters/${characterKey}`).on('value', characterSnapshot => {
					store.dispatch(firebaseUpdate('characters', characterKey, characterSnapshot.val()));
				});
			});
		}).catch(OnFirebaseException);
	};
	const selectCampaign = (store, campaignKey) => {
		const state = store.getState();
		const campaign = state.firebase.campaigns[campaignKey];

		state.firebase.scenarios = {};
		store.dispatch(firebaseSave(state));
		campaign.scenarios && Object.values(campaign.scenarios).forEach(scenarioKey => {
			firebase.database().ref(`scenarios/${scenarioKey}`).once('value',snapshot => {
				const scenario = snapshot.val();

				store.dispatch(firebaseUpdate('scenarios', scenarioKey, scenario));
			}).catch(OnFirebaseException);
		});
	};
	const selectScenario = (store, scenarioKey) => {
		const state = store.getState();
		const scenario = state.firebase.scenarios[scenarioKey]

		state.firebase.scenes = {};
		store.dispatch(firebaseSave(state));
		scenario.scenes && Object.values(scenario.scenes).forEach(scene => {
			firebase.database().ref(`scenes/${scene}`).once('value',snapshot => {
				store.dispatch(firebaseUpdate('scenes', scene, snapshot.val()));
			}).catch(OnFirebaseException);
		});
	};
	const untrackCharacters = (store, characters) => {
		characters && Object.keys(characters).forEach (characterKey => {
			firebase.database().ref(`characters/${characterKey}`).off();
		});
		store.dispatch(firebaseSave({ characters: undefined }));
	};
	const trackCharacter = (store, characterKey) => {
		firebase.database().ref(`characters/${characterKey}`).on('value', snapshot => {
			if (snapshot.exists()) {
				const character = snapshot.val();
				const user = store.getState().app.authentication.key;

				store.dispatch(firebaseUpdate('characters', characterKey, character));
				if (character.user !== user && character.model) {
					track(store, `userModels/${character.user}/${character.model}`,
						['playerModels', character.model]);
				}
			} else {
				console.log(`Character not found ${characterKey}`);
			}
		});
	};
	const untrackCharacter = (store, characterKey) => {
		firebase.database().ref(`characters/${characterKey}`).off();
		store.dispatch(firebaseSave({ character: undefined }));
	};
	const createCampaign = (store, campaignName) => {
		const state = store.getState();
		const user = state.app.authentication.key;

		firebase.database().ref('campaigns').push().then(campaignReference => {
			campaignReference.set({
				name: campaignName,
				user,
				scenarios: []
			}).catch(OnFirebaseException);
			firebase.database().ref(`userCampaigns/${user}/${campaignReference.key}`)
				.set({timestamp: Date.now()}).catch(OnFirebaseException);
		});
	};
	const deleteCampaign = (store, campaignKey, campaign) => {
		const campaignRef = firebase.database().ref(`campaigns/${campaignKey}`);

		campaignRef.remove().catch(OnFirebaseException);
		campaignRef.off();
		Object.keys(campaign.players || {}).forEach(userKey => {
			firebase.database().ref(`userCampaigns/${userKey}/${campaignKey}`).remove().catch(OnFirebaseException);
		});
	};
	const createScenario = (store, campaignKey, name) => {
		const state = store.getState();

		firebase.database().ref('scenarios').push().then(scenarioReference => {
			scenarioReference.set({
				name: name,
				campaign: campaignKey,
				user: state.app.authentication.key
			}).catch(OnFirebaseException);
		});
	};
	const createScene = (store, scenarioKey, name) => {
		firebase.database().ref('scenes').push().then(sceneReference => {
			sceneReference.set({ name: name, scenario: scenarioKey }).catch(OnFirebaseException);
		});
	};
	const trackScene = (store, sceneKey, trackScene) => {
		firebase.database().ref(`scenes/${sceneKey}`).on('value', snapshot => {
			if (snapshot.exists()) {
				const scene = snapshot.val();

				store.dispatch(firebaseUpdate('scenes', sceneKey, scene));
				if (trackScene) {
					store.dispatch(firebaseSave({scene}));
				}
			}
		});
	};
	const trackScenario = (store, scenarioKey, trackScenario) => {
		firebase.database().ref(`scenarios/${scenarioKey}`).on('value',snapshot => {
			if (snapshot.exists()) {
				const scenario = snapshot.val();

				scenario.scenes && Object.values(scenario.scenes).forEach(sceneKey => {
					trackScene(store, sceneKey);
				});
				store.dispatch(firebaseUpdate('scenarios', scenarioKey, scenario));
				if (trackScenario) {
					store.dispatch(firebaseSave({scenario}));
				}
			}
		});
	};
	const trackCampaign = (store, campaignKey) => {
		firebase.database().ref(`campaigns/${campaignKey}`).on('value', snapshot => {
			if (snapshot.exists()) {
				const campaign = snapshot.val();

				campaign.scenarios && Object.values(campaign.scenarios).forEach(scenarioKey => {
					trackScenario(store, scenarioKey);
				});
				store.dispatch(firebaseSave({campaign}));
			}
		});
	};
	const untrackScenario = (store, scenarioKey, reset) => {
		const state = store.getState();

		firebase.database().ref(`scenarios/${scenarioKey}`).off();
		if (reset) {
			state.firebase.scenario = undefined;
			store.dispatch(firebaseSave(state.firebase));
		}
		store.dispatch(firebaseUpdate('scenarios', scenarioKey, undefined));
	};
	const untrackScene = (store, sceneKey, reset) => {
		firebase.database().ref(`scenes/${sceneKey}`).off();
		store.dispatch(firebaseUpdate('scenes', sceneKey, undefined));
		if (reset) {
			const state = store.getState();

			state.firebase.scene = undefined;
			store.dispatch(firebaseSave(state.firebase));
		}
	};
	const setActiveCampaignScene = (store, campaignKey, scenarioKey, sceneKey) => {
		firebase.database().ref(`campaigns/${campaignKey}/activeScenario`).set(scenarioKey).catch(OnFirebaseException);
		firebase.database().ref(`campaigns/${campaignKey}/activeScene`).set(sceneKey).catch(OnFirebaseException);
	};
	const deleteCharacter = (store, characterKey) => {
		const state = store.getState();
		const user = state.app.authentication.key;

		firebase.database().ref(`characters/${characterKey}`).remove().catch(OnFirebaseException).then(() => {
			firebase.database().ref(`users/${user}/characters`).orderByValue().equalTo(characterKey).once('value', ref => {
				Object.keys(ref.val()).forEach(key => {
					firebase.database().ref(`users/${user}/characters/${key}`).remove().catch(OnFirebaseException);
				});
			}).catch(OnFirebaseException);
		});
	};
	const createCharacter = (store, name, avatar) => {
		const state = store.getState();
		const user = state.app.authentication.key;

		firebase.database().ref('characters').push().then(characterReference => {
			characterReference.set({
				name,
				user,
				avatar
			}).catch(OnFirebaseException);
			firebase.database().ref(`users/${user}/characters`).push().then(userCharacterReference => {
				userCharacterReference.set(characterReference.key).catch(OnFirebaseException);
			}).catch(OnFirebaseException);
		});
	};
	const updateUsers = (store, users) => {
		const userDetails = {};

		Promise.all(users.map(user => {
			return firebase.database().ref(`usersPublic/${user}/nickname`).once('value', snapshot => {
				userDetails[user] = snapshot.val();
			}).catch(OnFirebaseException);
		})).then(() => {
			store.dispatch(firebaseSave({ users: userDetails }));
		});
	};
	const trackFriends = (store, user) => {
		const friendsRef = firebase.database().ref(`friends/${user}`);
		const updateFriend = (friendKey, friend) => {
			firebase.database().ref(`usersPublic/${friendKey}`)
				.on('value', userSnapshot => {
					store.dispatch(firebaseSave({
						friends: {
							...store.getState().firebase.friends,
							[friendKey]: {
								...userSnapshot.val(),
								status: friend.status,
								timestamp: friend.timestamp
							}
						}
					}));
				});
		};

		friendsRef.on('child_added', snapshot => {
			updateFriend(snapshot.key, snapshot.val());
		});
		friendsRef.on('child_removed', removedSnapshot => {
			const friends = store.getState().firebase.friends;

			delete friends[removedSnapshot.key];
			firebase.database().ref(`usersPublic/${removedSnapshot.key}`).off();
			store.dispatch(firebaseSave({friends:{...friends}}));
		});
		friendsRef.on('child_changed', snapshot => {
			updateFriend(snapshot.key, snapshot.val());
		});
	};
	const inviteCampaignPlayer = (store, user, campaign, name, player) => {
		firebase.database().ref(`messages/${player}`).push().then(inviteRef => {
			inviteRef.set({
				type: 'invite',
				campaign,
				user,
				name
			}).then(() => {
				firebase.database().ref(`campaigns/${campaign}/players/${player}`).set({
					invite: inviteRef.key,
					status: 'invited',
					timestamp: Date.now()
				}).catch(OnFirebaseException);
			});
		}).catch(OnFirebaseException);
	};
	const removeCampaignPlayer = (store, campaign, player, invite) => {
		firebase.database().ref(`campaigns/${campaign}/players/${player}`).remove()
			.catch(OnFirebaseException);
		if (invite) {
			firebase.database().ref(`messages/${player}/${invite}`).remove()
				.catch(OnFirebaseException);
		}
		firebase.database().ref(`userCampaigns/${player}/${campaign}`).remove()
			.catch(OnFirebaseException);
	};
	const acceptInvite = (store, campaignKey, userKey, refereeKey) => {
		firebase.database().ref(`campaigns/${campaignKey}/players/${userKey}`).set({
			status: 'player',
			timestamp: Date.now()
		}).catch(OnFirebaseException);
		firebase.database().ref(`userCampaigns/${userKey}/${campaignKey}`).set({
			timestamp: Date.now(),
			user: refereeKey
		}).catch(OnFirebaseException);
	};
	const trackByChild = (store, path, child, from, to) => {
		firebase.database().ref(path).orderByChild(child).startAt(from).endAt(to).on('value', snapshot => {
			store.dispatch(firebaseSave({[path]: {...snapshot.val()}}));
		});
	};
	const untrackByChild = (store, path, child, from, to) => {
		firebase.database().ref(path).orderByChild(child).startAt(from).endAt(to).off('value');
		store.dispatch(firebaseDelete([path]));
	};
	const trackByChildren = (store, path, child, values) => {
		values.forEach(value => {
			firebase.database().ref(path).orderByChild(child).startAt(value).endAt(value).on('value', snapshot => {
				store.dispatch(firebaseUpdate(path, value, snapshot.val()));
			});
		});
	};
	const trackMonsters = (store, groups) => {
		const state = store.getState();

		groups.forEach(group => {
			firebase.database().ref('monsters').orderByChild('group').startAt(group).endAt(group)
				.on('value', snapshot => {
					store.dispatch(firebaseSave({
						monsters: {
							...state.monsters,
							...snapshot.val()
						}
					}));
				});
		});
	};
	const trackCampaignScenariosAndScenes = (store, campaign) => {
		firebase.database().ref('scenarios').orderByChild('campaign')
			.startAt(campaign).endAt(campaign).on('value', scenariosSnapshot => {
				const scenarios = scenariosSnapshot.val();
				const current = store.getState().firebase.scenarios;

				Object.keys(scenarios || {}).forEach(scenario => {
					firebase.database().ref('scenes').orderByChild('scenario')
						.startAt(scenario).endAt(scenario).on('value', scenesSnapshot => {
							const scenes = scenesSnapshot.val();
							const current = store.getState().firebase.scenes;
	
							if (! current || JSON.stringify(current) !== JSON.stringify(scenes)) {
								store.dispatch(firebaseUpdate('scenes', scenes));
							}
						});
				});
				if (! current || JSON.stringify(current) !== JSON.stringify(scenariosSnapshot.val())) {
					store.dispatch(firebaseSave({ scenarios }));
				}
			});
	};
	const trackCollection = (store, reference, update, remove) => {
		reference.on('child_added', snapshot => {
			update(snapshot.key, snapshot.val());
		});
		reference.on('child_removed', snapshot => {
			remove(snapshot.key);
		});
		reference.on('child_changed', snapshot => {
			update(snapshot.key, snapshot.val());
		});
	};
	const trackCollectionToStore = (store, reference, name) => {
		const update = (key, value) => store.dispatch(firebaseUpdate(name, key, value));
		const remove = key => store.dispatch(firebaseDelete([name, key]));

		trackCollection(store, reference, update, remove);
	};

	const trackFriendCharacters = (store, userKey) => {
/*
		trackCollection(store, firebase.database().ref(`friends/${userKey}`),
			'characters', 'friendCharacters');
*/
		firebase.database().ref(`friends/${userKey}`).on('value', friendsSnapshot => {
			Object.keys(friendsSnapshot.val() || {}).forEach(friendKey => {
				firebase.database().ref('characters').orderByChild('user')
					.startAt(friendKey).endAt(friendKey).on('value', snapshot => {
						store.dispatch(firebaseUpdate('friendCharacters', {...snapshot.val()}));
					});
			});
		});
	};
	const addUserFile = (store, user, blob, name, type) => {
		firebase.database().ref(`userFiles/${user}`).push().then(child => {
			firebase.app().storage('gs://content.holdperson.com')
				.ref(`users/${user}/${child.key}`).put(blob).then(snapshot => {
					child.set({
						metadata: {
							contentType: snapshot.metadata.contentType,
							fullPath: snapshot.metadata.fullPath,
							md5Hash: snapshot.metadata.md5Hash,
							size: snapshot.metadata.size,
							timeCreated: snapshot.metadata.timeCreated,
							type: snapshot.metadata.type,
							updated: snapshot.metadata.updated
						},
						url: `https://content.holdperson.com/users/${user}/${child.key}`,
						name,
						type
					}).catch(OnFirebaseException);
				});
		}).catch(OnFirebaseException);
	};
	const trackUserRules = (store, user) => {
		const update = key => {
			firebase.database().ref(`rules/${key}`).on('value', snapshot => {
				const rules = snapshot.val();

				store.dispatch(firebaseUpdate('userRules', key, rules));
				store.dispatch(jsonFetch(['rules', key], rules.path));
			});
		};
		const remove = key => {
			firebase.database().ref(`rules/${key}`).off();
			store.dispatch(firebaseDelete(['userRules', key]));
			store.dispatch(jsonRemove(['rules', key]));
		};

		trackCollection(store, firebase.database().ref(`userRules/${user}`), update, remove);
	};
	const trackAssignments = (store, user) => {
		trackCollectionToStore(store,
			firebase.database().ref('assignments').orderByChild('user').equalTo('default'),
			'assignments');
		trackCollectionToStore(store,
			firebase.database().ref('assignments').orderByChild('user').equalTo('premium'),
			'assignments');
		trackCollectionToStore(store,
			firebase.database().ref('assignments').orderByChild('user').equalTo(user),
			'assignments');
	};

	return store => next => action => {
		switch (action.type) {
		case FIREBASE_REMOVE:
			remove(store, action.path);
			break;
		case FIREBASE_GET:
			get(store, action.path, action.name);
			break;
		case FIREBASE_SET:
			set(store, action.path, action.value);
			break;
		case FIREBASE_EXTEND:
			extend(store, action.path, action.member, action.value);
			break;
		case FIREBASE_TRACK:
			track(store, action.path, action.name);
			break;
		case FIREBASE_UNTRACK:
			untrack(store, action.path);
			break;
		case FIREBASE_PUSH_CHILD:
			pushChild(store, action.path, action.child);
			break;
		case FIREBASE_REMOVE_FEATURE:
			removeFeature(store, action.path, action.feature, action.connections);
			break;
		case FIREBASE_AUTHENTICATE:
			authenticate(store);
			break;
		case FIREBASE_SIGN_OUT:
			signOut(store);
			break;
		case FIREBASE_TRACK_AUTHED_USER:
			trackAuthedUser(store, action.auth);
			break;
		case FIREBASE_TRACK_SCENE:
			trackScene(store, action.scene, true);
			break;
		case FIREBASE_UNTRACK_SCENE:
			untrackScene(store, action.scene, true);
			break;
		case FIREBASE_TRACK_USER_CAMPAIGNS:
			trackCampaigns(store, action.user);
			break;
		case FIREBASE_TRACK_USER_SCENARIOS:
			trackScenarios(store, action.user);
			break;
		case FIREBASE_TRACK_USER_SCENES:
			trackScenes(store, action.user);
			break;
		case FIREBASE_TRACK_SCENE_CHARACTERS:
			trackSceneCharacters(store, action.scene);
			break;
		case FIREBASE_SELECT_CAMPAIGN:
			selectCampaign(store, action.campaign);
			break;
		case FIREBASE_SELECT_SCENARIO:
			selectScenario(store, action.scenario);
			break;
		case FIREBASE_UNTRACK_CHARACTERS:
			untrackCharacters(store, action.characters);
			break;
		case FIREBASE_TRACK_CHARACTER:
			trackCharacter(store, action.character);
			break;
		case FIREBASE_UNTRACK_CHARACTER:
			untrackCharacter(store, action.character);
			break;
		case FIREBASE_CREATE_CAMPAIGN:
			createCampaign(store, action.name);
			break;
		case FIREBASE_DELETE_CAMPAIGN:
			deleteCampaign(store, action.key, action.campaign);
			break;
		case FIREBASE_CREATE_SCENARIO:
			createScenario(store, action.campaignKey, action.name);
			break;
		case FIREBASE_TRACK_CAMPAIGN:
			trackCampaign(store, action.campaignKey);
			break;
		case FIREBASE_TRACK_SCENARIO:
			trackScenario(store, action.scenarioKey, true);
			break;
		case FIREBASE_UNTRACK_SCENARIO:
			untrackScenario(store, action.scenarioKey, true);
			break;
		case FIREBASE_CREATE_SCENE:
			createScene(store, action.scenarioKey, action.name);
			break;
		case FIREBASE_SET_ACTIVE_CAMPAIGN_SCENE:
			setActiveCampaignScene(store, action.campaignKey, action.scenarioKey, action.sceneKey);
			break;
		case FIREBASE_CREATE_CHARACTER:
			createCharacter(store, action.name, action.avatar);
			break;
		case FIREBASE_DELETE_CHARACTER:
			deleteCharacter(store, action.characterKey);
			break;
		case FIREBASE_UPDATE_USERS:
			updateUsers(store, action.users);
			break;
		case FIREBASE_INVITE_CAMPAIGN_PLAYER:
			inviteCampaignPlayer(store, action.user, action.campaign, action.name, action.player);
			break;
		case FIREBASE_REMOVE_CAMPAIGN_PLAYER:
			removeCampaignPlayer(store, action.campaign, action.player, action.invite);
			break;
		case FIREBASE_ACCEPT_INVITE:
			acceptInvite(store, action.campaign, action.user, action.referee);
			break;
		case FIREBASE_TRACK_BY_CHILD:
			trackByChild(store, action.path, action.child, action.from, action.to);
			break;
		case FIREBASE_UNTRACK_BY_CHILD:
			untrackByChild(store, action.path, action.child, action.from, action.to);
			break;
		case FIREBASE_TRACK_BY_CHILDREN:
			trackByChildren(store, action.path, action.child, action.values);
			break;
		case FIREBASE_TRACK_MONSTERS:
			trackMonsters(store, action.groups);
			break;
		case FIREBASE_TRACK_CAMPAIGN_SCENARIOS_AND_SCENES:
			trackCampaignScenariosAndScenes(store, action.campaign);
			break;
		case FIREBASE_TRACK_FRIENDS:
			trackFriends(store, action.user);
			break;
		case FIREBASE_TRACK_FRIEND_CHARACTERS:
			trackFriendCharacters(store, action.user);
			break;
		case FIREBASE_ADD_USER_FILE:
			addUserFile(store, action.user, action.blob, action.name, action.fileType);
			break;
		case FIREBASE_TRACK_USER_RULES:
			trackUserRules(store, action.user);
			break;
		case FIREBASE_TRACK_ASSIGNMENTS:
			trackAssignments(store, action.user);
			break;
		default:
			return next(action);
		}
	};
};

export const OnFirebaseException = exception => {
	console.error(exception.message);
};

export function firebaseInitialise() {
	firebase.initializeApp(config);
	firebase.appCheck().activate('6LeeICEbAAAAAJGN6Hx8eoj3Cj6SVPlamNp8-CfJ');
}

export default firebaseService();
