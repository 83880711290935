import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/rules.jpg`;

export default function RulesItem(props) {
	return (
		<ImageLeftItem
			image={props.rules.image || DefaultImage} target={props.rulesKey} onClick={props.onClick}
			title={props.rules.name} description={props.rules.description}
			favourite={{key: props.rulesKey, data: props.rulesKey, view: 'rules'}}/>
	);
}
