import React from 'react';
import {Card, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({ card: { marginBottom: 16 }}));

export default function PanelItem(props) {
	const classes = useStyles();

	return (
		<Card className={classes.card} variant='outlined'>
			{props.children}
		</Card>
	);
}
