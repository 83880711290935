import {Box, List, ListItem, makeStyles, TextField, Typography} from '@material-ui/core';
import {Scrollbars} from 'react-custom-scrollbars';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {addChat} from '../../actions/chatActions';
import {selectFirebaseChats, selectFirebaseUsers} from '../../selectors/state/firebase';

const useStyles = makeStyles(theme => ({
	content: { flexGrow: 1 }
}));

function ChatPanel(props) {
	const classes = useStyles();
	const scroll = useRef();
	const [chat, setChat] = useState('');
	const [stickyScroll, setStickyScroll] = useState(true);
	const handleTextFieldChange = event => {
		setChat(event.target.value);
		return true;
	};
	const handleSubmitChat = event => {
		if (chat !== '' && event.code === 'Enter') {
			props.addChat(props.channel, chat, props.user);
			setChat('');
			event.target.blur();
		}
		event.stopPropagation();
	};
	const chats = props.chats && props.chats[props.channel] ? Object.keys(props.chats[props.channel]).map(key => {
		const chat = props.chats[props.channel][key];
		const user = props.users && props.users[chat.user] || 'Unknown'; 
		const content = chat.user ? `${user}: ${chat.text}` : chat.text;

		return (
			<ListItem key={key}>
				<Typography variant={'body2'}>{content}</Typography>
			</ListItem>
		);
	}) : [];
	const handleScrollStop = () => {
		setStickyScroll(scroll.current.getValues().top === 1);
	};

	useEffect(() => {
		if (scroll.current && stickyScroll) {
			const values = scroll.current.getValues();

			scroll.current.scrollTop(values.scrollHeight - values.clientHeight);
		}
		props.onChatUpdate(props.panel);
	}, [props.chats]);
	return (
		<Box className={classes.content}>
			<Scrollbars ref={scroll} onScrollStop={handleScrollStop}>
				<List dense disablePadding>
					{chats}
				</List>
			</Scrollbars>
			<TextField
				label={props.label} value={chat} size={props.state === 'min' ? 'small' : 'medium'}
				onChange={handleTextFieldChange} onKeyDown={handleSubmitChat}/>
		</Box>
	);
}

const mapStateToProps = store => ({
	chats: selectFirebaseChats(store), users: selectFirebaseUsers(store)
});
export default connect(mapStateToProps, {
	addChat
})(ChatPanel);
