import React, {Suspense} from 'react';
import {connect} from 'react-redux';
import SkyBox from './SkyBox';
import Sky from './Sky';
import Terrain from './Terrain';
import * as THREE from 'three';
import Sunlight from './Sunlight';
import Weather from './Weather';
import {Images} from '../../config';

const sun = new THREE.Vector3();
function ExternalView(props) {
	const faces = ['left', 'right', 'front', 'back', 'top', 'bottom'];
	const getTexture = (set, face) => `${Images}/skyboxes/${set}/${face}.jpg`;
	const sky = props.skyBox !== 'none' && props.skyBox !== undefined ?
		<SkyBox textures={faces.map(face => getTexture(props.skyBox, face))}/> :
		<Sky elevation={props.sunElevation} azimuth={props.sunAzimuth || 0}/>;
	const zenith = THREE.MathUtils.degToRad(90 - props.sunElevation || 0);
	const phi = THREE.MathUtils.degToRad(props.sunAzimuth || 0);

	sun.setFromSphericalCoords(1, phi, zenith);
	sun.multiplyScalar(50);
	return (
		<>
			<Sunlight zenith={zenith} position={sun}/>
			8amp		<Weather fog={props.fog}/>
			{sky}
			<Suspense fallback={null}>
				<Terrain seed={22}/>
			</Suspense>
		</>
	);
}

const mapStateToProps = store => ({
});

export default connect(mapStateToProps, {
})(ExternalView);
