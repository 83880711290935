import React from 'react';
import {IconButton} from '@material-ui/core';
import Admin from './Admin';
import {AddShoppingCart} from '@material-ui/icons';
import {connect} from 'react-redux';
import {adminAddShopItem} from '../../../actions/firebaseActions';

function ShopAdmin(props) {
	const handleAdminAddClick = () => {
		props.adminAddShopItem();
	};

	return (
		<Admin toolbar={[
			<IconButton key='add' onClick={handleAdminAddClick} color='secondary'><AddShoppingCart/></IconButton>
		]}/>
	);
}
const mapStateToProps = store => ({
});

export default connect(mapStateToProps, {
	adminAddShopItem
})(ShopAdmin);
