import React from 'react';
import { render } from 'react-dom';
import App from './App';
//import reportWebVitals from './reportWebVitals';
import thunk from 'redux-thunk';
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import reducers from './reducers/index';
import firebaseService, { firebaseInitialise } from './middleware/firebaseService';
import jsonService from './middleware/jsonService';
import './index.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
	reducers,
	composeEnhancers(
		applyMiddleware(thunk, firebaseService),
		applyMiddleware(thunk, jsonService)
	)
);
const rootElement = document.getElementById('root');

firebaseInitialise(store);

render(
	<Provider store={store}>
		<App/>
	</Provider>,
	rootElement
);
//reportWebVitals(console.log);
