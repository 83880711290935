import Character from './Character';
import CharacterController from './agent/CharacterController';
import React, {Suspense} from 'react';
import {selectSelectedCharacter} from '../../selectors/state/edit';
import {connect} from 'react-redux';
import {setSelectedCharacter} from '../../actions/editActions';

function Characters(props) {
	const onCharacterSelected = (event, characterKey) => {
		event.stopPropagation();
		if (characterKey === props.selectedCharacter) {
			props.setSelectedCharacter(undefined);
		} else {
			props.setSelectedCharacter(characterKey);
		}
	};

	return (
		<>
			{Object.keys(props.details)
				.map(characterKey => {
					const details = props.details[characterKey];

					return (
						<CharacterController
							key={characterKey} position={details.position} rotation={details.rotation}>
							<Suspense fallback={null}>
								<Character
									onPointerDown={event => onCharacterSelected(event, characterKey)}
									selected={props.selectedCharacter === characterKey}
									character={characterKey}
									animation={details.animation}
									onSetModel={model => {
										props.onSetModel && props.onSetModel(model, characterKey);
									}}/>
							</Suspense>
						</CharacterController>
					);
				})
			}
		</>
	);
}
const mapStateToProps = store => ({selectedCharacter: selectSelectedCharacter(store),});

export default connect(mapStateToProps, {setSelectedCharacter})(Characters);
