import {addChat} from './chatActions';

export const FIREBASE_GET = 'FIREBASE_GET';
export const FIREBASE_SET = 'FIREBASE_SET';
export const FIREBASE_EXTEND = 'FIREBASE_EXTEND';
export const FIREBASE_REMOVE = 'FIREBASE_REMOVE';
export const FIREBASE_TRACK = 'FIREBASE_TRACK';
export const FIREBASE_UNTRACK = 'FIREBASE_UNTRACK';
export const FIREBASE_SAVE = 'FIREBASE_SAVE';
export const FIREBASE_SIGN_OUT = 'FIREBASE_SIGN_OUT';
export const FIREBASE_PUSH_CHILD = 'FIREBASE_PUSH_CHILD';
export const FIREBASE_REMOVE_FEATURE = 'FIREBASE_REMOVE_FEATURE';
export const FIREBASE_AUTHENTICATE = 'FIREBASE_AUTHENTICATE';
export const FIREBASE_TRACK_AUTHED_USER = 'FIREBASE_TRACK_AUTHED_USER';
export const FIREBASE_TRACK_SCENE = 'FIREBASE_TRACK_SCENE';
export const FIREBASE_UNTRACK_SCENE = 'FIREBASE_UNTRACK_SCENE';
export const FIREBASE_TRACK_USER_CAMPAIGNS = 'FIREBASE_TRACK_USER_CAMPAIGNS';
export const FIREBASE_TRACK_USER_SCENARIOS = 'FIREBASE_TRACK_USER_SCENARIOS';
export const FIREBASE_TRACK_USER_SCENES = 'FIREBASE_TRACK_USER_SCENES';
export const FIREBASE_UNTRACK_USER_CAMPAIGNS = 'FIREBASE_UNTRACK_USER_CAMPAIGNS';
export const FIREBASE_TRACK_FRIEND_CHARACTERS = 'FIREBASE_TRACK_FRIEND_CHARACTERS';
export const FIREBASE_TRACK_SCENE_CHARACTERS = 'FIREBASE_TRACK_SCENE_CHARACTERS';
export const FIREBASE_UPDATE = 'FIREBASE_UPDATE';
export const FIREBASE_DELETE = 'FIREBASE_DELETE';
export const FIREBASE_SELECT_CAMPAIGN = 'FIREBASE_SELECT_CAMPAIGN';
export const FIREBASE_SELECT_SCENARIO = 'FIREBASE_SELECT_SCENARIO';
export const FIREBASE_UNTRACK_CHARACTERS = 'FIREBASE_UNTRACK_CHARACTERS';
export const FIREBASE_TRACK_CHARACTER = 'FIREBASE_TRACK_CHARACTER';
export const FIREBASE_UNTRACK_CHARACTER = 'FIREBASE_UNTRACK_CHARACTER';
export const FIREBASE_CREATE_CAMPAIGN = 'FIREBASE_CREATE_CAMPAIGN';
export const FIREBASE_DELETE_CAMPAIGN = 'FIREBASE_DELETE_CAMPAIGN';
export const FIREBASE_CREATE_SCENARIO = 'FIREBASE_CREATE_SCENARIO';
export const FIREBASE_TRACK_CAMPAIGN = 'FIREBASE_TRACK_CAMPAIGN';
export const FIREBASE_TRACK_SCENARIO = 'FIREBASE_TRACK_SCENARIO';
export const FIREBASE_UNTRACK_SCENARIO = 'FIREBASE_UNTRACK_SCENARIO';
export const FIREBASE_CREATE_SCENE = 'FIREBASE_CREATE_SCENE';
export const FIREBASE_DELETE_SCENE = 'FIREBASE_DELETE_SCENE';
export const FIREBASE_SET_ACTIVE_CAMPAIGN_SCENE = 'FIREBASE_SET_ACTIVE_CAMPAIGN_SCENE';
export const FIREBASE_DELETE_CHARACTER = 'FIREBASE_DELETE_CHARACTER';
export const FIREBASE_CREATE_CHARACTER = 'FIREBASE_CREATE_CHARACTER';
export const FIREBASE_UPDATE_USERS = 'FIREBASE_UPDATE_USERS';
export const FIREBASE_UPDATE_FRIENDS = 'FIREBASE_UPDATE_FRIENDS';
export const FIREBASE_INVITE_CAMPAIGN_PLAYER = 'FIREBASE_INVITE_CAMPAIGN_PLAYER';
export const FIREBASE_REMOVE_CAMPAIGN_PLAYER = 'FIREBASE_REMOVE_CAMPAIGN_PLAYER';
export const FIREBASE_ACCEPT_INVITE = 'FIREBASE_ACCEPT_INVITE';
export const FIREBASE_TRACK_BY_CHILD = 'FIREBASE_TRACK_BY_CHILD';
export const FIREBASE_UNTRACK_BY_CHILD = 'FIREBASE_UNTRACK_BY_CHILD';
export const FIREBASE_TRACK_BY_CHILDREN = 'FIREBASE_TRACK_BY_CHILDREN';
export const FIREBASE_TRACK_MONSTERS = 'FIREBASE_TRACK_MONSTERS';
export const FIREBASE_TRACK_CAMPAIGN_SCENARIOS_AND_SCENES = 'FIREBASE_TRACK_CAMPAIGN_SCENARIOS_AND_SCENES';
export const FIREBASE_TRACK_FRIENDS = 'FIREBASE_TRACK_FRIENDS';
export const FIREBASE_ADD_USER_FILE = 'FIREBASE_ADD_USER_FILE';
export const FIREBASE_TRACK_USER_RULES = 'FIREBASE_TRACK_USER_RULES';

export function firebaseGet(path, name) {
	return {
		type: FIREBASE_GET,
		path: path,
		name: name
	};
}

export function firebaseSet(path, value) {
	return {
		type: FIREBASE_SET,
		path: path,
		value: value
	};
}

export function firebaseRemove(path) {
	return {
		type: FIREBASE_REMOVE,
		path: path
	};
}

export function firebaseTrack(path, name) {
	return {
		type: FIREBASE_TRACK,
		path: path,
		name: name
	};
}

export function firebaseUntrack(path) {
	return {
		type: FIREBASE_UNTRACK,
		path: path
	};
}

export function firebaseSave(data) {
	return {
		type: FIREBASE_SAVE,
		data: data
	};
}

export function firebaseUpdate(member, keyOrData, data) {
	if (data === undefined) {
		return {
			type: FIREBASE_UPDATE,
			member,
			data: keyOrData
		};
	} else {
		return {
			type: FIREBASE_UPDATE,
			member,
			key: keyOrData,
			data
		};
	}
}

export function firebaseDelete(member) {
	return {
		type: FIREBASE_DELETE,
		member
	};
}

export function firebaseSignOut() {
	return {
		type: FIREBASE_SIGN_OUT
	};
}

export function firebasePushChild(path, child) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: path,
		child: child
	};
}

export function firebaseAuthenticate(user) {
	return {
		type: FIREBASE_AUTHENTICATE,
		user
	};
}

export function firebaseTrackAuthedUser(auth) {
	return {
		type: FIREBASE_TRACK_AUTHED_USER,
		auth
	};
}

export function firebaseUpdateUsers(users) {
	return {
		type: FIREBASE_UPDATE_USERS,
		users
	};
}

export function firebaseTrackAssignments(tier, user) {
	return {
		type: FIREBASE_TRACK_BY_CHILDREN,
		path: 'assignments',
		child: 'user',
		values: [tier, user]
	};
}

export function firebaseTrackDecorationModels() {
	return {
		type: FIREBASE_TRACK_BY_CHILD,
		path: 'models',
		child: 'type',
		from: 'decoration',
		to: 'decoration'
	};
}

const diceFunction = new RegExp(/(.*?)(\d*)d(\d+)(.*)/, 'g');

export function generateRandom(campaignKey, label, user, expression) {
	const roll = (times, die) => {
		let sum = 0;

		while (times > 0) {
			sum += Math.ceil(Math.random() * die);
			--times;
		}
		return sum;
	};
	let result = expression;
	
	while(result.match(diceFunction)) {
		const matches = [...result.matchAll(diceFunction)];

		matches.forEach(match => {
			result = match[1] + roll(match[2], match[3]) + match[4];
		});
	}
	return addChat(campaignKey, `${label} ${expression}: ${result} = ${eval(result)}`, user);
}

export function setDecorationPosition(featureKey, decorationKey, value) {
	return {
		type: FIREBASE_SET,
		path: `features/${featureKey}/decorations/${decorationKey}/position`,
		value
	};
}

export function setDecorationRotation(featureKey, decorationKey, value) {
	return {
		type: FIREBASE_SET,
		path: `features/${featureKey}/decorations/${decorationKey}/rotation`,
		value
	};
}

export function setDecorationScale(featureKey, decorationKey, value) {
	return {
		type: FIREBASE_SET,
		path: `features/${featureKey}/decorations/${decorationKey}/scale`,
		value
	};
}

export function setUserPublic(userKey, value) {
	return {
		type: FIREBASE_SET,
		path: `usersPublic/${userKey}`,
		value
	};
}

export function trackFriends(user) {
	return {
		type: FIREBASE_TRACK_FRIENDS,
		user
	};
}

export function removeFriend(userKey, friendKey) {
	return dispatch => {
		dispatch({
			type: FIREBASE_REMOVE,
			path: `friends/${userKey}/${friendKey}`
		});
		dispatch({
			type: FIREBASE_REMOVE,
			path: `friends/${friendKey}/${userKey}`
		});
	};
}

export function inviteFriend(userKey, friendKey) {
	return dispatch => {
		dispatch({
			type: FIREBASE_PUSH_CHILD,
			path: `messages/${friendKey}`,
			child: {
				type: 'friend',
				user: userKey
			}
		});
		dispatch({
			type: FIREBASE_SET,
			path: `friends/${userKey}/${friendKey}`,
			value: {
				timestamp: Date.now(),
				status: 'invited'
			}
		});
	};
}

export function trackUserPublic(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `usersPublic/${userKey}`,
		name: 'userPublic'
	};
}

export function updateUserPublic(userKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `usersPublic/${userKey}/${member}`,
		value
	};
}

export function firebaseTrackMessages(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `messages/${userKey}`,
		name: 'messages'
	};
}

export function deleteMessage(userKey, messageKey) {
	return {
		type: FIREBASE_REMOVE,
		path: `messages/${userKey}/${messageKey}`
	};
}

export function acceptInvite(userKey, friendKey) {
	return dispatch => {
		dispatch({
			type: FIREBASE_SET,
			path: `friends/${userKey}/${friendKey}`,
			value: {
				status: 'friend',
				timestamp: Date.now()
			}
		});
		dispatch({
			type: FIREBASE_SET,
			path: `friends/${friendKey}/${userKey}`,
			value: {
				status: 'friend',
				timestamp: Date.now()
			}
		});
	};
}

export function setSceneDetail(sceneKey, detail, value) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/${detail}`,
		value
	};
}

export function trackUserModels(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `userModels/${userKey}`,
		name: 'userModels'
	};
}

export function trackShopItems() {
	return {
		type: FIREBASE_TRACK,
		path: 'shopItems',
		name: 'shopItems'
	};
}

export function trackUserItems(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `userItems/${userKey}`,
		name: 'userItems'
	};
}

export function trackCampaigns(user) {
	return {
		type: FIREBASE_TRACK_USER_CAMPAIGNS,
		user
	};
}

export function updateCampaign(campaignKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `campaigns/${campaignKey}/${member}`,
		value
	};
}

export function trackPlayingCampaigns(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `userCampaigns/${userKey}`,
		name: 'userCampaigns'
	};
}

export function updateCharacter(characterKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `characters/${characterKey}/${member}`,
		value
	};
}

export function updateUserModel(userKey, modelKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `userModels/${userKey}/${modelKey}/${member}`,
		value
	};
}

export function buyModel(userKey, itemKey, shopItem, model) {
	return dispatch => {
		dispatch({
			type: FIREBASE_SET,
			path: `userItems/${userKey}/${itemKey}`,
			value: {
				bought: Date.now()
			}
		});
		dispatch({
			type: FIREBASE_PUSH_CHILD,
			path: `userModels/${userKey}`,
			child: {
				model: shopItem.model,
				name: model.name,
				type: model.type
			}
		});
	};
}
export function buyRules(userKey, itemKey, shopItem, rules) {
	return dispatch => {
		dispatch({
			type: FIREBASE_SET,
			path: `userItems/${userKey}/${itemKey}`,
			value: {bought: Date.now()}
		});
		dispatch({
			type: FIREBASE_SET,
			path: `userRules/${userKey}/${rules}`,
			value: {bought: Date.now()}
		});
	};
}

export function adminAddShopItem() {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'shopItems',
		child: { name: 'New Shop Item' }
	};
}

export function adminSetShopItem(shopItemKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `shopItems/${shopItemKey}/${member}`,
		value
	};
}

export function trackUserRules(user) {
	return {
		type: FIREBASE_TRACK_USER_RULES,
		user
	};
}

export function trackRules() {
	return {
		type: FIREBASE_TRACK,
		path: 'rules',
		name: 'rules'
	};
}

export function trackUserFiles(userKey) {
	return {
		type: FIREBASE_TRACK,
		path: `userFiles/${userKey}`,
		name: 'userFiles'
	};
}

export function addUserImage(user, blob, name) {
	return {
		type: FIREBASE_ADD_USER_FILE,
		user,
		blob,
		name,
		fileType: 'image'
	};
}
export function updatePicture(user, picture, member, value) {
	return {
		type: FIREBASE_SET,
		path: `userFiles/${user}/${picture}/${member}`,
		value
	};
}
export function trackUserFavourites(user) {
	return {
		type: FIREBASE_TRACK,
		path: `userFavourites/${user}`,
		name: 'userFavourites'
	};
}
export function setUserFavourite(user, key, view, name, image, data) {
	return {
		type: FIREBASE_SET,
		path: `userFavourites/${user}/${key}`,
		value: {
			data,
			image,
			name,
			timestamp: Date.now(),
			view
		}
	};
}
export function removeUserFavourite(user, key) {
	return {
		type: FIREBASE_REMOVE,
		path: `userFavourites/${user}/${key}`
	};
}
export function trackThemes() {
	return {
		type: FIREBASE_TRACK,
		path: 'themes',
		name: 'themes'
	};
}
export function trackInvites(user) {
	return {
		type: FIREBASE_TRACK_BY_CHILD,
		path: 'invites',
		child: 'user',
		from: user,
		to: user
	};
}
export function addInvite(user) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'invites',
		child: {
			added: Date.now(),
			user
		}
	};
}
export function trackUser(user) {
	return {
		type: FIREBASE_TRACK,
		path: `usersPublic/${user}`,
		name: ['users', user]
	};
}
