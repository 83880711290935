import React, {useRef, useEffect, useState} from 'react';
import {OrbitControls, PerspectiveCamera } from '@react-three/drei';
import Room from '../features/Room';
import Corridor from '../features/Corridor';
import Staircase from '../features/Staircase';
import {connect} from 'react-redux';
import {setSelected, setSelectedCharacter, setSelectedDecoration, setSelectedMonster} from '../../actions/editActions';
import {selectSelectedBoxes, selectSelectedCharacter, selectSelectedMonster}
	from '../../selectors/state/edit';
import {selectViewMode} from '../../selectors/state/app';
import {setCharacterPosition} from '../../actions/characterActions';
import {setMonsterPosition, trackMonsters} from '../../actions/monsterActions';
import {selectFirebaseCampaigns, selectFirebaseFeatures, selectFirebaseMonsters} from '../../selectors/state/firebase';
import Monsters from '../monsters/Monsters';
import Characters from '../play/Characters';
import SelectedBox from '../edit/SelectedBox';
import SelectedFigure from '../edit/SelectedFigure';
import RefereeControls from './RefereeControls';
import ExternalView from '../external/ExternalView';
import Features from '../features/Features';

function RefereeScene(props) {
	const campaign = props.campaigns[props.scene.campaign];
	const [selectedFigurePosition, setSelectedFigurePosition] = useState(undefined);
	const [dragging, setDragging] = useState(false);
	const sceneKey = props.viewMode.data.sceneKey;
	const controls = useRef();
	const camera = useRef();
	const components = {
		'room': props => <Room {...props}/>,
		'corridor': props => <Corridor {...props}/>,
		'staircase': props => <Staircase {...props}/>
	};
	const onTileSelected = (feature, box) => {
		const character = props.selectedCharacter === undefined &&
			Object.keys(props.scene.characters || {}).find(characterKey => {
				const character = props.scene.characters[characterKey];

				return character.position.every((coordinate, index) => coordinate === box[index]);
			});

		if (character) {
			setSelectedFigurePosition(box);
			props.setSelectedCharacter(character);
			props.setSelectedMonster(undefined);
			props.setSelected(undefined, []);
		} else {
			const monster = props.selectedMonster === undefined &&
				Object.keys(props.scene.monsters || {}).find(monsterKey => {
					const monster = props.scene.monsters[monsterKey];

					return monster.position.every((coordinate, index) => coordinate === box[index]);
				});

			props.setSelectedCharacter(undefined);
			props.setSelectedDecoration(undefined);
			if (monster) {
				setSelectedFigurePosition(box);
				props.setSelectedMonster(monster);
				props.setSelected(undefined, []);
			} else {
				setSelectedFigurePosition(undefined);
				props.setSelectedMonster(undefined);
				if (props.selectedBoxes.length &&
					props.selectedBoxes[0].every((coordinate, index) => coordinate === box[index])) {
					props.setSelected(feature, []);
				} else {
					props.setSelected(feature, [box]);
				}
			}
		}
		return true;
	};
	const onPointerMove = (event, box) => {
		controls.current.enabled = !dragging;
		if (dragging && (props.selectedCharacter || props.selectedMonster)) {
			setSelectedFigurePosition(box);
		}
		return true;
	};
	const onPointerDown = (event, box) => {
		setDragging(Object.values(props.scene.characters || {}).some(character => {
			return character.position.every((coordinate, index) => coordinate === box[index]);
		}) || Object.values(props.scene.monsters || {}).some(monster => {
			return monster.position.every((coordinate, index) => coordinate === box[index]);
		}));
		return true;
	};
	const onPointerUp = (event, box) => {
		if (dragging) {
			if (props.selectedCharacter) {
				props.setCharacterPosition(sceneKey, props.selectedCharacter, box);
			} else if (props.selectedMonster) {
				props.setMonsterPosition(sceneKey, props.selectedMonster, box);
			}
		}
		setDragging(false);
		return true;
	};
	const onDraggingChanged = dragging => {
		controls.current.enabled = !dragging;
		setDragging(dragging);
	};
	const featureHandlers = {
		onDraggingChanged,
		onPointerMove,
		onPointerUp,
		onPointerDown,
		onTileSelected
	};

	useEffect(() => {
		camera.current.lookAt(10, 10, -10);
		campaign.groups && props.trackMonsters(Object.values(campaign.groups));
	}, []);
	useEffect(() => {
		if (props.selectedCharacter) {
			setSelectedFigurePosition(props.scene.characters[props.selectedCharacter].position);
		} else if (props.selectedMonster) {
			setSelectedFigurePosition(props.scene.monsters[props.selectedMonster].position);
		} else {
			if (selectedFigurePosition) {
				setSelectedFigurePosition(undefined);
			}
		}
	}, [props.scene, props.selectedCharacter, props.selectedMonster]);
	return (
		<>
			<OrbitControls ref={controls}/>
			<PerspectiveCamera
				fov={35} aspec={window.innerWidth / window.innerHeight} near={1} far={100}
				up={[0, 0, 1]} position={[-15, -15, 15]} ref={camera} makeDefault/>
			{props.scene?.external ?
				<ExternalView
					fog={props.scene.fog}
					skyBox={props.scene.skyBox}
					sunAzimuth={props.scene.azimuth}
					sunElevation={props.scene.elevation}
				/> :
				<ambientLight args={['#FF8844', 0.4]}/>}
			<Features scene={props.scene} theme={props.scene.theme || 'default'} {...featureHandlers}/>
			{props.selectedBoxes.map((box, index) => (<SelectedBox key={index} position={box}/>))}
			<Characters details={props.scene?.characters || {}}/>
			<Monsters monsters={props.monsters} details={props.scene.monsters || []}/>
			{selectedFigurePosition && <SelectedFigure position={selectedFigurePosition}/>}
			<RefereeControls scene={props.scene}/>
		</>
	);
}

const mapStateToProps = store => ({
	campaigns: selectFirebaseCampaigns(store),
	features: selectFirebaseFeatures(store),
	monsters: selectFirebaseMonsters(store),
	selectedBoxes: selectSelectedBoxes(store),
	selectedCharacter: selectSelectedCharacter(store),
	selectedMonster: selectSelectedMonster(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	setCharacterPosition,
	setMonsterPosition,
	setSelected,
	setSelectedCharacter,
	setSelectedDecoration,
	setSelectedMonster,
	trackMonsters
})(RefereeScene);
