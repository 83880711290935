import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import {DefaultImage as DefaultUserImage} from '../items/FriendItem';
import {selectFirebaseFriends, selectFirebaseUsers} from '../../../selectors/state/firebase';
import {trackUser} from '../../../actions/firebaseActions';

function UserListItem(props) {
	const [user, setUser] = useState({ nickname: 'Unknown' });

	useEffect(() => {
		if (props.friends[props.user]) {
			setUser(props.friends[props.user]);
		} else if (props.users[props.user]) {
			setUser(props.users[props.user]);
		} else {
			props.trackUser(props.user);
		}
	}, [props.friends, props.user, props.users]);
	return (
		<ListItem alignItems="flex-start">
			<ListItemAvatar>
				<Avatar alt={user.nickname} src={user.image || DefaultUserImage}/>
			</ListItemAvatar>
			<ListItemText primary={user.nickname} secondary={props.secondary}/>
			{props.action && <ListItemSecondaryAction>{props.action}</ListItemSecondaryAction>}
		</ListItem>
	);
}
const mapStateToProps = store => ({
	friends: selectFirebaseFriends(store),
	users: selectFirebaseUsers(store) || {}
});

export default connect(mapStateToProps, {
	trackUser
})(UserListItem);
