import {FIREBASE_PUSH_CHILD, FIREBASE_TRACK, FIREBASE_UNTRACK} from './firebaseActions';

export function trackChat(channel) {
	return dispatch => {
		dispatch({
			type: FIREBASE_TRACK,
			path: 'chats/world',
			name: ['chats', 'world']
		});
		dispatch({
			type: FIREBASE_TRACK,
			path: `chats/${channel}`,
			name: ['chats', channel]
		});
	};
}

export function untrackChat() {
	return {
		type: FIREBASE_UNTRACK,
		path: 'chats'
	};
}

export function addChat(channel, text, user) {
	const timestamp = Date.now();

	return {
		type: FIREBASE_PUSH_CHILD,
		path: `chats/${channel}`,
		child: {
			text,
			timestamp,
			user
		}
	};
}
