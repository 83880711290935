import firebase from '@firebase/app';
import {FirebaseAuthProvider, IfFirebaseAuthed, IfFirebaseUnAuthed} from '@react-firebase/auth';
import firebaseConfig from './data/firebase.json';
import React from 'react';
import {Button, Container, CssBaseline} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import submitNormal from './css/images/authentication/GoogleSignIn.png';
import {connect} from 'react-redux';
import {firebaseAuthenticate, firebaseTrackAuthedUser} from './actions/firebaseActions';
import {selectAppUser} from './selectors/state/app';

const useStyles = makeStyles((theme) => ({
	paper: {
		paddingTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	submit: {
		backgroundImage: `url(${submitNormal})`,
		margin: theme.spacing(3, 0, 2),
		padding: '22px 96px'
	}
}));

function Authentication (props) {
	const classes = useStyles();
	const showModal = content => {
		return <Container maxWidth='md'>
			<CssBaseline/>
			<div className={classes.paper}>
				{content}
			</div>
		</Container>;
	};
	const signIn = () => {
		props.firebaseAuthenticate();
	};

	if (props.user === undefined) {
		return (<FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
			<IfFirebaseAuthed>
				{authState => {
					props.firebaseTrackAuthedUser(authState);
					return showModal(<p>Checking state..</p>);
				}}
			</IfFirebaseAuthed>
			<IfFirebaseUnAuthed>
				{ () => {
					return showModal(
						<>
							<p>You need to authenticate before you can use this service.</p>
							<Button className={classes.submit} onClick={signIn}/>
						</>
					);
				}}
			</IfFirebaseUnAuthed>
		</FirebaseAuthProvider>);
	} else if (props.user && props.user.roles && props.user.roles.player) {
		return props.children;
	} else {
		return showModal(
			<>
				<p>You are authenticated but do not currently have permission to view the database.</p>
				<p>You can request permission from alex@logjam.co.uk</p>
			</>
		);
	}
}

const mapStateToProps = store => ({user: selectAppUser(store)});

export default connect(mapStateToProps, {
	firebaseAuthenticate,
	firebaseTrackAuthedUser
})(Authentication);
