import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import {selectFirebaseModels, selectFirebaseUserItems, selectFirebaseUserModels, selectFirebaseShopItems}
	from '../../../selectors/state/firebase';
import ContentCover from '../items/ContentCover';
import {updateUserModel} from '../../../actions/firebaseActions';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Avatar, ListItem, ListItemAvatar, ListItemText, MenuItem, Radio, RadioGroup, TextField}
	from '@material-ui/core';
import {DefaultImage} from '../items/SceneItem';
import SearchTool from '../items/SearchTool';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/character.jpg`;

function ModelPanel(props) {
	if (props.view !== 'model') return null;

	const userModel = props.userModels[props.data.model];
	const [filter, setFilter] = useState('');
	const handleSearchChange = event => {
		setFilter(event.target.value.toLowerCase());
	};
	const handleSetImage = value => {
		props.updateUserModel(props.auth.key, props.data.model, 'image', value);
	};
	const handleSetName = value => {
		props.updateUserModel(props.auth.key, props.data.model, 'name', value);
	};
	const handleSetDescription = value => {
		props.updateUserModel(props.auth.key, props.data.model, 'description', value);
	};
	const handleModelTypeChange = event => {
		props.updateUserModel(props.auth.key, props.data.model, 'type', event.target.value);
	};
	const handleModelRadioChange = event => {
		props.updateUserModel(props.auth.key, props.data.model, 'model', event.target.value);
	};

	useEffect(() => {
		props.setTitle(userModel.name, ['models', 'player', 'monster']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCover
				image={userModel.image || defaultImage} setImage={handleSetImage}
				name={userModel.name} setName={handleSetName}
				description={userModel.description} setDescription={handleSetDescription}
				toolbar={[
					<SearchTool key={'search'} placeholder='Filter models' onChange={handleSearchChange}/>
				]}
			>
				<TextField label='Model Type' select value={userModel.type} fullWidth onChange={handleModelTypeChange}>
					<MenuItem aria-label='None' value='' />
					<MenuItem value='character'>Character</MenuItem>
					<MenuItem value='decoration'>Decoration</MenuItem>
				</TextField>
			</ContentCover>
			<RadioGroup value={userModel.model || ''} onChange={handleModelRadioChange}>
				{Object.keys(props.userItems || {})
					.filter(itemKey => {
						const item = props.shopItems[itemKey];

						return item.type === 'model' &&
							(filter === '' || item.name.toLowerCase().includes(filter));
					})
					.map(itemKey => {
						const item = props.shopItems[itemKey];
						const model = props.models[item.model];

						return <ListItem key={itemKey} alignItems="flex-start">
							<ListItemAvatar>
								<Avatar alt={model.name} src={model.image || DefaultImage}/>
							</ListItemAvatar>
							<ListItemText primary={model.name}/>
							<Radio value={item.model}/>
						</ListItem>;
					})}
			</RadioGroup>
		</Panel>
	);
}
const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	shopItems: selectFirebaseShopItems(store),
	userModels: selectFirebaseUserModels(store),
	userItems: selectFirebaseUserItems(store)
});

export default connect(mapStateToProps, {updateUserModel})(ModelPanel);
