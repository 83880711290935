import './App.css';
import React from 'react';
import Portal from './components/portal/Portal';
import Authentication from './Authentication';
import { connect } from 'react-redux';
import { selectAppVersion } from './selectors/state/app';

function App() {
	return (
		<Authentication>
			<Portal/>
		</Authentication>
	);
}

const mapStateToProps = store => ({ version: selectAppVersion(store) });

export default connect(mapStateToProps)(App);
