import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectCharacterModels, selectFirebaseCampaigns, selectFirebaseCharacters,
	selectFirebaseScenarios, selectFirebaseScenes} from '../../../selectors/state/firebase';
import {setViewMode} from '../../../actions/appActions';
import Panel from './Panel';
import ContentCover from '../items/ContentCover';
import {updateCharacter} from '../../../actions/firebaseActions';
import {deleteCharacter} from '../../../actions/characterActions';
import {IconButton, ListItem, ListItemAvatar, ListItemText, Radio, RadioGroup, Tab, Tabs} from '@material-ui/core';
import {Delete, PlayCircleOutline} from '@material-ui/icons';
import {DefaultImage} from '../items/SceneItem';
import Avatar from '../Avatar';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/character.jpg`;

function CharacterPanel(props) {
	if (props.view !== 'character') return null;

	const character = props.characters[props.data.character];
	const handleSetImage = image => {
		props.updateCharacter(props.data.character, 'image', image);
	};
	const handleSetName = name => {
		props.updateCharacter(props.data.character, 'name', name);
	};
	const handleSetBio = bio => {
		props.updateCharacter(props.data.character, 'bio', bio);
	};
	const handleArchiveCharacter = () => {
		props.deleteCharacter(props.data.character);
		props.setView('characters');
	};
	const handleModelRadioChange = event => {
		props.updateCharacter(props.data.character, 'model', event.target.value);
	};
	const handleClickPlayScene = event => {
		props.setViewMode('player', {
			campaignKey: event.currentTarget.dataset.campaign,
			scenarioKey: event.currentTarget.dataset.scenario,
			sceneKey: event.currentTarget.dataset.scene
		});
	};
	const [tab, setTab] = useState(0);
	const handleTabChange = (event, newTab) => {
		setTab(newTab);
	};
	const tabs = <Tabs key='tabs' value={tab} onChange={handleTabChange} style={{flexGrow: 2}}>
		<Tab label='Models'/>
		<Tab label='Scenes'/>
	</Tabs>;
	const tabContent = [
		<RadioGroup
			key='model' aria-label='model' name='model'
			value={character.model}
			onChange={handleModelRadioChange}>
			{Object.keys(props.models).map(modelKey => {
				const model = props.models[modelKey];

				return <ListItem key={modelKey} alignItems="flex-start">
					<ListItemAvatar>
						<Avatar image={model.image || DefaultImage} alt={model.name}/>
					</ListItemAvatar>
					<ListItemText primary={model.name}/>
					<Radio value={modelKey}/>
				</ListItem>;
			})}
		</RadioGroup>,
		Object.keys(props.scenes || {})
			.filter(sceneKey => props.scenes[sceneKey].characters && props.scenes[sceneKey].characters[props.data.character])
			.map(sceneKey => {
				const scene = props.scenes[sceneKey];
				const scenario = props.scenarios[scene.scenario];
				const campaign = props.campaigns[scenario.campaign];

				return <ListItem key={sceneKey} alignItems="flex-start">
					<ListItemAvatar>
						<Avatar image={scene.image} alt={scene.name} src={DefaultImage}/>
					</ListItemAvatar>
					<ListItemText primary={scene.name} secondary={`${campaign.name}: ${scenario.name}`}/>
					<IconButton
						data-campaign={scenario.campaign} data-scenario={scene.scenario} data-scene={sceneKey}
						aria-label='play scene' title='Play Scene' onClick={handleClickPlayScene}>
						<PlayCircleOutline/>
					</IconButton>
				</ListItem>;
			})
	];

	useEffect(() => {
		props.setTitle(character.name, ['characters', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCover
				image={character.image || defaultImage} setImage={handleSetImage}
				name={character.name} setName={handleSetName}
				description={character.bio} setDescription={handleSetBio}
				toolbar={[
					tabs, <IconButton key='archive' color='inherit' onClick={handleArchiveCharacter}><Delete/></IconButton>
				]}
			/>
			{tabContent[tab]}
		</Panel>
	);
}

const mapStateToProps = store => ({
	campaigns: selectFirebaseCampaigns(store),
	characters: selectFirebaseCharacters(store),
	models: selectCharacterModels(store),
	scenarios: selectFirebaseScenarios(store),
	scenes: selectFirebaseScenes(store)
});

export default connect(mapStateToProps, {
	deleteCharacter,
	setViewMode,
	updateCharacter
})(CharacterPanel);
