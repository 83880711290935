import {List, ListItem, Button, Box, Select, MenuItem, FormControl, InputLabel, Chip, IconButton, Typography}
	from '@material-ui/core';
import React, {useState} from 'react';
import Modal from './Modal';
import {connect} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import MonsterStats from '../monsters/srd/MonsterStats';
import {AddCircle} from '@material-ui/icons';
import {makeStyles} from '@material-ui/core/styles';
import {selectMonsterRules} from '../../selectors/state/jsonSelector';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		flexDirection : 'column',
		height: '80vh',
		width: '100%'
	},
	filter: {display:'flex', marginBottom: theme.spacing(1)},
	filters: {
		alignItems: 'flex-end',
		display: 'inline-flex',
		flexWrap: 'nowrap',
		justifyContent: 'right',
		'& > *': { margin: 2, },
		height: '100%',
		width: '100%'
	},
	filterLabel: {textTransform: 'capitalize' },
	monsterList: {width: '30%'},
	monsterPanel: {
		margin: theme.spacing(1),
		padding: theme.spacing(1),
		width: '70%'
	},
	monsters: {display:'flex', height: '100%'},
}));

function Filter(type, value) {
	return {
		label: `${type}: ${value}`,
		filters: monster => monster[type].toLowerCase() === value.toLowerCase(),
		type,
		value
	};
}

function ModalSelectMonster(props) {
	const [filters, setFilters] = useState([]);
	const [filterType, setFilterType] = useState('none');
	const [filterValue, setFilterValue] = useState('none');
	const [selected, setSelected] = useState();
	const confirm = () => {
		props.cancel();
		props.confirm(selected);
	};
	const onFilterType = event => {
		setFilterType(event.target.value);
		setFilterValue('none');
	};
	const onFilterValue = event => {
		setFilterValue(event.target.value);
	};
	const filterStyle = { minWidth: 100 };
	const filterValues = {
		alignment: [ 'Lawful Good', 'Neutral Good', 'Chaotic Good', 'Lawful Neutral', 'Neutral',
			'Chaotic Neutral', 'Lawful Evil', 'Neutral Evil', 'Chaotic Evil'
		],
		size: [ 'Tiny', 'Small', 'Medium', 'Large', 'Huge', 'Gargantuan' ],
		type: [ 'aberration', 'animal', 'celestial', 'construct', 'dragon', 'elemental', 'fey', 'fiend',
			'giant', 'humanoid', 'magical beast', 'monstrous humanoid', 'ooze', 'outsider', 'plant',
			'undead', 'vermin']
	};
	const classes = useStyles();
	const handleMonsterClick = event => setSelected(event.currentTarget.dataset.key);

	return (
		<Modal cancel={props.cancel} confirm={confirm} flexDirection='column'>
			<Box className={classes.content}>
				<Box className={classes.filter}>
					<FormControl style={filterStyle}>
						<InputLabel color='primary' id='FilterTypeSelectLabel'>Filter Type</InputLabel>
						<Select
							labelId='FilterTypeSelectLabel' autoWidth label='Filter Type' onChange={onFilterType}
							value={filterType}>
							<MenuItem value='none'><em>None</em></MenuItem>
							{Object.keys(filterValues).map(type =>
								<MenuItem key={type} value={type}>
									<em className={classes.filterLabel}>{type}</em>
								</MenuItem>)}
						</Select>
					</FormControl>
					<FormControl style={filterStyle}>
						<InputLabel id='FilterValueSelectLabel'>Filter Value</InputLabel>
						<Select
							labelId='FilterValueSelectLabel' autoWidth label='Filter Value' onChange={onFilterValue}
							value={filterValue}>
							<MenuItem value='none'><em>None</em></MenuItem>
							{filterValues[filterType] && filterValues[filterType].map(value => {
								return <MenuItem key={value} value={value}>
									<em className={classes.filterLabel}>{value}</em>
								</MenuItem>;
							})}
						</Select>
					</FormControl>
					{filterValue !== 'none' &&
						<IconButton color='primary' aria-label='add filter' onClick={() => {
							if (! filters.some(filter => filter.type === filterType && filter.value === filterValue)) {
								setFilters(filters.concat([new Filter(filterType, filterValue)]));
							}
						}}>
							<AddCircle/>
						</IconButton>}
					<Box flexGrow={1}>
						<Scrollbars>
							<li className={classes.filters}>
								{filters.length > 0 && filters.map(filter => {
									return (
										<Chip
											key={filter.label} label={filter.label} variant='outlined' color='primary'
											onDelete={() => {
												setFilters(filters.filter(test => test.label !== filter.label));
											}}/>
									);
								})}
							</li>
						</Scrollbars>
					</Box>
				</Box>
				<Box className={classes.monsters}>
					<Box className={classes.monsterList}>
						<Scrollbars hideTracksWhenNotNeeded>
							<List dense disablePadding>
								{props.monsters && Object.keys(props.monsters)
									.filter(key => filters.every(filter => filter.filters(props.monsters[key])))
									.map(monsterKey => {
										return (
											<ListItem
												key={monsterKey} data-key={monsterKey} button
												onClick={handleMonsterClick}>
												{props.monsters[monsterKey].name}
											</ListItem>
										);
									})}
							</List>
						</Scrollbars>
					</Box>
					<Box className={classes.monsterPanel}>
						<Scrollbars hideTracksWhenNotNeeded>
							{selected &&
								<Box>
									<Typography variant='h5'>{props.monsters[selected].name}</Typography>
									<MonsterStats monster={props.monsters[selected]}/>
								</Box>}
						</Scrollbars>
					</Box>
				</Box>
			</Box>
		</Modal>
	);
}

const mapStateToProps = store => ({
	monsters: selectMonsterRules(store)
});
export default connect(mapStateToProps, {
	
})(ModalSelectMonster);
