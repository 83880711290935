import {useThree} from 'react-three-fiber';
import {selectScreenShot} from '../../selectors/state/app';
import {connect} from 'react-redux';
import {recordScreenShot} from '../../actions/appActions';

function ScreenShot(props) {
	const { gl, scene, camera } = useThree();
	const domElement = gl.domElement;

	if (props.screenShot.state === 'pending') {
		domElement.getContext('webgl', { preserveDrawingBuffer: true });
		gl.render(scene, camera);
		domElement.toBlob(props.recordScreenShot, 'image/jpeg', 0.8);
		domElement.getContext('webgl', { preserveDrawingBuffer: false });
	}
	return null;
}
const mapStateToProps = store => ({screenShot: selectScreenShot(store)});

export default connect(mapStateToProps, {recordScreenShot})(ScreenShot);
