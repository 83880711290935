import Assignment from './Assignment';

export default function UserAssignments(userKey, user, assignments) {
	const userAssignments = Object.fromEntries(
		Object.entries(assignments).map(entry => [entry[0], new Assignment(entry[1])])
			.filter(entry => entry[1].resolveUser(userKey, user)));
	const sorted = Object.keys(userAssignments).sort((lhs, rhs) => 
		userAssignments[rhs].sortOrder - userAssignments[lhs].sortOrder);
	const getAssignment = (monsterKey, monster, field) =>
		sorted.map(key => userAssignments[key]).find(assignment => assignment.resolveMonster(monsterKey, monster) &&
			(field === undefined || assignment[field])); 

	return {
		getModel: (monsterKey, monster) => getAssignment(monsterKey, monster, 'model').model,
		getImage: (monsterKey, monster) => getAssignment(monsterKey, monster, 'image').image,
		assignments: userAssignments
	};
}
