import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import MessageItem from '../items/MessageItem';
import {selectFirebaseMessages} from '../../../selectors/state/firebase';

function MessagesPanel(props) {
	if (props.view !== 'messages') return null;

	const messages = Object.keys(props.messages || {}).map(messageKey => {
		return <MessageItem key={messageKey} messageKey={messageKey} message={props.messages[messageKey]}/>;
	});

	useEffect(() => {
		props.setTitle('Messages', 'home');
	}, []);
	return (
		<Panel visible={true}>
			{messages}
		</Panel>
	);
}

const mapStateToProps = store => ({
	messages: selectFirebaseMessages(store)
});

export default connect(mapStateToProps, {})(MessagesPanel);
