export const APP_SET = 'APP_SET';
export const APP_SET_MODE = 'APP_SET_MODE';

export function setAdmin(admin) {
	return {
		type: APP_SET,
		data: {
			admin
		}
	};
}

export function setViewMode(name, data) {
	return {
		type: APP_SET_MODE,
		data: {
			name,
			data
		}
	};
}

export function setScene(editScene) {
	return {
		type: APP_SET,
		data: {
			editScene: editScene
		}
	};
}

export function setUser(user, authentication) {
	return {
		type: APP_SET,
		data: {
			user: user,
			authentication: authentication
		}
	};
}

export function setHighlightTile(highlightTile) {
	return {
		type: APP_SET,
		data: {
			highlightTile
		}
	};
}
export function setHighlightState(highlightState) {
	return {
		type: APP_SET,
		data: {
			highlightState
		}
	};
}
export function takeScreenShot() {
	return {
		type: APP_SET,
		data: { screenShot: { state: 'pending' } }
	};
}
export function recordScreenShot(blob) {
	return {
		type: APP_SET,
		data: { screenShot: { state: 'taken', blob } }
	};
}
export function clearScreenShot() {
	return {
		type: APP_SET,
		data: { screenShot: { state: 'default' } }
	};
}
