import { createSelector } from 'reselect';

export const selectEdit = createSelector(store => store, store => store.edit);
export const selectEditing = createSelector(selectEdit, edit => edit.edit);
export const selectEditAdding = createSelector(selectEdit, edit => edit.adding);
export const selectEditState = createSelector(selectEdit, edit => edit.state);
export const selectSelectedMonster = createSelector(selectEdit, edit => edit.selectedMonster);
export const selectSelectedBoxes = createSelector(selectEdit, edit => edit.selectedBoxes);
export const selectSelectedCharacter = createSelector(selectEdit, edit => edit.selectedCharacter);
export const selectSelectedDecorationDetails = createSelector(selectEdit, edit => edit.selectedDecoration);
export const selectSelectedDecoration = createSelector(selectEdit, edit => edit.selectedDecoration);
export const selectSelectedFeature = createSelector(selectEdit, edit => edit.selectedFeature);
