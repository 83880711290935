import { createReducer } from '.';
import {FIREBASE_DELETE, FIREBASE_SAVE, FIREBASE_UPDATE} from '../actions/firebaseActions';
import 'firebase/auth';

const firebaseSave = (firebaseStore, action) => {
	return {
		...firebaseStore,
		...action.data
	};
};

const firebaseUpdate = (firebaseStore, action) => {
	if (action.key) {
		return {
			...firebaseStore,
			[action.member]: {
				...firebaseStore[action.member],
				[action.key]: action.data
			}
		};
	} else {
		return {
			...firebaseStore,
			[action.member]: {
				...firebaseStore[action.member],
				...action.data
			}
		};
	}
};

const firebaseDelete = (firebaseStore, action) => {
	const newStore = {...firebaseStore};

	if (Array.isArray(action.member)) {
		if (newStore[action.member[0]]) {
			delete newStore[action.member[0]][action.member[1]];
			if (Object.keys(newStore[action.member[0]]).length === 0) {
				delete newStore[action.member[0]];
			}
		}
	} else {
		delete newStore[action.member];
	}
	return newStore;
};

const initialState = {
	characters: {}
};

export default createReducer(initialState, {
	[FIREBASE_DELETE]: firebaseDelete,
	[FIREBASE_SAVE]: firebaseSave,
	[FIREBASE_UPDATE]: firebaseUpdate
});
