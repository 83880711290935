import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {connect} from 'react-redux';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/characters.jpg`;

function ModelItem(props) {
	const handleDelete = () => {
		if (props.delete) {
			props.delete(props.modelKey);
		}
	};

	return (
		<ImageLeftItem
			image={props.model.image || DefaultImage} target={props.modelKey} onClick={props.onClick}
			title={props.model.name} description={props.model.description}
			favourite={{key: props.modelKey, data: {model: props.modelKey}, view: 'model'}}
			delete={props.delete && handleDelete}/>
	);
}

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {
})(ModelItem);
