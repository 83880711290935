import React, {} from 'react';
import {Avatar as MuiAvatar} from '@material-ui/core';
import {selectUserImages} from '../../selectors/state/firebase';
import {connect} from 'react-redux';

const defaultTranslation = { x: 0, y: 0};
const defaultScale = 1;
function Avatar(props) {
	const image = {
		scale: {
			avatar: defaultScale,
			banner: defaultScale,
			full: defaultScale,
			tile: defaultScale
		},
		translate: {
			avatar: defaultTranslation,
			banner: defaultTranslation,
			full: defaultTranslation,
			tile: defaultTranslation
		},
		url: props.image,
		...props.images[props.image]
	};
	const translate = props.translate || image.translate?.avatar || defaultTranslation;
	const scale = props.scale || image.scale?.avatar || defaultScale;
	const transformScale = `scale(${scale})`;
	const transformTranslate = `translate(${translate.x || 0}%, ${translate.y || 0}%)`;

	return <MuiAvatar alt={props.alt} src={image.url} imgProps={{
		style: {transform: `${transformScale} ${transformTranslate}`},
		width: 'auto'
	}}/>;
}

const mapStateToProps = store => ({
	images: selectUserImages(store)
});

export default connect(mapStateToProps, {})(Avatar);
