import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/characters.jpg`;

export default function ShopItem(props) {
	return (
		<ImageLeftItem
			image={props.item.image || DefaultImage} target={props.itemKey} onClick={props.onClick}
			title={props.item.name} description={`Price: ${props.item.price}sp`}
			favourite={{key: props.itemKey, data: props.itemKey, view: 'shopItem'}}/>
	);
}
