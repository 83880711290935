import { createSelector } from 'reselect';

export const selectFirebase = createSelector(store => store, store => store.firebase);
export const selectFirebaseAssignments = createSelector(selectFirebase, firebase => firebase.assignments);
export const selectFirebaseCharacters = createSelector(selectFirebase, firebase => firebase.characters);
export const selectFirebaseCharacter = createSelector(selectFirebase, scene => scene.character);
export const selectFirebaseCampaigns = createSelector(selectFirebase, firebase => firebase.campaigns);
export const selectFirebaseChats = createSelector(selectFirebase, firebase => firebase.chats);
export const selectFirebaseFeatures = createSelector(selectFirebase, firebase => firebase.features);
export const selectFirebaseFriends = createSelector(selectFirebase, firebase => firebase.friends);
export const selectFirebaseFriendCharacters = createSelector(selectFirebase, firebase => firebase.friendCharacters);
export const selectFirebaseGroups = createSelector(selectFirebase, firebase => firebase.groups);
export const selectFirebaseInvites = createSelector(selectFirebase, firebase => firebase.invites);
export const selectFirebaseMessages = createSelector(selectFirebase, firebase => firebase.messages);
export const selectFirebaseModels = createSelector(selectFirebase, firebase => firebase.models);
export const selectFirebaseMonsters = createSelector(selectFirebase, firebase => firebase.monsters);
export const selectFirebaseScenarios = createSelector(selectFirebase, firebase => firebase.scenarios);
export const selectFirebaseRules = createSelector(selectFirebase, firebase => firebase.rules);
export const selectFirebaseScenario = createSelector(selectFirebase, firebase => firebase.scenario);
export const selectFirebaseScenes = createSelector(selectFirebase, firebase => firebase.scenes);
export const selectFirebaseShopItems = createSelector(selectFirebase, firebase => firebase.shopItems);
export const selectFirebasePlayerModels = createSelector(selectFirebase, firebase => firebase.playerModels);
export const selectFirebaseThemes = createSelector(selectFirebase, firebase => firebase.themes);
export const selectFirebaseUsers = createSelector(selectFirebase, firebase => firebase.users);
export const selectFirebaseUserFavourites = createSelector(selectFirebase, firebase => firebase.userFavourites);
export const selectFirebaseUserFiles = createSelector(selectFirebase, firebase => firebase.userFiles);
export const selectFirebaseUserItems = createSelector(selectFirebase, firebase => firebase.userItems);
export const selectFirebaseUserModels = createSelector(selectFirebase, firebase => firebase.userModels);
export const selectFirebaseUserPublic = createSelector(selectFirebase, firebase => firebase.userPublic);
export const selectFirebaseUserRules = createSelector(selectFirebase, firebase => firebase.userRules);

export const selectCharacterModels = createSelector(selectFirebase, firebase => {
	const models = {};
	
	Object.keys(firebase.userModels || {}).forEach(userModelKey => {
		const model = firebase.userModels[userModelKey];
		
		if (model.type === 'character') {
			models[userModelKey] = model;
		}
	});
	return models;
});

export const selectAvailableShopItems = createSelector(store => store, store => {
	const items = {};

	Object.keys(store.firebase.shopItems || {}).forEach(itemKey => {
		const item = store.firebase.shopItems[itemKey];

		if (store.app.admin || (! store.firebase.userItems ||
			store.firebase.userItems[itemKey] === undefined && item.available < Date.now())) {
			items[itemKey] = item;
		}
	});
	return items;
});

export const selectUserImages = createSelector(store => store, store => {
	const images = {};

	Object.keys(store.firebase.userFiles || {}).forEach(imageKey => {
		const image = store.firebase.userFiles[imageKey];

		if (image.type === 'image') {
			images[imageKey] = image;
		}
	});
	return images;
});
