import React, {Suspense, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import Lantern from './Lantern';
import Model from '../models/Model';
import {TransformControls} from '@react-three/drei';
import {Euler, Quaternion, Vector3} from 'three';
import {setDecorationPosition, setDecorationRotation, setDecorationScale} from '../../actions/firebaseActions';

const featurePosition = new Vector3();
const decorationPosition = new Vector3();
const decorationScale = new Vector3();
const decorationQuaternion = new Quaternion();
const decorationRotation = new Euler();

function Decoration(props) {
	const transformControls = useRef();
	const decoration = useRef();
	const controlModes = ['translate', 'rotate', 'scale'];
	const [controlMode, setControlMode] = useState('translate');
	const onDecorationSelected = event => {
		if (! props.readonly && event.button === 0) {
			event.stopPropagation();
			if (props.selected) {
				const index = controlModes.indexOf(controlMode);

				if (index === controlModes.length - 1) {
					props.onDecorationSelected(event, undefined);
				} else {
					setControlMode(controlModes[index + 1]);
				}
			} else {
				setControlMode(controlModes[0]);
				props.onDecorationSelected(event, props.decorationKey);
			}
		}
	};
	const position = featurePosition.fromArray(props.featurePosition)
		.add(decorationPosition.fromArray(props.decoration.position)).toArray();
	const defaultDistance = 4;
	const defaultIntensity = 6;
	const defaultColor = '#f3bd80';

	useEffect(() => {
		if (! transformControls.current) return;
		const onDraggingChanged = event => {
			props.onDraggingChanged(event.value);
			if (! event.value) {
				if (controlMode === 'translate') {
					decoration.current.getWorldPosition(decorationPosition);
					featurePosition.fromArray(props.featurePosition);
					decorationPosition.sub(featurePosition);
					props.setDecorationPosition(props.featureKey, props.decorationKey, decorationPosition.toArray());
				} else if (controlMode === 'rotate') {
					decoration.current.getWorldQuaternion(decorationQuaternion);
					decorationRotation.setFromQuaternion(decorationQuaternion);
					props.setDecorationRotation(props.featureKey, props.decorationKey,
						decorationRotation.toArray().slice(0,3));
				} else if (controlMode === 'scale') {
					decoration.current.getWorldScale(decorationScale);
					props.setDecorationScale(props.featureKey, props.decorationKey, decorationScale.toArray());
				}
			}
		};

		transformControls.current.addEventListener('dragging-changed', onDraggingChanged);
	});
	if (props.decoration.type === 'wisp' || props.decoration.type === 'lantern') {
		return <Lantern
			position={position}
			color={props.decoration.color || defaultColor}
			distance={props.decoration.distance || defaultDistance}
			intensity={props.decoration.intensity || defaultIntensity}/>;
	} else if (props.decoration.type === 'model') {
		if (props.selected) {
			return <Suspense fallback={null}>
				<TransformControls
					size={0.5} mode={controlMode} ref={transformControls} onPointerDown={event => {
						event.stopPropagation();
					}}
					position={position} rotation={props.decoration.rotation} scale={props.decoration.scale}>
					<group ref={decoration}>
						<Model
							selected={true}
							onPointerDown={onDecorationSelected}
							{...props.decoration.model}/>
					</group>
				</TransformControls>
			</Suspense>;
		} else {
			return <Suspense fallback={null}>
				<group position={position} rotation={props.decoration.rotation} scale={props.decoration.scale}>
					<Model
						selected={false}
						onPointerDown={onDecorationSelected}
						{...props.decoration.model}/>
				</group>
			</Suspense>;
		}
	} else {
		return null;
	}
}
const mapStateToProps = store => ({});

export default connect(mapStateToProps, {
	setDecorationPosition,
	setDecorationRotation,
	setDecorationScale
})(Decoration);
