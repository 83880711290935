import {FIREBASE_PUSH_CHILD, FIREBASE_REMOVE, FIREBASE_SET, FIREBASE_TRACK_USER_SCENARIOS} from './firebaseActions';

export function createScenario(campaign, name, user) {
	const created = Date.now();

	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'scenarios',
		child: {
			created,
			name,
			campaign,
			user
		}
	};
}

export function deleteScenario(scenarioKey) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenarios/${scenarioKey}`
	};
}

export function trackUserScenarios(user) {
	return {
		type: FIREBASE_TRACK_USER_SCENARIOS,
		user
	};
}

export function updateScenario(scenarioKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `scenarios/${scenarioKey}/${member}`,
		value
	};
}
