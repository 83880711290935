import {Box, createStyles, Divider, FormControl, IconButton, InputLabel, makeStyles, MenuItem, Select, Typography}
	from '@material-ui/core';
import React, {useState} from 'react';
import {selectSelectedBoxes, selectSelectedFeature} from '../../selectors/state/edit';
import {connect} from 'react-redux';
import {updateFeature, updateFeatureBoxes} from '../../actions/featureActions';
import {selectSelectedSceneKey} from '../../selectors/state/app';
import {selectFirebaseFeatures, selectFirebaseThemes} from '../../selectors/state/firebase';
import {BorderClear, BorderLeft, Undo} from '@material-ui/icons';

const useStyles = makeStyles(theme => createStyles({
	box: {
		position: 'absolute',
		top: 80,
		right: '5ch',
		width: '30ch',
		height: '60vh',
		zIndex: 1,
		color: 'white',
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
		borderColor: 'black'
	},
	form: {
		marginTop: 8
	},
	select: {
		color: 'white',
		'&.MuiInput-underline:after': { borderBottomColor: 'white' },
		'&.Mui-focused:after': { borderBottomColor: 'white' }
	},
	label: {
		color: 'white',
		'&.Mui-focused': { color: 'white' }
	}
}));

function SelectTheme(props) {
	const classes = useStyles();
	const unique = (value, index, self) => self.indexOf(value) === index;
	const values = props.selectedBoxes?.map(box => 
		props.feature.boxes && props.feature.boxes[box.join('_')]?.theme || props.feature.theme
	).filter(unique) || [props.feature.theme];
	const value = values.length === 1 ? values[0] : 'Various';

	return (
		<FormControl className={classes.form} fullWidth>
			<InputLabel className={classes.label} id='themeLabel'>Theme</InputLabel>
			<Select
				className={classes.select} labelId='themeLabel' autoWidth
				onChange={props.onChange} value={value}>
				<MenuItem value='Various' disabled>Various</MenuItem>
				{Object.keys(props.themes).map(themeKey => (
					<MenuItem key={themeKey} value={themeKey}>{props.themes[themeKey].name}</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
function CutOutIntersections(props) {
	const [cutOut, setCutOut] = useState('void');
	const cutouts = { void: 'Empty space', doorSingle: 'Single door', doorDouble: 'Double door'};
	const classes = useStyles();
	const handleSelectChange = event => {
		setCutOut(event.target.value);
	};
	const handleClickCut = event => {
		props.onCutIntersections(cutOut);
	};

	return (
		<Box display='flex' alignItems='center'>
			<Box flexGrow={1}>
				<FormControl className={classes.form} fullWidth>
					<InputLabel className={classes.label} id='themeLabel'>Intersection type</InputLabel>
					<Select
						className={classes.select} labelId='themeLabel' autoWidth
						onChange={handleSelectChange} value={cutOut}>
						{Object.keys(cutouts).map(cutoutKey => (
							<MenuItem key={cutoutKey} value={cutoutKey}>{cutouts[cutoutKey]}</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<Box>
				<IconButton
					title='Cut wall intersections with other features' color='inherit'
					onClick={handleClickCut}><BorderLeft/></IconButton>
			</Box>
			<Box>
				<IconButton
					title='Reset intersections to return to closed room' color='inherit'
					onClick={props.onCutReset}><BorderClear/></IconButton>
			</Box>
		</Box>
	);
}
function FeatureDetails(props) {
	const feature = props.features[props.selectedFeature];

	if (! feature) return null;

	const classes = useStyles();
	const handleOrientationChange = event => {
		props.updateFeature(props.selectedFeature, 'orientation', event.target.value);
	};
	const handleFeatureThemeChange = event => {
		props.updateFeature(props.selectedFeature, 'theme', event.target.value);
	};
	const handleBoxThemeChange = event => {
		props.updateFeatureBoxes(props.selectedFeature, props.selectedBoxes, 'theme', event.target.value);
	};
	const handleBoxResetClick = () => {
		props.updateFeature(props.selectedFeature, 'boxes', []);
	};
	const orientation = feature?.type === 'staircase' && (
		<FormControl className={classes.form}>
			<InputLabel className={classes.label} id='orientationLabel'>Orientation</InputLabel>
			<Select
				className={classes.select} labelId='orientationLabel' autoWidth label={props.label}
				onChange={handleOrientationChange} value={feature.orientation || ''}>
				<MenuItem key='x+' value='x+'><em>Up with X</em></MenuItem>
				<MenuItem key='x-' value='x-'><em>Down with X</em></MenuItem>
				<MenuItem key='y+' value='y+'><em>Up with Y</em></MenuItem>
				<MenuItem key='y-' value='y-'><em>Down with Y</em></MenuItem>
			</Select>
		</FormControl>
	);

	return (
		<Box className={classes.box} m={1} p={1} border={1} borderRadius={16}>
			<Box p={1}>
				<Typography variant='h6'>Selected Feature</Typography>
				<Typography variant='body1'>Type: {feature.type}</Typography>
				<Typography variant='body1'>Position: {feature.position.join(', ')}</Typography>
				<Typography variant='body1'>Size: {feature.size.join(', ')}</Typography>
				<SelectTheme themes={props.themes} feature={feature} onChange={handleFeatureThemeChange}/>
				<CutOutIntersections
					theme={props.themes[feature.theme]}
					onCutIntersections={props.onCutIntersections} onCutReset={props.onCutReset}/>
				{feature.type === 'staircase' && orientation}
			</Box>
			<Divider/>
			<Box p={1}>
				<Box display='flex' alignItems='center'>
					<Box flexGrow={1}><Typography variant='h6'>Selected Boxes</Typography></Box>
					<IconButton color='inherit' title='Reset boxes' onClick={handleBoxResetClick}>
						<Undo/>
					</IconButton>
				</Box>
				<SelectTheme
					themes={props.themes} selectedBoxes={props.selectedBoxes} feature={feature}
					onChange={handleBoxThemeChange}/>
				<Typography variant='body1'>Boxes: {props.selectedBoxes.join(', ')}</Typography>
			</Box>
		</Box>
	);
}

const mapStateToProps = store => ({
	features: selectFirebaseFeatures(store),
	selectedBoxes: selectSelectedBoxes(store),
	selectedFeature: selectSelectedFeature(store),
	selectedSceneKey: selectSelectedSceneKey(store),
	themes: selectFirebaseThemes(store)
});

export default connect(mapStateToProps, {
	updateFeature,
	updateFeatureBoxes
})(FeatureDetails);
