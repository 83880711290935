import React from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {Box, Divider, TextField, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {generateRandom} from '../../../actions/firebaseActions';
import {addChat} from '../../../actions/chatActions';
import {setMonsterStat} from '../../../actions/monsterActions';
import {selectAppAuthentication, selectViewMode} from '../../../selectors/state/app';

function MonsterActions(props) {
	const useStyles = makeStyles(theme => ({
		bold: {fontWeight: 600},
		divider: {marginTop: theme.spacing(1), marginBottom: theme.spacing(1)},
		property: {
			'& label': {color: theme.palette.common.white},
			'& label.Mui-focused': {color: theme.palette.common.white},
			'& .MuiInputBase-root': {color: theme.palette.common.white},
			'& .MuiOutlinedInput-root fieldset': {borderColor: theme.palette.common.white},
			'& .MuiOutlinedInput-root.Mui-focused fieldset': {borderColor: theme.palette.common.white},
			'&:hover fieldset': {borderColor: theme.palette.common.white},
			borderColor: theme.palette.common.white,
			color: theme.palette.common.white,
			padding: 2
		},
		statRow: {textTransform: 'uppercase', width: '100%'},
		clickable: {
			cursor: 'pointer',
			border: '1px solid',
			borderRadius: theme.shape.borderRadius,
			borderColor: theme.palette.common.white,
			flexGrow: 1,
			margin: 2,
			padding: theme.spacing(1)
		}
	}));
	const classes = useStyles();
	const dynamicProperty = (label, defaultValue) => {
		const value = props.details.stats && props.details.stats[label] !== undefined ?
			props.details.stats[label] : defaultValue;
		const onFocus = event => {
			event.target.select();
		};
		const onBlur = event => {
			if (event.target.value[0] === '-' || event.target.value[0] === '+') {
				const newValue = value + Number.parseInt(event.target.value);

				props.setMonsterStat(props.viewMode.data.sceneKey, props.monsterKey, label, newValue);
				event.target.value = newValue;
			} else if (event.target.value === '') {
				props.setMonsterStat(props.viewMode.data.sceneKey, props.monsterKey, label, defaultValue);
				event.target.value = defaultValue;
			} else {
				const newValue = Number.parseInt(event.target.value);

				if (!Number.isNaN(newValue)) {
					props.setMonsterStat(props.viewMode.data.sceneKey, props.monsterKey, label, newValue);
				}
			}
		};
		const onKeyDown = event => {
			if (event.keyCode === 13) {
				event.target.blur();
			}
			event.stopPropagation();
		};

		return (
			<TextField
				classes={{ root: classes.property}}
				InputLabelProps={{shrink: true}} label={label.toUpperCase()} variant='outlined' margin='dense'
				defaultValue={value} onFocus={onFocus} onBlur={onBlur} onKeyDown={onKeyDown}/>
		);
	};
	const statistic = (stat) => {
		const onClick = () => {
			const label = `${props.monster.name} ${stat.toUpperCase()} roll`;
			let expression = '1d20';

			if (props.monster[stat].modifier < 0) {
				expression += props.monster[stat].modifier;
			} else if (props.monster[stat].modifier > 0) {
				expression += '+' + props.monster[stat].modifier;
			}
			props.generateRandom(props.viewMode.data.campaignKey, label, props.authentication.key, expression);
		};

		return (
			<Box key={stat} className={classes.clickable} onClick={onClick}>
				<Typography className={`${classes.bold} ${classes.statRow}`} variant='body2' align='center'>
					{stat}
				</Typography>
				<Typography className={classes.statRow} variant='body2' align='center'>
					{props.monster[stat].value}
				</Typography>
				<Typography className={classes.statRow} variant='body2' align='center'>
					{`(${props.monster[stat].modifier >= 0 ? '+' : ''}${props.monster[stat].modifier})`}
				</Typography>
			</Box>
		);
	};
	const simpleStatistic = (stat, value) => {
		const onClick = () => {
			const label = `${props.monster.name} ${stat.toUpperCase()} roll`;
			let expression = '1d20';

			if (value < 0) {
				expression += value;
			} else if (value > 0) {
				expression += '+' + value;
			}
			props.generateRandom(props.viewMode.data.campaignKey, label, props.authentication.key, expression);
		};

		return (
			<Box key={stat} className={classes.clickable} onClick={onClick}>
				<Typography className={classes.bold} variant='body2' align='center'>
					{`${stat.toUpperCase()} `}
				</Typography>
				<Typography variant='body2' align='center'>
					{value >= 0 ? `+${value}` : value}
				</Typography>
			</Box>
		);
	};
	const action = action => {
		const onClick = () => {
			if (action.attack) {
				const label = `${props.monster.name} ${action.name} 1d20${action.attack.toHit}`;
				const expression = `1d20${action.attack.toHit}`;

				props.generateRandom(props.viewMode.data.campaignKey, label, props.authentication.key, expression);
			} else if (action.details) {
				props.addChat(props.viewMode.data.campaignKey, action.details, props.authentication.key);
			}
		};
		const name = action.name.replace(/[. ]*$/, '');

		return (
			<Box key={action.name} className={classes.clickable} onClick={onClick}>
				<Typography className={classes.bold} variant='body2'>{name}</Typography>
			</Box>
		);
	};
	const exludedActions = [undefined, 'Multiattack.'];

	return (
		<Scrollbars style={{height: '100%'}}>
			<Box display='flex'>
				{dynamicProperty('hp', props.monster.hp.value)}
				{dynamicProperty('ac', props.monster.ac.value)}
			</Box>
			<Box display='flex'>
				{['str', 'dex', 'con', 'int', 'wis', 'cha'].map(stat => statistic(stat))}
			</Box>
			{props.monster.saves &&
				<Box>
					<Divider className={classes.divider}/>
					<Typography className={classes.bold} variant='body2'>Saves</Typography>
					<Box display='flex'>
						{Object.keys(props.monster.saves).map(stat => simpleStatistic(stat, props.monster.saves[stat], 3))}
					</Box>
				</Box>
			}
			{props.monster.skills &&
				<Box>
					<Divider className={classes.divider}/>
					<Typography className={classes.bold} variant='body2'>Skills</Typography>
					<Box display='flex' flexWrap='wrap'>
						{Object.keys(props.monster.skills).map(stat => simpleStatistic(stat, props.monster.skills[stat], 6))}
					</Box>
				</Box>
			}
			<Box>
				<Divider className={classes.divider}/>
				<Typography className={classes.bold} variant='body2'>Actions</Typography>
				<Box>
					{props.monster.actions.filter(details => ! exludedActions.includes(details.name))
						.sort((lhs, rhs) => !lhs.attack - !rhs.attack)
						.map(details => action(details))}
				</Box>
			</Box>
			{props.monster.legendaryActions &&
				<>
					<Divider className={classes.divider}/>
					<Typography className={classes.bold} variant='body2'>Legendary Actions</Typography>
					<Box>
						{props.monster.legendaryActions
							.filter(details => details.name)
							.map(details => action(details))}
					</Box>
				</>
			}
		</Scrollbars>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	viewMode: selectViewMode(store)
});
export default connect(mapStateToProps, {
	addChat,
	generateRandom,
	setMonsterStat
})(MonsterActions);
