import {useThree} from 'react-three-fiber';
import * as THREE from 'three';

function Weather(props) {
	const { scene } = useThree();

	if (props.fog > 0)
		scene.fog = new THREE.FogExp2('#97abbd', props.fog);
	return null;
}
export default Weather;
