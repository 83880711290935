import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectAvailableShopItems} from '../../../selectors/state/firebase';
import Panel from './Panel';
import Cover from '../items/Cover';
import SearchTool from '../items/SearchTool';
import ShopItem from '../items/ShopItem';
import {adminAddShopItem} from '../../../actions/firebaseActions';
import ShopAdmin from '../admin/ShopAdmin';
import {Box, Typography} from '@material-ui/core';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/character.jpg`;

function ShopPanel(props) {
	if (props.view !== 'shop') return null;

	const [filter, setFilter] = useState('');
	const handleSearchChange = event => {
		setFilter(event.target.value.toLowerCase());
	};
	const handleItemClick = event => {
		props.setView('shopItem', event.currentTarget.dataset.target);
	};
	const itemSort = (lhs, rhs) => props.shopItems[lhs].name.localeCompare(props.shopItems[rhs].name);
	const modelKeys = Object.keys(props.shopItems).filter(itemKey => props.shopItems[itemKey].type === 'model')
		.sort(itemSort);
	const rulesKeys = Object.keys(props.shopItems).filter(itemKey => props.shopItems[itemKey].type === 'rules')
		.sort(itemSort);
	const otherKeys = Object.keys(props.shopItems).filter(itemKey => props.shopItems[itemKey].type === undefined);

	useEffect(() => {
		props.setTitle('Shop', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover
				image={defaultImage}
				toolbar={[
					<SearchTool key={'search'} placeholder='Filter shop' onChange={handleSearchChange}/>
				]}/>
			{modelKeys.length > 0 && <Box p={2}><Typography variant='h5' align='center'>Models</Typography></Box>}
			{modelKeys.filter(itemKey => filter === '' || props.shopItems[itemKey].name.toLowerCase().includes(filter))
				.map(itemKey => {
					return (
						<ShopItem
							key={itemKey} itemKey={itemKey} item={props.shopItems[itemKey]}
							onClick={handleItemClick}
						/>
					);
				})}
			{rulesKeys.length > 0 && <Box p={2}><Typography variant='h5' align='center'>Rules</Typography></Box>}
			{rulesKeys.filter(itemKey => filter === '' || props.shopItems[itemKey].name.toLowerCase().includes(filter))
				.map(itemKey => {
					return (
						<ShopItem
							key={itemKey} itemKey={itemKey} item={props.shopItems[itemKey]}
							onClick={handleItemClick}
						/>
					);
				})}
			{otherKeys.length > 0 && <Box p={2}><Typography variant='h5' align='center'>New Items</Typography></Box>}
			{otherKeys.map(itemKey => {
				return (
					<ShopItem
						key={itemKey} itemKey={itemKey} item={props.shopItems[itemKey]}
						onClick={handleItemClick}
					/>
				);
			})}
			<ShopAdmin/>
		</Panel>
	);
}

const mapStateToProps = store => ({shopItems: selectAvailableShopItems(store)});

export default connect(mapStateToProps, {adminAddShopItem})(ShopPanel);
