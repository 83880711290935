import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseRules, selectFirebaseUserRules} from '../../../selectors/state/firebase';
import Panel from './Panel';
import Cover from '../items/Cover';
import RulesItem from '../items/RulesItem';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/rules.jpg`;

function RulesPanel(props) {
	if (props.view !== 'rules') return null;
	
	const handleRulesClick = event => {
		const rules = props.rules[event.currentTarget.dataset.target];
		
		if (rules?.type === 'monsters') {
			props.setView('monsters', event.currentTarget.dataset.target);
		}
	};

	useEffect(() => {
		props.setTitle('Rules', ['referee']);
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={[]}/>
			{Object.keys(props.userRules || {}).map(rulesKey =>
				<RulesItem
					key={rulesKey} rulesKey={rulesKey} rules={props.rules[rulesKey]} data-key={rulesKey}
					onClick={handleRulesClick}/>
			)}
		</Panel>
	);
}

const mapStateToProps = store => ({
	rules: selectFirebaseRules(store),
	userRules: selectFirebaseUserRules(store)
});

export default connect(mapStateToProps, { })(RulesPanel);
