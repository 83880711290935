import React, {useEffect, useRef, useState} from 'react';
import Box from './Box';
import {connect} from 'react-redux';
import {selectEditState, selectSelectedFeature, selectSelectedBoxes} from '../../selectors/state/edit';
import {TransformControls} from '@react-three/drei';
import {BoxGeometry, Vector3} from 'three';
import {selectSelectedSceneKey} from '../../selectors/state/app';
import {setSelected} from '../../actions/editActions';
import {ExcludesBox} from './Staircase';

const oneVector3 = new Vector3(1, 1, 1);
const outlineOffset = new Vector3(-0.5, -0.5, -0.5);
const outlinePosition = new Vector3();
const colors = {
	corridor: 'blue',
	room: 'red',
	staircase: 'green'
};

function Outline(props) {
	const geometry = new BoxGeometry(...props.size);

	outlinePosition.fromArray(props.size).multiplyScalar(0.5);
	return (
		<group position={outlineOffset}>
			<mesh position={outlinePosition}>
				<lineSegments>
					<edgesGeometry attach='geometry' args={[geometry]}/>
					<lineBasicMaterial attach='material' color='black'/>
				</lineSegments>
			</mesh>
		</group>
	);
}

function BluePrint(props) {
	const [size, setSize] = useState(props.size);
	const blueprint = useRef();
	const transformControls = useRef();
	const boxes = [];
	const selectedFeatureColor = 'black';
	const selectedBoxColor='white';
	const doorwayModels = {
		north: { geometry: [0.8, 0.1, 0.8], offset: [0.05, 0.5, 0.05] },
		east: { geometry: [0.1, 0.8, 0.8], offset: [0.5, 0.05, 0.05] },
		south: { geometry: [0.8, 0.1, 0.8], offset: [0.05, -0.5, 0.05] },
		west: { geometry: [0.1, 0.8, 0.8], offset: [-0.5, 0.05, 0.05] },
		ceiling: { geometry: [0.8, 0.8, 0.1], offset: [0.05, 0.05, 0.5] },
		floor: { geometry: [0.8, 0.8, 0.1], offset: [0.05, 0.05, -0.5] }
	};
	const doorwayTiles = {void: {transparent:false, opacity:0.1, color:'white'}};
	const buildDoorways = boxes => {
		return Object.keys(boxes).reduce((doorways, boxKey) => {
			Object.keys(boxes[boxKey].sides || {}).forEach(side => {
				const tile = boxes[boxKey].sides[side];

				if (doorwayTiles[tile]) {
					doorways.push({box: boxKey.split('_').map(value => Number.parseInt(value)), side, tile});
				}
			});
			return doorways;
		}, []).map((doorway, index) => {
			const offset = doorwayModels[doorway.side].offset;
			const doorwayPosition = doorway.box.map((coordinate, index) => coordinate + offset[index]);

			return <mesh key={`doorway${index}`} position={doorwayPosition}>
				<boxBufferGeometry args={doorwayModels[doorway.side].geometry}/>;
				<meshBasicMaterial attach="material" {...doorwayTiles[doorway.type]}/>
			</mesh>;
		});
	};
	const doorways = buildDoorways(props.boxes || {});
	const selected = props.selectedFeature === props.label;
	const featureColor = selected && ! props.prefab ? selectedFeatureColor : colors[props.type];
	const onPointerDown = (event, selectedBox) => {
		if (transformEvent) return;
		if (props.prefab) {
			props.setSelected(null, []);
			return props.onPointerDown(event, selectedBox);
		} else if (! event.shiftKey) {
			const deselect = props.selectedBoxes.findIndex(box =>
				box.every((coordinate, index) => selectedBox[index] === coordinate));

			if (deselect !== -1) {
				const selectedBoxes = [...props.selectedBoxes];

				selectedBoxes.splice(deselect, 1);
				if (! event.ctrlKey) {
					if (selectedBoxes.length) {
						props.setSelected(props.label, [selectedBox]);
					} else {
						props.setSelected(null, []);
					}
				} else {
					props.setSelected(selectedBoxes.length ? props.label : null, selectedBoxes);
				}
			} else if (event.ctrlKey) {
				props.setSelected(props.label, [...props.selectedBoxes, selectedBox]);
			} else {
				props.setSelected(props.label, [selectedBox]);
			}
		} else {
			if (props.selectedBoxes.length) {
				const anchor = props.selectedBoxes[0];
				const selectedBoxes = [];
				
				for (let x = Math.min(anchor[0], selectedBox[0]); x <= Math.max(anchor[0], selectedBox[0]); ++x)
					for (let y = Math.min(anchor[1], selectedBox[1]); y <= Math.max(anchor[1], selectedBox[1]); ++y)
						for (let z = Math.min(anchor[2], selectedBox[2]); z <= Math.max(anchor[2], selectedBox[2]); ++z)
							if (anchor[0] === x && anchor[1] === y && anchor[2] === z) {
								selectedBoxes.unshift([x, y, z]);
							} else {
								selectedBoxes.push([x, y, z]);
							}
				props.setSelected(props.label, selectedBoxes);
			} else {
				props.setSelected(props.label, [selectedBox]);
			}
		}
	};
	const [transformEvent, setTransformEvent] = useState(false);

	useEffect(() => {
		const worldPosition = new Vector3();
		const startingPosition = new Vector3();
		const startingSize = new Vector3();
		const scaledSize = new Vector3();

		const onDraggingChanged = event => {
			if (! event.value) {
				blueprint.current.getWorldPosition(worldPosition);

				const newPosition = worldPosition.toArray();
				if (props.position.some((coordinate, index) => coordinate !== newPosition[index])) {
					props.onUpdateFeature(props.selectedFeature, 'position', newPosition);
				}
				if (size.some((coordinate, index) => coordinate !== props.size[index])) {
					props.onUpdateFeature(props.selectedFeature, 'size', size);
				}
			}
		};
		const onDrag = () => {
			if (! transformEvent) return;
			if (transformControls.current?.mode === 'scale' && transformControls.current.object) {
				const scale = transformControls.current.object.scale;

				scaledSize.copy(startingSize).multiply(scale);
				scaledSize.round();
				scaledSize.max(oneVector3);
				if (! scaledSize.equals(startingSize)) {
					setSize(scaledSize.toArray());
					scale.copy(oneVector3);
				}
			}
		};
		const handleMouseDown = () => {
			setTransformEvent(true);
		};
		const handleMouseUp = () => {
			setTransformEvent(false);
		};

		if (['scale', 'translate', 'orientation'].indexOf(props.showTransform) >= 0) {
			transformControls.current?.setMode(props.showTransform);
		}
		startingPosition.fromArray(props.position);
		startingSize.fromArray(props.size);
		transformControls.current?.addEventListener('dragging-changed', onDraggingChanged);
		transformControls.current?.addEventListener('change', onDrag);
		transformControls.current?.addEventListener('mouseDown', handleMouseDown);
		transformControls.current?.addEventListener('mouseUp', handleMouseUp);
		return () => {
			transformControls.current?.removeEventListener('dragging-changed', onDraggingChanged);
			transformControls.current?.removeEventListener('change', onDrag);
			transformControls.current?.removeEventListener('mouseDown', handleMouseDown);
			transformControls.current?.removeEventListener('mouseUp', handleMouseUp);
		};
	}, [transformControls, transformEvent, blueprint, props.showTransform, props.size, size]);
	useEffect(() => {
		setSize(props.size);
	}, [props.size]);
	for (let x = 0; x < size[0]; ++x) {
		for (let y = 0; y < size[1]; ++y) {
			for (let z = 0; z < size[2]; ++z) {
				if (props.type === 'staircase' && ExcludesBox(props, [x, y, z]))
					continue;

				const boxPosition = [x, y, z];
				const key = [x, y, z];
				const boxColor = (selected && props.selectedBoxes.some(box =>
					box[0] === x && box[1] === y && box[2] === z)) ? selectedBoxColor : featureColor;

				boxes.push(<Box
					label={key} key={key} position={boxPosition} color={boxColor}
					onPointerDown={onPointerDown}/>);
			}
		}
	}
	if (props.showTransform !== 'none' && selected) {
		return (
			<TransformControls size={0.5} position={props.position} translationSnap={1} ref={transformControls}>
				<group ref={blueprint} key={props.label}>
					{! transformEvent && <Outline size={size}/>}
					{boxes}
					{doorways}
				</group>
			</TransformControls>
		);
	} else {
		return (
			<group position={props.position}>
				<group ref={blueprint} key={props.label}>
					{selected && <Outline size={size}/>}
					{boxes}
					{doorways}
				</group>
			</group>
		);
	}
}

const mapStateToProps = store => ({
	state: selectEditState(store),
	selectedBoxes: selectSelectedBoxes(store),
	selectedFeature: selectSelectedFeature(store),
	selectedSceneKey: selectSelectedSceneKey(store)
});

export default connect(mapStateToProps, {
	setSelected
})(BluePrint);
