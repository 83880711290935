import {connect} from 'react-redux';
import {selectEditAdding, selectSelectedFeature} from '../../selectors/state/edit';
import React, {useEffect} from 'react';
import { setEditing, setSelected } from '../../actions/editActions';
import Prefab from './Prefab';
import {selectSelectedSceneKey} from '../../selectors/state/app';

function EditControls(props) {
	const colors = {
		'corridor': 'blue',
		'room': 'red',
		'staircase': 'green'
	};
	const keydown = event => {
		if (event.keyCode === 16) { // Shift
			if (event.altKey) {
				props.onShowTransform(event, 'scale');
			}
		}
		if (event.keyCode === 18) { // Alt
			if (event.shiftKey) {
				props.onShowTransform(event, 'scale');
			} else {
				props.onShowTransform(event, 'transform');
			}
		}
		if ((event.keyCode === 46 || event.keyCode === 8) && props.selectedFeature !== null) { // Delete or Backspace
			props.setSelected(null, []);
			props.onRemoveFeature(props.selectedFeature);
		}
		if (event.keyCode === 27) { // Escape
			props.setSelected(null, []);
		}
		if (event.keyCode === 81) { // Q
			props.changeZPlane(1);
		}
		if (event.keyCode === 69) { // E
			props.changeZPlane(- 1);
		}
	};
	const keyup = event => {
		if (event.keyCode === 16) { // Shift
			if (event.altKey) {
				props.onShowTransform(event, 'translate');
			}
		}
		if (event.keyCode === 18) { // Alt
			props.onShowTransform(event, 'none');
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keydown);
		window.addEventListener('keyup', keyup);
		return () => {
			window.removeEventListener('keydown', keydown);
			window.removeEventListener('keyup', keyup);
		};
	}, [props.selectedFeature]);
	return (
		<>
			{props.adding !== '' &&
				<Prefab
					onSetOrientation={props.onSetOrientation} onAddFeature={props.onAddFeature}
					onUpdateFeature={props.onUpdateFeature} {...props} color={colors[props.adding]}/>}
		</>
	);
}

const mapStateToProps = store => ({
	adding: selectEditAdding(store),
	selectedFeature: selectSelectedFeature(store),
	editScene: selectSelectedSceneKey(store)
});

export default connect(mapStateToProps, {
	setAdding: setEditing,
	setSelected
})(EditControls);
