import {Tab, Tabs} from '@material-ui/core';
import React from 'react';

export default function ToolbarTabs(props) {
	const tabKeys = Object.keys(props.tabs || {});
	const tab = tabKeys.indexOf(props.selected);
	const handleTabChange = (event, newTab) => {
		props.setTab(tabKeys[newTab]);
	};

	return <Tabs key='tabs' value={tab} onChange={handleTabChange} style={{flexGrow: 2}}>
		{tabKeys.map(key => (<Tab key={key} label={key}/>))}
	</Tabs>;
}
