import React from 'react';
import {IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import {selectFirebaseCampaigns, selectFirebaseScenarios, selectFirebaseScenes, selectFirebaseUserFavourites}
	from '../../selectors/state/firebase';
import {connect} from 'react-redux';
import Avatar from './Avatar';
import {selectAppAuthentication} from '../../selectors/state/app';
import {Favorite} from '@material-ui/icons';
import {removeUserFavourite} from '../../actions/firebaseActions';

function Favourites(props) {
	if (! props.userFavourites) return null;

	const handleFavouriteClick = event => {
		const favourite = props.userFavourites[event.currentTarget.dataset.favourite];

		if (favourite) {
			props.setView(event.currentTarget.dataset.view, favourite.data);
		}
	};
	const handleUnfavouriteClick = event => {
		props.removeUserFavourite(props.auth.key, event.currentTarget.dataset.favourite);
	};
	const favouriteDetails = Object.keys(props.userFavourites).map(favouriteKey => {
		const favourite = props.userFavourites[favouriteKey];

		return {
			favourite,
			favouriteKey,
			view: favourite.view,
			data: favourite.data
		};
	});
	const favourites = favouriteDetails.filter(details => 
		props.types.includes(details.view)
	).sort((lhs, rhs) => 
		props.types.indexOf(lhs.view) - props.types.indexOf(rhs.view)
	).map(details => {
		return (
			<ListItem
				button key={details.favouriteKey}
				data-view={details.view} data-favourite={details.favouriteKey}
				onClick={handleFavouriteClick}>
				<ListItemAvatar>
					<Avatar image={details.favourite.image}/>
				</ListItemAvatar>
				<ListItemText primary={details.favourite.name}/>
				<ListItemSecondaryAction>
					<IconButton
						title='Remove favourite' data-favourite={details.favouriteKey}
						onClick={handleUnfavouriteClick}>
						<Favorite/>
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		);
	});

	return (
		<List>{favourites}</List>
	);
}
const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store),
	scenarios: selectFirebaseScenarios(store),
	scenes: selectFirebaseScenes(store),
	userFavourites: selectFirebaseUserFavourites(store)
});

export default connect(mapStateToProps, {
	removeUserFavourite
})(Favourites);
