import { createSelector } from 'reselect';

const selectJson = createSelector(store => store, store => store.json);

export const selectRules = createSelector(selectJson, json => json.rules);

export const selectMonsterRules = createSelector(selectRules, rules => {
	const monsters = {};

	Object.keys(rules || {}).filter(key => rules[key].monsters).forEach(key => {
		const monsterRules =  rules[key].monsters;

		Object.keys(monsterRules).forEach(key => {
			monsters[key] = monsterRules[key];
		});
	});
	return monsters;
});
