import React, {useEffect, useState} from 'react';
import Note from './Note';
import {connect} from 'react-redux';
import {selectAppAuthentication} from '../../../selectors/state/app';

function Notes(props) {
	const [highlight, setHighlight] = useState(-1);
	const [notes, setNotes] = useState([]);
	const handleCardClick = (event, index) => {
		setHighlight(index);
	};
	const handleMoveUp = (event, key) => {
		const index = notes.indexOf(key);
		const replace = notes[index - 1];

		props.updateNote(replace, {...props.notes[replace], order: index});
		props.updateNote(key, {...props.notes[key], order: index - 1});
		event.stopPropagation();
	};
	const handleMoveDown = (event, key) => {
		const index = notes.indexOf(key);
		const replace = notes[index + 1];

		props.updateNote(replace, {...props.notes[replace], order: index});
		props.updateNote(key, {...props.notes[key], order: index + 1});
		event.stopPropagation();
	};

	useEffect(() => {
		setNotes(Object.keys(props.notes || {}).filter(noteKey => {
			const note = props.notes[noteKey];
			
			return note.visibility === 'public' ||
				(note.visibility === 'players' && props.players[props.auth.key]) ||
				props.owner === props.auth.key;
		}).sort((lhs, rhs) => {
			return props.notes[lhs].order - props.notes[rhs].order;
		}));
	}, [props.notes]);
	return notes.map((noteKey, index) => (
		<Note
			key={noteKey} index={index} noteKey={noteKey} notes={props.notes} highlight={index === highlight}
			owner={props.owner} updateNote={props.updateNote} removeNote={props.removeNote}
			onCardClick={handleCardClick} onMoveUp={handleMoveUp} onMoveDown={handleMoveDown}/>
	));
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store)
});

export default connect(mapStateToProps, {})(Notes);
