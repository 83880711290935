export const JSON_FETCH = 'JSON_FETCH';
export function jsonFetch(name, path) {
	return {
		type: JSON_FETCH,
		path,
		name
	};
}
export const JSON_REMOVE = 'JSON_REMOVE';
export function jsonRemove(name) {
	return {
		type: JSON_REMOVE,
		name
	};
}
export const JSON_SET = 'JSON_SET';
export function jsonSet(name, value) {
	return {
		type: JSON_SET,
		value,
		name
	};
}
