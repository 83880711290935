import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import TextItem from '../items/TextItem';
import Cover from '../items/Cover';
import {selectAppAdmin, selectAppUser} from '../../../selectors/state/app';
import {FormControlLabel, Switch} from '@material-ui/core';
import {setAdmin} from '../../../actions/appActions';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/running.jpg`;

function HomePanel(props) {
	if (props.view !== 'home') return null;

	const toolbar = [];
	const handleAdminChanged = event => {
		props.setAdmin(event.target.checked);
	};
	
	if (props.user.roles.admin) {
		toolbar.push(<FormControlLabel key='admin' control={
			<Switch
				checked={props.admin} onChange={handleAdminChanged} name={'admin'}
				inputProps={{ 'aria-label': 'primary checkbox' }}/>} label='Admin'/>);
	}
	useEffect(() => {
		props.setTitle('Home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={toolbar}/>
			<TextItem>
				Your friend Brad just created a new campaign named &quot;Bog Standard&quot;.
			</TextItem>
			<TextItem>
				Your friend Trouble earned the Fumbler achievement in the &quot;Nasty Surprise&quot; Scenario.
			</TextItem>
			<TextItem>
				Thanks for the game last night John, will make sure to keep an eye open for hidden doorways in future! 
			</TextItem>
		</Panel>
	);
}

const mapStateToProps = store => ({
	admin: selectAppAdmin(store),
	user: selectAppUser(store)
});

export default connect(mapStateToProps, {
	setAdmin
})(HomePanel);
