import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/characters.jpg`;

export default function GalleryItem(props) {
	return (
		<ImageLeftItem
			image={props.imageKey} target={props.imageKey} onClick={props.onClick} title={props.name}
			favourite={{key: props.imageKey, data: props.imageKey, view: 'picture'}}/>
	);
}
