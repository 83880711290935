export default function Assignment(assignment) {
	const order = ['default', 'premium'];
	const {image, model, predicate, user} = assignment;
	const sortOrder = order.includes(user) ? order.indexOf(user) : order.length;

	return {
		describeAssignment: (models, userModels) => {
			const description = [];

			if (assignment.model) {
				if (userModels && userModels[assignment.model]) {
					description.push(`Setting user model ${userModels[assignment.model].name}.`);
				} else if (models && models[assignment.model]) {
					description.push(`Setting model ${models[assignment.model].name}.`);
				}
			}
			if (assignment.image) {
				description.push('Setting image.');
			}
			if (assignment.details) {
				description.push('Setting details.');
			}
			return description.join(' ');
		},
		describePredicate: (monsters) => {
			if (predicate === undefined || predicate.value === undefined) return 'Undefined';
			if (predicate.type === 'equals') {
				if (predicate.field === 'key') {
					return `Monster is ${monsters[predicate.value]?.name || 'unknown'}`;
				} else {
					return `Field ${predicate.field} equals ${predicate.value}`;
				}
			}
			if (predicate.type === 'contains') {
				if (predicate.field === 'key') {
					return `Monster is one of ${predicate.value.map(value => monsters[value]?.name).join(', ')}`;
				} else {
					return `Field ${predicate.field} is one of ${predicate.value.join(', ')}`;
				}
			}
			if (predicate.type === 'boolean') {
				if (predicate.value) {
					return 'Always applies';
				} else {
					return 'Never applies';
				}
			}
		},
		resolveUser: (key, test) => user === 'default' || user === test.tier || user === key,
		resolveMonster: (monsterKey, monster) => {
			return monster === undefined || predicate === undefined || predicate.value === undefined ||
				(predicate.type === 'equals' &&
					(predicate.field === 'key' && monsterKey === predicate.value ||
						monster[predicate.field] === predicate.value)) ||
				(predicate.type === 'contains' && (predicate.value.includes === undefined ||
					(predicate.field === 'key' && predicate.value.includes(monsterKey) ||
						predicate.value.includes(monster[predicate.field])))) ||
				(predicate.type === 'boolean' && predicate.value);
		},
		image,
		model,
		sortOrder,
		user
	};
}
