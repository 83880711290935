import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCharacters} from '../../../selectors/state/firebase';
import Panel from './Panel';
import CharacterItem from '../items/CharacterItem';
import Cover from '../items/Cover';
import {IconButton} from '@material-ui/core';
import {PersonAdd} from '@material-ui/icons';
import {createCharacter} from '../../../actions/characterActions';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/characters.jpg`;
const defaultModel = 'archer';

function CharactersPanel(props) {
	if (props.view !== 'characters') return null;

	const handleCharacterClick = event => {
		props.setView('character', {character: event.currentTarget.dataset.target});
	};
	const handleClickAddCharacter = () => {
		props.createCharacter('New Character', defaultModel);
	};

	useEffect(() => {
		props.setTitle('Characters', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={defaultImage} toolbar={[
				<IconButton
					key='addCharacter' aria-label="add character" title="Add Character"
					color='inherit' onClick={handleClickAddCharacter}>
					<PersonAdd/>
				</IconButton>
			]}/>
			{Object.keys(props.characters || {}).map(characterKey => (
				<CharacterItem
					key={characterKey} characterKey={characterKey}
					character={props.characters[characterKey]} onClick={handleCharacterClick}/>
			))}
		</Panel>
	);
}

const mapStateToProps = store => ({
	characters: selectFirebaseCharacters(store)
});

export default connect(mapStateToProps, {
	createCharacter
})(CharactersPanel);
