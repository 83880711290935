import React, { } from 'react';

function Box(props) {
	const onClick = event => {
		if (props.onPointerDown) {
			event.stopPropagation();
			return props.onPointerDown(event, props.label);
		}
	};

	return (
		<group>
			<mesh position={props.position} label={props.label} onClick={onClick}>
				<boxBufferGeometry args={props.size || [1, 1, 1]} />
				<meshBasicMaterial attach="material" transparent={true} opacity={props.opacity || 0.3} color={props.color}/>
			</mesh>
		</group>
	);
}

export default Box;
