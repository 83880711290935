import {FIREBASE_ACCEPT_INVITE} from './firebaseActions';

export function acceptCampaignInvite(user, campaign, referee) {
	return {
		type: FIREBASE_ACCEPT_INVITE,
		campaign,
		user,
		referee
	};
}
