import React, {} from 'react';
import {connect} from 'react-redux';
import {useUpdate} from 'react-three-fiber';
import {noise} from '../../utilities/Perlin';
import {useTexture} from '@react-three/drei';
import * as THREE from 'three';
import {Images} from '../../config';

function Terrain(prop) {
	const plainMesh = useUpdate(({geometry}) => {
		noise.seed(prop.seed === undefined ? Math.random() : prop.seed);
		const positions = geometry.getAttribute('position').array;
		const hVerts = geometry.parameters.heightSegments + 1;
		const wVerts = geometry.parameters.widthSegments + 1;
		const midHeight = hVerts * 0.5;
		const midWidth = wVerts * 0.5;
		const altitudeModifier = 10;
		const flatRangeSquared = 400;
		const rangeAltitude = altitudeModifier / (midHeight * midHeight + midWidth * midWidth);

		for (let height = 0; height < hVerts; height++) {
			for (let width = 0; width < wVerts; width++) {
				const ex = 1.1;
				const deltaHeight = Math.abs(midHeight - height);
				const deltaWidth = Math.abs(midWidth - width);
				const rangeSquared = (deltaHeight * deltaHeight + deltaWidth * deltaWidth);
				const offset = -0.5;

				if (rangeSquared > flatRangeSquared) {
					positions[3 * (height * wVerts + width) + 2] = offset + rangeSquared * rangeAltitude *
						(noise.simplex2(width / 100, height / 100) +
							noise.simplex2((width + 200) / 50, height / 50) * Math.pow(ex, 1) +
							noise.simplex2((width + 400) / 25, height / 25) * Math.pow(ex, 2) +
							noise.simplex2((width + 600) / 12.5, height / 12.5) * Math.pow(ex, 3) +
							noise.simplex2((width + 800) / 6.25, height / 6.25) * Math.pow(ex, 4));
				} else {
					positions[3 * (height * wVerts + width) + 2] = offset;
				}
			}
		}

		positions.needsUpdate = true;
	}, [prop.seed]);
	const color = '#497528';
	const planePolys = 512;
	const planeDivisions = 512;
	const grass = useTexture(`${Images}/textures/grass.jpg`);

	grass.wrapS = grass.wrapT = THREE.RepeatWrapping;
	grass.repeat.set(planeDivisions, planeDivisions);
	return (
		<mesh ref={plainMesh} rotation={[0, 0, 0]} receiveShadow>
			<planeBufferGeometry up={[0, 0, 1]} attach="geometry" args={[planePolys, planePolys, planeDivisions, planeDivisions]} />
			<meshPhongMaterial map={grass} color={color} attach="material" shininess={3} flatShading/>
		</mesh>
	);
}

const mapStateToProps = store => ({
});

export default connect(mapStateToProps, {
})(Terrain);
