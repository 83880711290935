import {
	FIREBASE_PUSH_CHILD,
	FIREBASE_REMOVE,
	FIREBASE_SET,
	FIREBASE_TRACK_BY_CHILD,
	FIREBASE_UNTRACK_BY_CHILD
} from './firebaseActions';

export function addFeatureDecoration(feature, child) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `features/${feature}/decorations`,
		child
	};
}

export function removeFeatureDecoration(feature, decoration) {
	return {
		type: FIREBASE_REMOVE,
		path: `features/${feature}/decorations/${decoration}`
	};
}

export function updateFeature(featureKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `features/${featureKey}/${member}`,
		value
	};
}

export function updateFeatureBoxes(featureKey, featureBoxes, member, value) {
	return dispatch => {
		featureBoxes.forEach(box => {
			const boxKey = box.join('_');
			dispatch({
				type: FIREBASE_SET,
				path: `features/${featureKey}/boxes/${boxKey}/${member}`,
				value
			});
		});
	};
}
export function trackFeatures(scene) {
	return {
		type: FIREBASE_TRACK_BY_CHILD,
		path: 'features',
		child: 'scene',
		from: scene,
		to: scene
	};
}
export function untrackFeatures(scene) {
	return {
		type: FIREBASE_UNTRACK_BY_CHILD,
		path: 'features',
		child: 'scene',
		from: scene,
		to: scene
	};
}
export function addFeature(child) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'features',
		child
	};
}
export function removeFeature(feature) {
	return {
		type: FIREBASE_REMOVE,
		path: `features/${feature}`
	};
}
