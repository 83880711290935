import { combineReducers } from 'redux';
import appReducer from './appReducer';
import editReducer from './editReducer';
import firebaseReducer from './firebaseReducer';
import jsonReducer from './jsonReducer';

export default combineReducers({
	app: appReducer,
	edit: editReducer,
	firebase: firebaseReducer,
	json: jsonReducer
});

export function createReducer(initialState, handlers) {
	return (state = initialState, action) => {
		return handlers.hasOwnProperty(action.type) ?
			handlers[action.type](state, action) :
			state;
	};
}
