import {JSON_FETCH, jsonSet} from '../actions/jsonActions';

export const OnException = exception => {
	console.error(exception.message);
};

const jsonFetch = (store, name, path) => {
	fetch(path).then(async response => {
		const json = await response.json();

		store.dispatch(jsonSet(name, json));
	}).catch(OnException);
};

const jsonService = () => {
	return store => next => action => {
		switch (action.type) {
		case JSON_FETCH:
			jsonFetch(store, action.name, action.path);
			break;
		default:
			return next(action);
		}
	};
};

export default jsonService();

