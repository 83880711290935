import {useEffect, useRef} from 'react';

const deepDiffMapper = function () {
	return {
		VALUE_CREATED: 'created',
		VALUE_UPDATED: 'updated',
		VALUE_DELETED: 'deleted',
		VALUE_UNCHANGED: 'unchanged',
		compare: function(obj1, obj2, name) {
			return this.diff(obj1, obj2, [name], {});
		},
		diff: function(lhs, rhs, path, diff) {
			if (this.isFunction(lhs) || this.isFunction(rhs)) {
				if (! (this.isFunction(lhs) && this.isFunction(rhs))) {
					throw 'Arguments do not have the same functions pointers.';
				}
			} else if (this.isValue(lhs) || this.isValue(rhs)) {
				const compare = this.compareValues(lhs, rhs);

				if (compare === this.VALUE_UPDATED) {
					diff[path.join('.')] = `updated from: ${JSON.stringify(lhs)}, to: ${JSON.stringify(rhs)}`;
				} else if (compare === this.VALUE_CREATED) {
					diff[path.join('.')] = `created: ${JSON.stringify(rhs)}`;
				} else if (compare === this.VALUE_DELETED) {
					diff[path.join('.')] = `deleted: ${JSON.stringify(lhs)}`;
				}
			} else {
				Object.keys(lhs)
					.filter(key => ! this.isFunction(rhs[key]))
					.forEach(key => {
						this.diff(lhs[key], rhs[key], [...path, key], diff);
					});
				Object.keys(rhs)
					.filter(key => ! this.isFunction(rhs[key]))
					.filter(key => lhs[key] === undefined)
					.forEach(key => {
						this.diff(lhs[key], rhs[key], [...path, key], diff);
					});
			}
			return diff;
		},
		compareValues: function (value1, value2) {
			if (value1 === value2) {
				return this.VALUE_UNCHANGED;
			}
			if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
				return this.VALUE_UNCHANGED;
			}
			if (value1 === undefined) {
				return this.VALUE_CREATED;
			}
			if (value2 === undefined) {
				return this.VALUE_DELETED;
			}
			return this.VALUE_UPDATED;
		},
		isFunction: function (x) {
			return Object.prototype.toString.call(x) === '[object Function]';
		},
		isArray: function (x) {
			return Object.prototype.toString.call(x) === '[object Array]';
		},
		isDate: function (x) {
			return Object.prototype.toString.call(x) === '[object Date]';
		},
		isObject: function (x) {
			return Object.prototype.toString.call(x) === '[object Object]';
		},
		isValue: function (x) {
			return !this.isObject(x) && !this.isArray(x);
		}
	};
}();
export default function WhyDidYouUpdate(props) {
	// Get a mutable ref object where we can store props ...
	// ... for comparison next time this hook runs.
	const previousProps = useRef();
	useEffect(() => {
		if (previousProps.current) {
			// Get all keys from previous and current props
			const allKeys = Object.keys({ ...previousProps.current, ...props });
			// Use this object to keep track of changed props
			const changesObj = {};
			// Iterate through keys
			allKeys.forEach((key) => {
				// If previous is different from current
				if (previousProps.current[key] !== props[key]) {
					// Add to changesObj
					changesObj[key] = {
						from: previousProps.current[key],
						to: props[key],
					};
				}
			});
			// If changesObj not empty then output to console
			Object.keys(changesObj).forEach(change => {
				const compare = deepDiffMapper.compare(changesObj[change].from, changesObj[change].to, change);

				if (Object.keys(compare).length > 0) {
					console.log(`[${name} update]`, change);
					console.log(compare);
				}
			});
		}
		// Finally update previousProps with current props for next hook call
		previousProps.current = props;
	});
	return null;
}
