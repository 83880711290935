import {styled} from '@material-ui/core/styles';
import {Drawer as MuiDrawer} from '@material-ui/core';
import {connect} from 'react-redux';

const Drawer = styled(MuiDrawer)(({ theme, open, width }) => ({
	'& .MuiDrawer-paper': {
		position: 'relative',
		whiteSpace: 'nowrap',
		width,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
		boxSizing: 'border-box',
		...(!open && {
			overflowX: 'hidden',
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			width: theme.spacing(7),
			[theme.breakpoints.up('sm')]: {width: theme.spacing(9)},
		}),
	},
}));

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(Drawer);
