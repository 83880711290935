import React, {useEffect, useState} from 'react';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectFirebaseAssignments, selectFirebaseMonsters} from '../../../selectors/state/firebase';
import {connect} from 'react-redux';
import {updateAssignment} from '../../../actions/monsterActions';
import {Box, FormControlLabel, makeStyles, MenuItem, Switch, TextField} from '@material-ui/core';
import ModelList from '../lists/ModelList';
import {selectRules} from '../../../selectors/state/jsonSelector';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/rules.jpg`;

function AssignmentPanel(props) {
	if (props.view !== 'assignment' || props.assignments === undefined) return null;

	const useStyles = makeStyles(theme => ({
		formLine: {
			display: 'flex',
			paddingBottom: theme.spacing(2)
		},
		formElement: {
			flexGrow: 1,
			minWidth: 100,
			margin: theme.spacing(1)
		}
	}));
	const classes = useStyles();
	const assignmentKey = props.data.assignmentKey;
	const assignment = props.assignments[assignmentKey];
	const [predicate, setPredicate] = useState(assignment.predicate || { type: '' });
	const defaultPredicates = {
		boolean: { type: 'boolean', value: true },
		equals: { type: 'equals', field: 'size', value: 'Medium'},
		contains: { type: 'contains', field: 'size', value: ['Medium']},
	};
	const monsters = props.rules[assignment.rules].monsters;
	const fields = {
		key: {
			label: 'Monster',
			values: Object.fromEntries(Object.entries(monsters).map(([key, value]) => [key, value.name]))
		},
		size: {
			label: 'Size',
			values: {
				Tiny: 'Tiny',
				Small: 'Small',
				Medium: 'Medium',
				Large:'Large',
				Huge:'Huge',
				Gargantuan:'Gargantuan'
			}
		}
	};
	const handleSetImage = image => props.updateAssignment(assignmentKey, 'image', image);
	const handleTypeChange = event => {
		props.updateAssignment(assignmentKey, 'predicate', defaultPredicates[event.target.value]);
		setPredicate(defaultPredicates[event.target.value]);
	};
	const updatePredicate = (member, value) => {
		const newValue = {
			...predicate,
			[member]: value
		};

		props.updateAssignment(assignmentKey, 'predicate', newValue);
		setPredicate(newValue);
	};
	const buildBinaryOperator = options => {
		const field = assignment.predicate?.field || '';
		const value = assignment.predicate?.value || (options?.multiple ? [] : '');
		const values = assignment.predicate?.field && fields[assignment.predicate.field].values || {};

		return (<Box className={classes.formLine}>
			<TextField
				className={classes.formElement} label='Field to check' value={field}
				select onChange={handleFieldChange}>
				<MenuItem key='unset' disabled value=''>Unset</MenuItem>
				{Object.keys(fields).map(field => (
					<MenuItem key={field} value={field}>{fields[field].label}</MenuItem>))}
			</TextField>
			<Autocomplete
				options={Object.keys(values)} getOptionLabel={key => values[key]} multiple={options?.multiple}
				onChange={handleSelectValueChange} renderInput={params => <TextField {...params} label='Value'/>}
				className={classes.formElement} value={value}/>
		</Box>);
	};
	const handleSwitchValueChanged = event => updatePredicate('value', event.target.checked);
	const handleSelectValueChange = (event, newValue) => updatePredicate('value', newValue);
	const handleFieldChange = event => {
		const newValue = {
			...predicate,
			field: event.target.value,
			value: ''
		};

		props.updateAssignment(assignmentKey, 'predicate', newValue);
		setPredicate(newValue);
	};
	const handleModelChange = event => props.updateAssignment(assignmentKey,'model', event.target.value);
	const ruleDetails = {
		boolean: <Box className={classes.formLine}>
			<FormControlLabel label='True or false' title='If true then rule always applies' control={
				<Switch checked={assignment.predicate?.value === true} onChange={handleSwitchValueChanged}/>
			}/></Box>,
		equals: buildBinaryOperator(),
		contains: buildBinaryOperator({multiple: true})
	};

	useEffect(() => {
		props.setTitle('Rule', ['monster', 'monsters']);
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={assignment.image || defaultImage} editImage={true} setImage={handleSetImage}/>
			<TextField
				className={classes.formLine} label='Predicate type' select value={predicate.type} fullWidth
				onChange={handleTypeChange}>
				<MenuItem disabled value=''>Unset</MenuItem>
				<MenuItem value='boolean'>Boolean</MenuItem>
				<MenuItem value='equals'>Equals</MenuItem>
				<MenuItem value='contains'>Contains</MenuItem>
			</TextField>
			{ruleDetails[predicate.type]}
			<ModelList admin={props.data.admin} value={assignment.model} onChange={handleModelChange}/>
		</Panel>
	);
}
const mapStateToProps = store => ({
	assignments: selectFirebaseAssignments(store),
	monsters: selectFirebaseMonsters(store),
	rules: selectRules(store)
});

export default connect(mapStateToProps, {
	updateAssignment
})(AssignmentPanel);
