import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import {PlayCircleOutline} from '@material-ui/icons';
import {selectFirebaseCampaigns, selectFirebaseCharacters, selectFirebaseScenes}
	from '../../../selectors/state/firebase';
import Panel from './Panel';
import {DefaultImage} from '../items/SceneItem';
import {updateScene} from '../../../actions/sceneActions';
import {setViewMode} from '../../../actions/appActions';
import ContentCoverStatic from '../items/ContentCoverStatic';
import {DefaultImage as DefaultCharacterImage} from '../items/CharacterItem';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function ScenePlayerPanel(props) {
	if (props.view !== 'scenePlayer') return null;

	const scene = props.scenes[props.data.scene];
	const campaign = props.campaigns[props.data.campaign];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleSetTab = tab => setSelectedTab(tab);
	const handleClickPlayScene = () => {
		props.setViewMode('player', {
			campaignKey: props.data.campaign,
			scenarioKey: props.data.scenario,
			sceneKey: props.data.scene
		});
	};
	const tabs = {
		Notes: {
			content: <Notes key='notes' notes={scene.notes} owner={scene.user} players={campaign.players}/>,
			tools: []
		},
		Characters: {
			content: Object.keys(scene.characters || {})
				.filter(characterKey => props.characters[characterKey])
				.map(characterKey => {
					const character = props.characters[characterKey];

					return <ListItem key={characterKey} alignItems="flex-start">
						<ListItemAvatar>
							<Avatar alt={character.name} src={character.image || DefaultCharacterImage}/>
						</ListItemAvatar>
						<ListItemText primary={character.name}/>
						<IconButton
							data-character={characterKey} aria-label="play scene"
							title='Play Scene' onClick={handleClickPlayScene}>
							<PlayCircleOutline/>
						</IconButton>
					</ListItem>;
				}),
			tools: []
		}
	};

	useEffect(() => {
		props.setTitle(scene.name, ['scenarioPlayer', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCoverStatic
				image={scene.image || DefaultImage} name={scene.name} description={scene.description}
				toolbar={[<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>]}
			/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	campaigns: selectFirebaseCampaigns(store),
	characters: selectFirebaseCharacters(store),
	scenes: selectFirebaseScenes(store)
});

export default connect(mapStateToProps, {
	setViewMode,
	updateScene
})(ScenePlayerPanel);
