import React from 'react';
import {connect} from 'react-redux';
import {Box as MuiBox, Breadcrumbs, Link as MuiLink, Toolbar} from '@material-ui/core';
import {firebaseSignOut} from '../../actions/firebaseActions';
import {styled} from '@material-ui/core/styles';

const Box = styled(MuiBox)(() => ({
	flexGrow: 0
}));

const Link = styled(MuiLink)(() => ({
	cursor: 'pointer'
}));

function FooterBar(props) {
	return (
		<Box color='default'>
			<Toolbar>
				<Breadcrumbs>
					{(props.breadcrumbs || []).map(link => (
						<Link
							key={link} color='inherit' onClick={props.onLinkClick}
							data-view={link.view} data-title={link.title} data-data={JSON.stringify(link.data)}>
							{link.title || link.view}
						</Link>
					))}
				</Breadcrumbs>
			</Toolbar>
		</Box>
	);
}
const mapStateToProps = store => ({});

export default connect(mapStateToProps, {firebaseSignOut})(FooterBar);
