import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Button, Typography} from '@material-ui/core';
import firebase from '@firebase/app';
import Panel from './Panel';
import Cover from '../items/Cover';
import {DefaultImage} from '../items/FriendItem';
import {inviteFriend} from '../../../actions/firebaseActions';
import {selectAppAuthentication} from '../../../selectors/state/app';

function UserPanel(props) {
	if (props.view !== 'user') return null;

	const [user, setUser] = useState();
	const handleInviteFriendClick = () => {
		props.inviteFriend(props.authentication.key, props.data);
		props.setView('social');
	};

	useEffect(() => {
		firebase.database().ref(`usersPublic/${props.data}`).on('value', snapshot => {
			setUser(snapshot.val());
		});
		return () => firebase.database().ref(`usersPublic/${props.data}`).off();
	}, []);
	useEffect(() => {
		props.setTitle(user?.nickname, 'social');
	}, user);
	if (user === undefined) return null;
	return (
		<Panel visible={true}>
			<Cover
				image={user.image || DefaultImage}
				name={user.nickname}
				toolbar={[
					<Button color='inherit' key='invite' onClick={handleInviteFriendClick}>Invite Friend</Button>
				]}
			>
				<Typography variant='h5'>{user.nickname}</Typography>
				<Typography variant='body1'>{user.bio}</Typography>
			</Cover>
		</Panel>
	);
}

const mapStateToProps = store => ({authentication: selectAppAuthentication(store)});

export default connect(mapStateToProps, {inviteFriend})(UserPanel);
