import React from 'react';
import Drawer from './Drawer';
import {List, ListItem, Toolbar} from '@material-ui/core';
import {connect} from 'react-redux';
import Advert from './Advert';
import {Images} from '../../config';

const RightBar = props => {
	const handleAdvertClick = () => {
		props.setView('shop');
	};

	return (
		<Drawer variant='permanent' anchor='right' open={true} width={props.drawerWidth}>
			<Toolbar/>
			<List>
				<ListItem>
					<Advert
						image={`${Images}/adverts/vampire.jpg`}
						title='Vampire'
						body='Impress your friends with a grumpy vampire. Don&apos;t expect them to invite you indoors!'
						onClick={handleAdvertClick}
					/>
				</ListItem>
			</List>
		</Drawer>
	);
};

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(RightBar);
