import React, {useState} from 'react';
import {connect} from 'react-redux';
import {firebaseSignOut, inviteFriend, removeFriend, setUserPublic} from '../../actions/firebaseActions';
import Modal from './Modal';
import {Box, FormControl, Grid, IconButton, InputAdornment, InputLabel, List, ListItem, OutlinedInput,
	TextField, Typography} from '@material-ui/core';
import {selectFirebaseFriends, selectFirebaseUserPublic} from '../../selectors/state/firebase';
import {Scrollbars} from 'react-custom-scrollbars';
import {Delete, PersonAdd, PersonAddDisabled, Search} from '@material-ui/icons';
import firebase from 'firebase/app';
import 'firebase/database';

function ModalAccountDetails(props) {
	const [candidates, setCandidates] = useState({});
	const [identifier, setIdentifier] = useState(props.userPublic?.identifier || '');
	const [nickname, setNickname] = useState(props.userPublic?.nickname || '');
	const [searchTerm, setSearchTerm] = useState('');
	const handleModalClose = () => {
		if (nickname !== props.userPublic?.nickname || identifier !== props.userPublic?.identifier) {
			props.setUserPublic(props.authentication.key, {nickname, identifier});
		}
		props.confirm();
	};
	const handleRemoveFriend = event => {
		props.removeFriend(props.authentication.key, event.currentTarget.value);
	};
	const handleFindFriend = () => {
		return firebase.database().ref('usersPublic').orderByChild('nickname').startAt(searchTerm)
			.endAt(searchTerm + 'z').limitToFirst(20).once('value', snapshot => {
				const candidates = snapshot.val();

				setCandidates(candidates);
			});
	};
	const handleAddFriend = event => {
		props.inviteFriend(props.authentication.key, event.currentTarget.value);
		setSearchTerm('');
		setCandidates({});
	};

	return (
		<Modal cancelText='Close' cancel={handleModalClose}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Typography variant='h4'>{props.authentication.displayName}</Typography>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label='Nickname' variant='outlined'
						onChange={event => setNickname(event.target.value)} value={nickname}/>
					<TextField style={{marginTop:8}}
						label='Other Identifying Information' variant='outlined'
						onChange={event => setIdentifier(event.target.value)} value={identifier}/>
					<FormControl style={{marginTop:8}} variant='outlined' fullWidth>
						<InputLabel htmlFor='find-friend'>Find Friend</InputLabel>
						<OutlinedInput
							id='find-friend' placeholder='Nickname' value={searchTerm} autoComplete='off'
							onChange={event => setSearchTerm(event.target.value)}
							onKeyDown={event => event.keyCode === 13 && handleFindFriend()}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton onClick={handleFindFriend} edge='end'><Search/></IconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<Scrollbars style={{height: '30vh'}}>
						<List dense disablePadding>
							{Object.keys(candidates || {}).filter(candidateKey =>
								candidateKey !== props.authentication.key &&
									! Object.values(props.friends || {}).find(friendKey => friendKey === candidateKey)
							).map(candidateKey => {
								const candidate = candidates[candidateKey];

								return (
									<ListItem key={candidateKey}>
										<IconButton value={candidateKey} onClick={handleAddFriend}>
											<PersonAdd/>
										</IconButton>
										<Typography variant='body1'>{candidate.nickname}</Typography>
										{candidate.identifier &&
											<Typography noWrap title={candidate.identifier} variant='body1'>
												&nbsp;({candidate.identifier})
											</Typography>}
									</ListItem>
								);
							})}
						</List>
					</Scrollbars>
				</Grid>
				<Grid item xs={6}>
					<Box p={2} border={1} borderRadius={4} borderColor='primary.main'>
						<Scrollbars style={{height: '40vh'}}>
							<List dense disablePadding>
								{Object.keys(props.friends || {}).map(friendKey => {
									const friend = props.friends[friendKey];

									return (
										<ListItem key={friendKey}>
											{friend.status === 'invited' ?
												<IconButton size='small' onClick={handleRemoveFriend} value={friendKey}>
													<PersonAddDisabled/>
												</IconButton> :
												<IconButton size='small' onClick={handleRemoveFriend} value={friendKey}>
													<Delete/>
												</IconButton>}
											<Typography variant={'body1'}>{friend.nickname}</Typography>
										</ListItem>
									);
								})}
							</List>
						</Scrollbars>
					</Box>
				</Grid>
			</Grid>
		</Modal>
	);
}

const mapStateToProps = store => ({
	friends: selectFirebaseFriends(store),
	userPublic: selectFirebaseUserPublic(store)
});

export default connect(mapStateToProps, {
	inviteFriend,
	removeFriend,
	setUserPublic,
	firebaseSignOut
})(ModalAccountDetails);
