import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {firebaseTrackAuthedUser, trackCampaigns, firebaseTrackMessages, trackShopItems,
	trackUserItems, trackUserModels, trackFriends, trackUserFiles, trackUserPublic,
	trackUserRules, trackRules, trackUserFavourites, trackThemes,
	trackInvites} from '../../actions/firebaseActions';
import {trackAssignments} from '../../actions/monsterActions';
import {trackFriendCharacters, trackUserCharacters} from '../../actions/characterActions';
import {trackModels} from '../../actions/modelActions';
import {trackUserScenarios} from '../../actions/scenarioActions';
import {trackUserScenes} from '../../actions/sceneActions';
import {selectAppAuthentication, selectViewMode} from '../../selectors/state/app';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import {Box, Container, CssBaseline} from '@material-ui/core';
import {Scrollbars} from 'react-custom-scrollbars';
import {selectFirebaseMessages} from '../../selectors/state/firebase';
import AssignmentPanel from './panels/AssignmentPanel';
import CampaignPanel from './panels/CampaignPanel';
import CampaignPlayerPanel from './panels/CampaignPlayerPanel';
import CharacterPanel from './panels/CharacterPanel';
import CharactersPanel from './panels/CharactersPanel';
import FriendPanel from './panels/FriendPanel';
import HomePanel from './panels/HomePanel';
import MessagesPanel from './panels/MessagesPanel';
import ModelPanel from './panels/ModelPanel';
import ModelsPanel from './panels/ModelsPanel';
import MonsterPanel from './panels/MonsterPanel';
import MonstersPanel from './panels/MonstersPanel';
import PicturePanel from './panels/PicturePanel';
import PlayerPanel from './panels/PlayerPanel';
import PlayingPanel from './panels/PlayingPanel';
import RefereePanel from './panels/RefereePanel';
import RunningPanel from './panels/RunningPanel';
import ScenarioPanel from './panels/ScenarioPanel';
import ScenarioPlayerPanel from './panels/ScenarioPlayerPanel';
import ScenePanel from './panels/ScenePanel';
import ScenePlayerPanel from './panels/ScenePlayerPanel';
import ShopItemPanel from './panels/ShopItemPanel';
import ShopPanel from './panels/ShopPanel';
import RulesPanel from './panels/RulesPanel';
import SocialPanel from './panels/SocialPanel';
import UserPanel from './panels/UserPanel';
import FooterBar from './FooterBar';
import LeftBar from './LeftBar';
import RightBar from './RightBar';
import TitleBar from './TitleBar';
import RefereeView from '../referee/RefereeView';
import EditView from '../edit/EditView';
import PlayView from '../play/PlayView';
import GalleryPanel from './panels/GalleryPanel';

const drawerWidth = 240;
const customTheme = {shape: {borderRadius: 16}};

function Portal(props) {
	const portal = useRef();
	const theme = createMuiTheme(customTheme);
	const [sidebarOpen, setSidebarOpen] = useState(true);
	const [view, setView] = useState('home');
	const [viewData, setViewData] = useState({});
	const [title, setTitle] = useState('Home');
	const [breadcrumbs, setBreadcrumbs] = useState([{view, title: 'Home'}]);
	const toggleSidebarOpen = () => setSidebarOpen(!sidebarOpen);
	const handleSetView = (view, data) => {
		setView(view);
		setViewData(data);
	};
	const handleSetTitle = (title, parent) => {
		const home = {view: 'home', title: 'Home'};
		const insertBreadcrumb = parent => {
			const index = breadcrumbs.findIndex(crumb => crumb.view === parent);

			if (index !== -1) {
				setBreadcrumbs([...breadcrumbs.slice(0, index + 1), {data: viewData, title, view}]);
				return true;
			}
		};

		if (parent === undefined || (parent.some ? !parent.some(insertBreadcrumb) : !insertBreadcrumb(parent))) {
			if (view === 'home') {
				setBreadcrumbs([home]);
			} else {
				setBreadcrumbs([home, {data: viewData, title, view}]);
			}
		}
		setTitle(title);
	};
	const handleLinkClick = event => {
		const index = breadcrumbs.findIndex(link => link.view === event.target.dataset.view);

		setBreadcrumbs([...breadcrumbs.slice(0, index + 1)]);
		setView(event.target.dataset.view);
		setViewData(event.target.dataset.data && JSON.parse(event.target.dataset.data));
		event.preventDefault();
	};

	useEffect(() => {
		if (props.authentication) {
			props.firebaseTrackMessages(props.authentication.key);
			props.trackAssignments(props.authentication.key);
			props.trackModels();
			props.trackUserCharacters(props.authentication.key);
			props.trackFriends(props.authentication.key);
			props.trackFriendCharacters(props.authentication.key);
			props.trackCampaigns(props.authentication.key);
			props.trackInvites(props.authentication.key);
			props.trackRules();
			props.trackShopItems();
			props.trackThemes();
			props.trackUserFavourites(props.authentication.key);
			props.trackUserFiles(props.authentication.key);
			props.trackUserModels(props.authentication.key);
			props.trackUserPublic(props.authentication.key);
			props.trackUserRules(props.authentication.key);
			props.trackUserScenarios(props.authentication.key);
			props.trackUserScenes(props.authentication.key);
			props.trackUserItems(props.authentication.key);
		}
	}, [props.authentication]);
	if (props.viewMode.name === 'referee') {
		return (
			<ThemeProvider theme={theme}>
				<RefereeView/>
			</ThemeProvider>
		);
	}
	if (props.viewMode.name === 'edit') {
		return (
			<ThemeProvider theme={theme}>
				<EditView/>
			</ThemeProvider>
		);
	}
	if (props.viewMode.name === 'player') {
		return (
			<ThemeProvider theme={theme}>
				<PlayView/>
			</ThemeProvider>
		);
	}
	return (
		<div ref={portal} className="portal">
			<ThemeProvider theme={theme}>
				<Box style={{display: 'flex', flexFlow: 'column', height: '100vh'}}>
					<Box style={{display: 'flex', flexGrow: 1}}>
						<CssBaseline/>
						<TitleBar
							open={sidebarOpen} drawerWidth={drawerWidth} toggleOpen={toggleSidebarOpen} title={title}
							messages={props.messages} setView={handleSetView}/>
						<LeftBar
							open={sidebarOpen} drawerWidth={drawerWidth} toggleOpen={toggleSidebarOpen}
							setView={handleSetView}/>
						<Box component='main' style={{
							flexGrow: 1,
							overflow: 'auto',
							paddingTop: 90
						}}>
							<Container style={{height: '100%'}} maxWidth='lg' spacing={3}>
								<Scrollbars autoHide={true} hideTracksWhenNotNeeded={true}>
									<AssignmentPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<CampaignPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<CharacterPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<CharactersPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<CampaignPlayerPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<SocialPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<FriendPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<GalleryPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<HomePanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<MessagesPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ModelPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ModelsPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<MonstersPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<MonsterPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<PlayingPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<PlayerPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<PicturePanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<RefereePanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<RunningPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<RulesPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ScenarioPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ScenarioPlayerPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ScenePanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ScenePlayerPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ShopItemPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<ShopPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
									<UserPanel
										view={view} setTitle={handleSetTitle} data={viewData} setView={handleSetView}/>
								</Scrollbars>
							</Container>
						</Box>
						<RightBar drawerWidth={300} setView={handleSetView}/>
					</Box>
					<FooterBar breadcrumbs={breadcrumbs} onLinkClick={handleLinkClick}/>
				</Box>
			</ThemeProvider>
		</div>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	messages: selectFirebaseMessages(store),
	user: selectAppAuthentication(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	firebaseTrackAuthedUser,
	firebaseTrackMessages,
	trackAssignments,
	trackModels,
	trackUserCharacters,
	trackCampaigns,
	trackFriends,
	trackFriendCharacters,
	trackRules,
	trackShopItems,
	trackUserFiles,
	trackUserFavourites,
	trackUserItems,
	trackInvites,
	trackUserModels,
	trackUserPublic,
	trackUserRules,
	trackUserScenarios,
	trackUserScenes,
	trackThemes
})(Portal);
