import {connect} from 'react-redux';
import {useEffect} from 'react';
import {selectSelectedCharacter, selectSelectedDecoration, selectSelectedMonster}
	from '../../selectors/state/edit';
import {setSelected, setSelectedCharacter, setSelectedDecoration, setSelectedMonster} from '../../actions/editActions';
import {setCharacterAnimation, setCharacterPosition, setCharacterRotation} from '../../actions/characterActions';
import {removeFeatureDecoration} from '../../actions/featureActions';
import {setMonsterAnimation, setMonsterPosition, setMonsterRotation} from '../../actions/monsterActions'; 
import {removeCharacterFromScene, removeMonsterFromScene} from '../../actions/sceneActions';
import {FloorHeight} from '../features/Features';
import {selectViewMode} from '../../selectors/state/app';
import * as THREE from 'three';
import {selectFirebaseFeatures} from '../../selectors/state/firebase';

const forwardVector = new THREE.Vector3(0, 1, 0);
const goalPosition = new THREE.Vector3();
const goalQuaternion = new THREE.Quaternion();
const rotationAxis = new THREE.Vector3(0, 0, 1);
const startPosition = new THREE.Vector3();

function RefereeControls(props) {
	useEffect(() => {
		const getPosition = (rotation, position, distance) => {
			const featureContainingPosition = position => Object.values(props.features).find(feature => {
				return feature.position[0] <= position.x && feature.position[0] + feature.size[0] > position.x &&
					feature.position[1] <= position.y && feature.position[1] + feature.size[1] > position.y &&
					feature.position[2] <= position.z && feature.position[2] + feature.size[2] > position.z;
			});

			goalQuaternion.setFromAxisAngle(rotationAxis, rotation);
			startPosition.fromArray(position);
			goalPosition.copy(forwardVector).multiplyScalar(distance)
				.applyAxisAngle(rotationAxis, rotation).add(startPosition).round();

			const goalFeature = featureContainingPosition(goalPosition);

			if (goalFeature) {
				goalPosition.z = goalFeature.position[2] + FloorHeight(goalFeature,
					goalPosition.x - goalFeature.position[0],
					goalPosition.y - goalFeature.position[1]);
				return goalPosition.toArray();
			}
			return startPosition.toArray();
		};
		const onKeydown = event => {
			const animations = ['Idle', 'Death', 'Walking'];

			if (event.keyCode === 27) { // Escape
				props.setSelected(undefined, []);
				props.setSelectedCharacter(undefined);
				props.setSelectedDecoration(undefined);
				props.setSelectedMonster(undefined);
			} else if (props.selectedCharacter) {
				const character = props.scene.characters[props.selectedCharacter];

				if (event.keyCode === 37 || event.keyCode === 65) { // Left / A
					props.setCharacterRotation(props.viewMode.data.sceneKey, props.selectedCharacter,
						character.rotation + Math.PI * 0.5);
				} else if (event.keyCode === 38 || event.keyCode === 87) { // Up / W
					props.setCharacterPosition(props.viewMode.data.sceneKey, props.selectedCharacter,
						getPosition(character.rotation, character.position, 1));
				} else if (event.keyCode === 39 || event.keyCode === 68) { // Right / D
					props.setCharacterRotation(props.viewMode.data.sceneKey, props.selectedCharacter,
						character.rotation - Math.PI * 0.5);
				} else if (event.keyCode === 40 || event.keyCode === 83) { // Down / S 
					props.setCharacterPosition(props.viewMode.data.sceneKey, props.selectedCharacter,
						getPosition(character.rotation, character.position, -1));
				} else if (event.keyCode >= 49 && event.keyCode <= 51) {
					props.setCharacterAnimation(props.viewMode.data.sceneKey, props.selectedCharacter,
						animations[event.keyCode - 49]);
				} else if (event.keyCode === 46 || event.keyCode === 8) {
					props.setSelectedCharacter(undefined);
					props.removeCharacterFromScene(props.viewMode.data.sceneKey, props.selectedCharacter);
				}
			} else if (props.selectedMonster) {
				const monster = props.scene.monsters[props.selectedMonster];

				if (event.keyCode === 37 || event.keyCode === 65) { // Left / A
					props.setMonsterRotation(props.viewMode.data.sceneKey, props.selectedMonster,
						monster.rotation + Math.PI * 0.5);
				} else if (event.keyCode === 38 || event.keyCode === 87) { // Up / W
					props.setMonsterPosition(props.viewMode.data.sceneKey, props.selectedMonster,
						getPosition(monster.rotation, monster.position, 1));
				} else if (event.keyCode === 39 || event.keyCode === 68) { // Right / D
					props.setMonsterRotation(props.viewMode.data.sceneKey, props.selectedMonster,
						monster.rotation - Math.PI * 0.5);
				} else if (event.keyCode === 40 || event.keyCode === 83) { // Down / S 
					props.setMonsterPosition(props.viewMode.data.sceneKey, props.selectedMonster,
						getPosition(monster.rotation, monster.position, -1));
				} else if (event.keyCode >= 49 && event.keyCode <= 51) {
					props.setMonsterAnimation(props.viewMode.data.sceneKey, props.selectedMonster,
						animations[event.keyCode - 49]);
				} else if (event.keyCode === 46 || event.keyCode === 8) {
					props.setSelectedMonster(undefined);
					props.removeMonsterFromScene(props.viewMode.data.sceneKey, props.selectedMonster);
				}					
			} else if (props.selectedDecoration) {
				if (event.keyCode === 46 || event.keyCode === 8) {
					props.setSelectedDecoration(undefined);
					props.removeFeatureDecoration(props.selectedDecoration.feature,
						props.selectedDecoration.decoration);
				}
			}
		};

		window.addEventListener('keydown', onKeydown);
		return () => {
			window.removeEventListener('keydown', onKeydown);
		};
	}, [
		props.scene,
		props.selectedMonster,
		props.selectedCharacter,
		props.selectedDecoration
	]);

	return null;
}

const mapStateToProps = store => ({
	selectedCharacter: selectSelectedCharacter(store),
	selectedDecoration: selectSelectedDecoration(store),
	features: selectFirebaseFeatures(store),
	selectedMonster: selectSelectedMonster(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	removeCharacterFromScene,
	removeFeatureDecoration,
	removeMonsterFromScene,
	setCharacterAnimation,
	setCharacterPosition,
	setCharacterRotation,
	setMonsterAnimation,
	setMonsterPosition,
	setMonsterRotation,
	setSelected,
	setSelectedCharacter,
	setSelectedDecoration,
	setSelectedMonster
})(RefereeControls);
