import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectRules} from '../../../selectors/state/jsonSelector';
import MonsterStats from '../../monsters/srd/MonsterStats';
import {Box, IconButton, Typography} from '@material-ui/core';
import ToolbarTabs from '../items/ToolbarTabs';
import AssignmentList from '../lists/AssignmentList';
import ModelItem from '../items/ModelItem';
import UserAssignments from '../../monsters/UserAssignments';
import {selectFirebaseAssignments, selectFirebaseModels, selectFirebaseUserModels}
	from '../../../selectors/state/firebase';
import {selectAppAuthentication, selectAppUser} from '../../../selectors/state/app';
import {Queue} from '@material-ui/icons';
import {addAssignment, removeAssignment} from '../../../actions/monsterActions';
import {Images} from '../../../config';

const defaultImage = `${Images}/ui/rules.jpg`;

function MonsterContent(props) {
	return (
		<Box>
			<Typography variant='h5'>{props.monster.name}</Typography>
			<MonsterStats monster={props.monster}/>
		</Box>
	);
}
function MonsterPanel(props) {
	if (props.view !== 'monster' || props.rules === undefined) return null;

	const monsters = props.rules[props.data.rules].monsters;
	const monsterKey = props.data.monster;
	const monster = monsters[monsterKey];
	const [dirty, setDirty] = useState(0);
	const [selectedTab, setSelectedTab] = useState('Monster');
	const handleSetTab = tab => setSelectedTab(tab);
	const handleModelClick = () => {
		props.setView('model', { model: userAssignments.model});
	};
	const handleAddAssignment = () => {
		props.addAssignment(props.auth.key, props.data.rules,
			{predicate: { type: 'equals', field: 'key', value: monsterKey}});
	};
	const handleRemoveAssignment = event => {
		props.removeAssignment(event.currentTarget.dataset.key);
		setDirty(Date.now()); // Really don't understand the need for this
	};
	const userAssignments = new UserAssignments(props.auth.key, props.user, props.assignments);
	const image = userAssignments.getImage(monsterKey, monster);
	const modelKey = userAssignments.getModel(monsterKey, monster);
	const model = props.models[modelKey] || props.userModels[modelKey];
	const tabs = {
		Monster: {
			content: <MonsterContent monster={monster}/>,
			tools: []
		},
		Rules: {
			content:
				<AssignmentList
					assignments={userAssignments.assignments} monster={monster} monsterKey={props.data.monster}
					monsters={monsters} removeAssignment={handleRemoveAssignment}
					setView={props.setView} dirty={dirty}/>,
			tools: [
				<IconButton key='addRule' title='Add rule' color='inherit' onClick={handleAddAssignment}>
					<Queue/>
				</IconButton>
			]
		},
		Model: {
			content: model ?
				<ModelItem modelKey={modelKey} model={model} onClick={handleModelClick}/> : null,
			tools: []
		}
	};

	useEffect(() => {
		props.setTitle(monster.name, 'monsters');
	}, []);
	return (
		<Panel visible={true}>
			<Cover image={image || defaultImage} toolbar={[
				<ToolbarTabs key={'toolbar'} tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>,
				...tabs[selectedTab].tools
			]}/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	assignments: selectFirebaseAssignments(store),
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	rules: selectRules(store),
	user: selectAppUser(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {
	addAssignment,
	removeAssignment
})(MonsterPanel);
