import {FIREBASE_PUSH_CHILD, FIREBASE_REMOVE, FIREBASE_SET} from './firebaseActions';

export function addCampaignNote(campaign) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `campaigns/${campaign}/notes`,
		child: {title: 'New note', contents: 'New note contents', visibility: 'private'}
	};
}
export function removeCampaignNote(campaign, notes) {
	return {
		type: FIREBASE_REMOVE,
		path: `campaigns/${campaign}/notes/${notes}`
	};
}
export function updateCampaignNote(campaign, notes, value) {
	return {
		type: FIREBASE_SET,
		path: `campaigns/${campaign}/notes/${notes}`,
		value
	};
}
export function addScenarioNote(key) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `scenarios/${key}/notes`,
		child: {title: 'New note', contents: 'New note contents', visibility: 'private'}
	};
}
export function removeScenarioNote(key, notes) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenarios/${key}/notes/${notes}`
	};
}
export function updateScenarioNote(key, notes, value) {
	return {
		type: FIREBASE_SET,
		path: `scenarios/${key}/notes/${notes}`,
		value
	};
}

export function addSceneNote(key) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `scenes/${key}/notes`,
		child: {title: 'New note', contents: 'New note contents', visibility: 'private'}
	};
}
export function removeSceneNote(key, notes) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenes/${key}/notes/${notes}`
	};
}
export function updateSceneNote(key, notes, value) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${key}/notes/${notes}`,
		value
	};
}
