import React from 'react';
import {connect} from 'react-redux';
import {Box, makeStyles, Typography} from '@material-ui/core';
import {selectFirebaseCharacters, selectFirebaseMonsters, selectFirebaseScenes} from '../../selectors/state/firebase';
import {selectViewMode} from '../../selectors/state/app';
import Scrollbars from '../portal/Scrollbars';
import {setSelectedCharacter, setSelectedMonster} from '../../actions/editActions';

const useStyles = makeStyles(theme => ({
	characterInitiative: {
		borderColor: theme.palette.common.white,
		border: 'solid 1px',
		borderRadius: 16,
		height: 100,
		width: 120
	},
	initiative: {
		color: theme.palette.common.white,
		display: 'flex',
		flexGrow: 1,
		pointerEvents: 'all'
	}
}));

function CharacterInitiative(props) {
	const classes = useStyles();
	const handleCharacterClick = () => {
		props.setSelectedCharacter(props.characterKey);
		props.setSelectedMonster(undefined);
	};

	return (
		<Box className={classes.characterInitiative} p={1} onClick={handleCharacterClick}>
			<Typography variant='body2'>{props.character.name}</Typography>
		</Box>
	);
}

function Initiative(props) {
	const classes = useStyles();
	const scene = props.scenes[props.viewMode.data.sceneKey];

	return (
		<Box className={classes.initiative} p={1} display='flex'>
			<Scrollbars>
				{Object.keys(scene.characters || {}).map(key =>
					(<CharacterInitiative key={key} characterKey={key} character={props.characters[key]} {...props}/>)
				)}
			</Scrollbars>
		</Box>
	);
}

const mapStateToProps = store => ({
	characters: selectFirebaseCharacters(store),
	monsters: selectFirebaseMonsters(store),
	scenes: selectFirebaseScenes(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	setSelectedCharacter,
	setSelectedMonster
})(Initiative);
