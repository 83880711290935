import React, {useEffect, useState} from 'react';
import {useGLTF} from '@react-three/drei';
import {selectFirebaseModels} from '../../selectors/state/firebase';
import {connect} from 'react-redux';
import {SkeletonUtils} from 'three/examples/jsm/utils/SkeletonUtils';
import {AnimationMixer} from 'three';
import {useFrame} from 'react-three-fiber';
import 'firebase/storage';
import {Content} from '../../config';

export const DefaultAnimations = {
	Idle: {
		shortcut: 1,
		repetitions: Infinity,
		clampWhenFinished: false
	},
	Death: {
		shortcut: 2,
		repetitions: 1,
		clampWhenFinished: true
	},
	Walking: {
		repetitions: Infinity,
		clampWhenFinished: false
	}
};

function AnimatedModel(props) {
	const [actions, setActions] = useState({});
	const [animations, setAnimations] = useState(DefaultAnimations);
	const [clone, setClone] = useState();
	const [meshes, setMeshes] = useState();
	const [hips, setHips] = useState();
	const [mixer, setMixer] = useState();
	const [model, setModel] = useState();
	const gltf = useGLTF(`${Content}/${props.model.path}`);

	useEffect(() => {
		if (!props.model) return;
		if (model !== props.model.path) {
			// Disappearing model
			setModel(props.model.path);
			setMixer(null);
		}
		if (props.model.animations) {
			setAnimations({
				...DefaultAnimations,
				...props.model.animations
			});
		}
	}, [props.model]);
	useEffect(() => {
		if (!model) return;
		setClone(SkeletonUtils.clone(gltf.scene));
	}, [model]);
	useEffect(() => {
		if (!clone) return;
		setMeshes(Object.values(gltf.nodes)
			.filter(node => node.type === 'SkinnedMesh')
			.map(node => clone.getObjectByName(node.name)));
		setHips(clone.getObjectByName('Hips'));
	}, [clone]);
	useEffect(() => {
		if (!meshes) return;
		setMixer(new AnimationMixer(meshes[0]));
		return () => {
			setMixer(null);
		};
	}, [meshes]);
	useEffect(() => {
		if (!mixer) return;
		const update = {};

		gltf.animations.forEach(clip => {
			update[clip.name] = mixer.clipAction(clip);
			if (animations[clip.name]) {
				update[clip.name].repetitions = animations[clip.name].repetitions;
				update[clip.name].clampWhenFinished = animations[clip.name].clampWhenFinished;
			}
		});
		setActions(update);
	}, [mixer]);
	useEffect(() => {
		const blendDuration = 0.5;
		const action = actions[props.animation];

		if (!action) return;
		action.reset().fadeIn(blendDuration).play();
		return () => void action.fadeOut(blendDuration);
	}, [props.animation, actions]);
	useFrame((store, delta) => {
		if (!mixer) return;
		mixer.update(delta);
	});
	if (props.model.path !== model || meshes === undefined) return null;
	return (
		<group
			rotation={props.model.rotation}
			position={props.model.position}
			scale={props.model.scale}
			dispose={null}>
			<primitive object={hips} dispose={null}/>
			{meshes.filter(mesh => gltf.materials[mesh.material.name])
				.map(mesh => {
					const material = gltf.materials[mesh.material.name];

					return (
						<skinnedMesh
							onPointerDown={event => {
								props.onPointerDown(event);
							}}
							castShadow
							frustumCulled={false}
							key={mesh.uuid}
							material={material}
							geometry={mesh.geometry}
							skeleton={mesh.skeleton}
						/>
					);
				})}
		</group>
	);
}

const mapStateToProps = store => ({ models: selectFirebaseModels(store) });

export default connect(mapStateToProps, {})(AnimatedModel);
