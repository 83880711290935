import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import * as THREE from 'three';
import BluePrint from '../features/BluePrint';
import {setEditing, setEditingState} from '../../actions/editActions';
import {addFeature} from '../../actions/featureActions';
import {selectAppAuthentication, selectViewMode} from '../../selectors/state/app';
import {selectEditAdding, selectEditState} from '../../selectors/state/edit';

function Prefab(props) {
	const height = props.adding === 'room' ? 2 : 1;
	const defaultPosition = [0, 0, 0];
	const defaultSize = [1, 1, height];
	const [prefabPosition, setPrefabPosition] = useState(defaultPosition);
	const [prefabSize, setPrefabSize] = useState(defaultSize);
	const [prefabOrigin, setPrefabOrigin] = useState(defaultPosition);
	const windowRatio = {
		width: 2 / window.innerWidth,
		height: 2 / (window.innerHeight)
	};
	const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
	const cameraRay = new THREE.Vector3();
	const planePosition = new THREE.Vector3();
	const onPointerDown = () => {
		if (props.state === 'placing') {
			props.setEditingState('sizing');
		} else if (props.state === 'sizing') {
			props.setEditingState('committing');
			props.onAddFeature({
				position: prefabPosition,
				size: prefabSize,
				type: props.adding
			});
			props.setEditing('');
			props.setEditingState('waiting');
			setPrefabSize(defaultSize);
		}
	};
	const updateMousePosition = useCallback(event => {
		setMousePosition({x: event.clientX, y: event.clientY});
	}, []);
	const keydown = event => {
		if (event.keyCode === 27) {
			props.setEditing('');
		}
	};
	const getPrefabPosition = () => {
		const camera = props.camera.current;
		const cameraPosition = camera.position;
		const position = [];

		cameraRay.set(mousePosition.x * windowRatio.width - 1,
			1 - mousePosition.y * windowRatio.height, props.zPlane);
		cameraRay.unproject(camera);
		cameraRay.sub(cameraPosition).normalize();
		planePosition.copy(cameraPosition).add(cameraRay.multiplyScalar((props.zPlane - cameraPosition.z) / cameraRay.z));
		position[0] = Math.round(planePosition.x);
		position[1] = Math.round(planePosition.y);
		position[2] = props.zPlane;
		return position;
	};

	useEffect(() => {
		if (props.state === 'placing' || props.state === 'sizing') {
			const position = getPrefabPosition();

			position[2] = props.zPlane;
			if (props.state === 'placing') {
				setPrefabPosition(position);
				setPrefabOrigin(position);
			} else {
				const sizeX = Math.abs(prefabOrigin[0] - position[0]) + 1;
				const sizeY = Math.abs(prefabOrigin[1] - position[1]) + 1;
				let sizeZ = height;

				if (props.adding === 'staircase') {
					if (sizeX > sizeY) {
						sizeZ = sizeX + 1;
						if (prefabOrigin[0] > position[0]) {
							props.onSetOrientation('x-');
						} else {
							props.onSetOrientation('x+');
						}
					} else {
						sizeZ = sizeY + 1;
						if (prefabOrigin[1] > position[1]) {
							props.onSetOrientation('y-');
						} else {
							props.onSetOrientation('y+');
						}
					}
				}
				const size = [sizeX, sizeY, sizeZ];

				setPrefabSize(size);
				setPrefabPosition([
					Math.min(position[0], prefabOrigin[0]),
					Math.min(position[1], prefabOrigin[1]),
					props.zPlane
				]);
			}
		}
	}, [mousePosition]);
	useEffect(() => {
		window.addEventListener('mousemove', updateMousePosition);
		window.addEventListener('keydown', keydown);
		return () => {
			window.removeEventListener('mousemove', updateMousePosition);
			window.removeEventListener('keydown', keydown);
		};
	}, []);

	return (
		<BluePrint
			{...props} type={props.adding} prefab={true} position={prefabPosition} size={prefabSize}
			onPointerDown={onPointerDown}/>
	);
}

const mapStateToProps = store => ({
	adding: selectEditAdding(store),
	auth: selectAppAuthentication(store),
	state: selectEditState(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	addFeature,
	setEditing,
	setEditingState
})(Prefab);
