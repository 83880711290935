import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Avatar, Box, FormControl, FormControlLabel, IconButton, InputLabel, ListItem, ListItemAvatar, ListItemText,
	MenuItem, Select, Slider as MuiSlider, Switch, Typography} from '@material-ui/core';
import {Delete, Edit, Queue, RemoveCircle, Visibility} from '@material-ui/icons';
import {selectFirebaseCampaigns, selectFirebaseFriendCharacters, selectFirebaseScenes, selectFirebaseThemes}
	from '../../../selectors/state/firebase';
import Panel from './Panel';
import ContentCover from '../items/ContentCover';
import {DefaultImage} from '../items/SceneItem';
import {deleteScene, removeCharacterFromScene, updateScene} from '../../../actions/sceneActions';
import {addSceneNote, removeSceneNote, updateSceneNote} from '../../../actions/noteActions';
import {setViewMode} from '../../../actions/appActions';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function FriendCharacter(props) {
	return <ListItem key={props.characterKey} alignItems="flex-start">
		<ListItemAvatar>
			<Avatar alt={props.character.name} src={props.character.image || DefaultImage}/>
		</ListItemAvatar>
		<ListItemText primary={props.character.name} secondary={
			props.character.added && new Date(props.character.added).toLocaleDateString()
		}/>
		<IconButton
			data-character={props.characterKey} aria-label="remove character" onClick={props.onClick}>
			<RemoveCircle/>
		</IconButton>
	</ListItem>;
}

function SelectFrom(props) {
	return (
		<FormControl fullWidth>
			<InputLabel id='themeLabel'>{props.label}</InputLabel>
			<Select labelId='themeLabel' autoWidth onChange={props.onChange} value={props.value}>
				{Object.keys(props.options).map(optionsKey => (
					<MenuItem key={optionsKey} value={optionsKey}>
						{props.getOption ? props.getOption(props.options[optionsKey]) : props.options[optionsKey]}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

function Slider(props) {
	return (
		<Box p={1} flexGrow={1}>
			<Typography id='azimuth-slider' variant='caption' gutterBottom>{props.label}</Typography>
			<MuiSlider
				aria-labelledby='azimuth-slider' min={props.min} max={props.max} step={props.step} marks
				value={props.value} onChange={props.onChange}/>
		</Box>
	);
}
function ScenePanel(props) {
	if (props.view !== 'scene') return null;

	const scene = props.scenes[props.data.scene];
	const campaign = props.campaigns[props.data.campaign];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleSetImage = image => {
		props.updateScene(props.data.scene, 'image', image);
	};
	const handleSetName = value => {
		props.updateScene(props.data.scene, 'name', value);
	};
	const handleSetDescription = value => {
		props.updateScene(props.data.scene, 'description', value);
	};
	const handleThemeChange = event => {
		props.updateScene(props.data.scene, 'theme', event.target.value);
	};
	const handleArchiveScene = () => {
		props.deleteScene(props.data.scene);
		props.setView('scenario', {campaign: props.data.campaign, scenario: props.data.scenario});
	};
	const handleEditScene = () => {
		props.setViewMode('edit', {
			campaignKey: props.data.campaign,
			scenarioKey: props.data.scenario,
			sceneKey: props.data.scene
		});
	};
	const handleViewScene = () => {
		props.setViewMode('referee', {
			campaignKey: props.data.campaign,
			scenarioKey: props.data.scenario,
			sceneKey: props.data.scene
		});
	};
	const handleClickRemoveCharacter = event => {
		props.removeCharacterFromScene(props.data.scene, event.currentTarget.dataset.character);
	};
	const handleExternalChanged = event => {
		props.updateScene(props.data.scene, 'external', event.target.checked);
	};
	const handleSkyBoxChange = event => {
		props.updateScene(props.data.scene, 'skyBox', event.target.value);
	};
	const handleElevationChange = (event, newValue) => {
		props.updateScene(props.data.scene, 'elevation', newValue);
	};
	const handleAzimuthChange = (event, newValue) => {
		props.updateScene(props.data.scene, 'azimuth', newValue);
	};
	const handleFogChange = (event, newValue) => {
		props.updateScene(props.data.scene, 'fog', newValue);
	};
	const handleSetTab = tab => setSelectedTab(tab);
	const handleAddNote = () => props.addSceneNote(props.data.scene);
	const handleUpdateNote = (noteKey, update) => props.updateSceneNote(props.data.scene, noteKey, update);
	const handleRemoveNote = noteKey => props.removeSceneNote(props.data.scene, noteKey);
	const tabs = {
		Notes: {
			content: <Notes
				key='notes' notes={scene.notes} owner={scene.user} players={campaign.players}
				updateNote={handleUpdateNote} removeNote={handleRemoveNote}/>,
			tools: [<IconButton key='addNote' title='Add note' color='inherit' onClick={handleAddNote}>
				<Queue/></IconButton>]
		},
		Characters: {
			content: Object.keys(scene.characters || {}).map(characterKey =>
				(<FriendCharacter
					key={characterKey} characterKey={characterKey} character={props.friendCharacters[characterKey]}
					onClick={handleClickRemoveCharacter}/>)
			),
			tools: []
		}
	};
	const commonTools = [
		<IconButton key='edit' color='inherit' onClick={handleEditScene}><Edit/></IconButton>,
		<IconButton key='referee' color='inherit' onClick={handleViewScene}><Visibility/></IconButton>,
		<IconButton key='archive' color='inherit' onClick={handleArchiveScene}><Delete/></IconButton>
	];

	useEffect(() => {
		props.setTitle(scene.name, ['scenario', 'referee']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCover
				image={scene.image || DefaultImage} setImage={handleSetImage}
				name={scene.name} setName={handleSetName}
				description={scene.description} setDescription={handleSetDescription}
				toolbar={[
					<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>,
					...tabs[selectedTab].tools, ...commonTools
				]}>
				<SelectFrom
					options={props.themes} value={scene.theme || ''} label='Theme'
					getOption={option => option.name} onChange={handleThemeChange}/>
				<FormControlLabel
					key='admin' label='External' title='Switch between underground and outside terrain' control={
						<Switch checked={scene.external === true} onChange={handleExternalChanged} name={'external'}/>
					}/>
				{scene.external &&
					<Box>
						<SelectFrom
							options={{none: 'Clear skies', sunny: 'Sunny day'}} value={scene.skyBox || 'none'}
							label='Sky Box' onChange={handleSkyBoxChange}/>
						<Box display='flex'>
							<Box p={1} flexGrow={1}>
								<Typography id='elevation-slider' variant='caption' gutterBottom>Sun elevation</Typography>
								<Slider
									aria-labelledby='elevation-slider' min={0} max={90} step={10} marks
									value={scene.elevation || 0} onChange={handleElevationChange} label='Sun elevation'/>
							</Box>
							<Box p={1} flexGrow={1}>
								<Typography id='azimuth-slider' variant='caption' gutterBottom>Sun azimuth</Typography>
								<Slider
									aria-labelledby='azimuth-slider' min={0} max={360} step={10} marks
									value={scene.azimuth || 0} onChange={handleAzimuthChange} label='Sun azimuth'/>
							</Box>
						</Box>
						<Box display='flex'>
							<Slider
								label='Fog' min={0} max={0.1} step={0.005} value={scene.fog || 0}
								onChange={handleFogChange}/>
						</Box>
					</Box>}
			</ContentCover>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	campaigns: selectFirebaseCampaigns(store),
	friendCharacters: selectFirebaseFriendCharacters(store),
	scenes: selectFirebaseScenes(store),
	themes: selectFirebaseThemes(store)
});

export default connect(mapStateToProps, {
	addSceneNote,
	deleteScene,
	removeCharacterFromScene, 
	setViewMode,
	removeSceneNote,
	updateSceneNote,
	updateScene
})(ScenePanel);
