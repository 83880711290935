import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {selectFirebaseCampaigns, selectFirebaseScenarios, selectFirebaseScenes}
	from '../../../selectors/state/firebase';
import Panel from './Panel';
import ContentCover from '../items/ContentCover';
import {DefaultImage} from '../items/ScenarioItem';
import SceneItem from '../items/SceneItem';
import {addScenarioNote, removeScenarioNote, updateScenarioNote} from '../../../actions/noteActions';
import {deleteScenario, updateScenario} from '../../../actions/scenarioActions';
import {createScene} from '../../../actions/sceneActions';
import {IconButton} from '@material-ui/core';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Delete, Queue} from '@material-ui/icons';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function ScenarioPanel(props) {
	if (props.view !== 'scenario') return null;

	const scenario = props.scenarios[props.data.scenario];
	const campaign = props.campaigns[props.data.campaign];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleSetImage = image => {
		props.updateScenario(props.data.scenario, 'image', image);
	};
	const handleSetName = value => {
		props.updateScenario(props.data.scenario, 'name', value);
	};
	const handleSetDescription = value => {
		props.updateScenario(props.data.scenario, 'description', value);
	};
	const handleSceneClick = event => {
		props.setView('scene', {
			campaign: props.data.campaign,
			scenario: props.data.scenario,
			scene: event.currentTarget.dataset.target
		});
	};
	const handleAddScene = () => {
		props.createScene(props.data.campaign, props.data.scenario, 'New Scene', props.auth.key);
	};
	const handleArchiveScenario = () => {
		props.deleteScenario(props.data.scenario);
		props.setView('campaign', props.data.campaign);
	};
	const handleAddNote = () => props.addScenarioNote(props.data.scenario);
	const handleUpdateNote = (noteKey, update) => props.updateScenarioNote(props.data.scenario, noteKey, update);
	const handleRemoveNote = noteKey => props.removeScenarioNote(props.data.scenario, noteKey);
	const handleSetTab = tab => setSelectedTab(tab);
	const scenes = Object.keys(props.scenes || {}).filter(sceneKey => {
		return props.scenes[sceneKey].scenario === props.data.scenario;
	})
		.map(sceneKey => ({ key: sceneKey, scene: props.scenes[sceneKey]}))
		.sort((lhs, rhs) => 
			lhs.scene.order - rhs.scene.order
		);
	const tabs = {
		Notes: {
			content: <Notes
				key='notes' notes={scenario.notes} owner={scenario.user} players={campaign.players}
				updateNote={handleUpdateNote} removeNote={handleRemoveNote}/>,
			tools: [<IconButton key='addNote' title='Add note' color='inherit' onClick={handleAddNote}>
				<Queue/></IconButton>]
		},
		Scenes: {
			content:  scenes.map(item => <SceneItem
				key={item.key} sceneKey={item.key} scenarioKey={props.data.scenario} campaignKey={props.data.campaign}
				scene={item.scene} scenes={scenes} onClick={handleSceneClick}/>),
			tools: [<IconButton key='addScenario' title='Add scene' color='inherit' onClick={handleAddScene}>
				<Queue/></IconButton>]
		}
	};
	const commonTools = [
		<IconButton key='archive' title='Delete scenario' color='inherit' onClick={handleArchiveScenario} disabled={
			Object.values(props.scenes || {}).some(scene => scene.scenario === props.data.scenario)
		}><Delete/></IconButton>
	];

	useEffect(() => {
		props.setTitle(scenario.name, ['campaign', 'referee']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCover
				image={scenario.image || DefaultImage} setImage={handleSetImage}
				name={scenario.name} setName={handleSetName}
				description={scenario.description} setDescription={handleSetDescription}
				toolbar={[
					<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>,
					...tabs[selectedTab].tools,
					...commonTools
				]}
			/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store),
	scenarios: selectFirebaseScenarios(store),
	scenes: selectFirebaseScenes(store)
});

export default connect(mapStateToProps, {
	addScenarioNote,
	createScene,
	deleteScenario,
	removeScenarioNote,
	updateScenarioNote,
	updateScenario
})(ScenarioPanel);
