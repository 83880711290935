import Monster from '../play/Monster';
import React, {Suspense} from 'react';
import MonsterController from './MonsterController';
import {connect} from 'react-redux';
import {setSelectedMonster} from '../../actions/editActions';
import {selectSelectedMonster} from '../../selectors/state/edit';
import srd from '../../data/srd/srd5eBestiary.json';
import UserAssignments from './UserAssignments';
import {selectFirebaseAssignments, selectFirebaseModels, selectFirebaseUserModels}
	from '../../selectors/state/firebase';
import {selectMonsterRules} from '../../selectors/state/jsonSelector';
import {selectAppAuthentication, selectAppUser} from '../../selectors/state/app';

function Monsters(props) {
	const onMonsterSelected = (event, monsterKey) => {
		event.stopPropagation();
		if (monsterKey === props.selectedMonster) {
			props.setSelectedMonster(undefined);
		} else {
			props.setSelectedMonster(monsterKey);
		}
	};
	const userAssignments = UserAssignments(props.auth.key, props.user, props.assignments);

	return Object.keys(props.details || {})
		.map(detailsKey => {
			const details = props.details[detailsKey];
			const monster = srd.monsters[details.monster];

			return (
				<MonsterController
					key={detailsKey} position={details.position} rotation={details.rotation}>
					<Suspense fallback={null}>
						<Monster
							assignments={userAssignments} selected={props.selectedMonster === detailsKey}
							onPointerDown={event => onMonsterSelected(event, detailsKey)}
							onSetModel={props.onSetModel}
							animation={details.animation} monster={monster} monsterKey={details.monster}/>
					</Suspense>
				</MonsterController>
			);
		});
}
const mapStateToProps = store => ({
	assignments: selectFirebaseAssignments(store),
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	monsters: selectMonsterRules(store),
	selectedMonster: selectSelectedMonster(store),
	user: selectAppUser(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {setSelectedMonster})(Monsters);
