import React from 'react';

export default function SelectedBox(props) {
	return (
		<group>
			<mesh position={[props.position[0], props.position[1], props.position[2] - 0.5]}>
				<boxBufferGeometry args={[1, 1, 0.1]} />
				<meshBasicMaterial attach="material" transparent={true} opacity={props.opacity || 0.3}/>
			</mesh>
		</group>
	);
}
