import React from 'react';
import {Box, CardActionArea, CardContent, IconButton, makeStyles, Typography} from '@material-ui/core';
import PanelItem from './PanelItem';
import CardImage from '../CardImage';
import Scrollbars from '../Scrollbars';
import {Delete, Favorite, FavoriteBorder} from '@material-ui/icons';
import {connect} from 'react-redux';
import {setUserFavourite, removeUserFavourite} from '../../../actions/firebaseActions';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {selectFirebaseUserFavourites} from '../../../selectors/state/firebase';

const useStyles = makeStyles(() => ({
	actionArea: { display: 'flex', flexDirection: 'column' },
	buttons: { display: 'flex', justifyContent: 'flex-end' },
	content: { flexGrow: 1, paddingTop: 4, paddingBottom: 8, paddingLeft: 8, paddingRight: 8, height: 120 },
	description: { flexGrow: 1, height: 40, width: '100%' },
	heading: { flexGrow: 0 },
	image: { flexGrow: 0},
	title: { justifyContent: 'space-between', width: '100%'}
}));

function ImageLeftItem(props) {
	const classes = useStyles();
	const date = props.date && `${props.datePrefix || ''} ${new Date(props.date).toLocaleDateString()}`;
	const handleFavouriteClick = () => {
		if (props.favourites && props.favourites[props.favourite.key]) {
			props.removeUserFavourite(props.auth.key, props.favourite.key);
		} else {
			props.setUserFavourite(props.auth.key, props.favourite.key, props.favourite.view, props.title, props.image,
				props.favourite.data);
		}
	};

	return (
		<PanelItem>
			<Box display='flex'>
				<CardImage format='tile' image={props.image}/>
				<CardContent className={classes.content}>
					<CardActionArea className={classes.actionArea} data-target={props.target} onClick={props.onClick}>
						<Box display='flex' className={classes.title}>
							<Typography variant='h5' component='span'>{props.title}</Typography>
							<Typography variant='caption' color='textSecondary'>{date}</Typography>
						</Box>
						<Box className={classes.description}>
							<Scrollbars>
								<Typography variant='body2' color='textSecondary'>{props.description}</Typography>
							</Scrollbars>
						</Box>
						{props.children}
					</CardActionArea>
					<div className={classes.buttons}>
						{props.toolbar}
						{props.favourite &&
						<IconButton onClick={handleFavouriteClick}>
							{props.favourites[props.favourite.key] ? <Favorite/> : <FavoriteBorder/>}
						</IconButton>}
						{props.delete &&
						<IconButton onClick={props.delete}>
							<Delete/>
						</IconButton>
						}
					</div>
				</CardContent>
			</Box>
		</PanelItem>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	favourites: selectFirebaseUserFavourites(store) || {}
});

export default connect(mapStateToProps, {
	removeUserFavourite,
	setUserFavourite
})(ImageLeftItem);
