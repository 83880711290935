import React from 'react';
import {AppBar, Card, CardContent, makeStyles, Toolbar} from '@material-ui/core';
import {selectAppAdmin} from '../../../selectors/state/app';
import {connect} from 'react-redux';

const useStyles = makeStyles(() => ({
	content: { flex: '1 0 auto'},
	card: { marginBottom: 16 },
	toolbar: { justifyContent: 'flex-end'}
}));

function Admin(props) {
	if (! props.admin) {
		return null;
	}

	const classes = useStyles();

	return (
		<Card className={classes.card} variant='outlined'>
			{props.children &&
			<CardContent className={classes.content}>
				{props.children}
			</CardContent>
			}
			{props.toolbar &&
			<AppBar position='static'>
				<Toolbar className={classes.toolbar}>
					{Object.values(props.toolbar)}
				</Toolbar>
			</AppBar>
			}
		</Card>
	);
}
const mapStateToProps = store => ({
	admin: selectAppAdmin(store)
});

export default connect(mapStateToProps, {
})(Admin);
