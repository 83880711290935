import { createReducer } from '.';
import {EDIT_SET_EDITING} from '../actions/editActions';

const updateStore = (appStore, action) => {
	return {
		...appStore,
		...action.data
	};
};

const initialState = {
	adding: '',
	state: 'waiting',
	selectedFeature: null,
	selectedBoxes: []
};

export default createReducer(initialState, {
	[EDIT_SET_EDITING]: updateStore
});
