import { createSelector } from 'reselect';

export const selectApp = createSelector(store => store, store => store.app);
export const selectAppAdmin = createSelector(selectApp, app => app.admin === true);
export const selectAppVersion = createSelector(selectApp, app => app.version);
export const selectViewMode = createSelector(selectApp, app => app.mode);
export const selectSelectedSceneKey = createSelector(selectViewMode, mode => mode.data.sceneKey);
export const selectAppScene = createSelector(selectApp, app => app.scene);
export const selectAppUser = createSelector(selectApp, app => app.user);
export const selectAppAuthentication = createSelector(selectApp, app => app.authentication);
export const selectHighlightState = createSelector(selectApp, app => app.highlightState);
export const selectHighlightTile = createSelector(selectApp, app => app.highlightTile);
export const selectScreenShot = createSelector(selectApp, app => app.screenShot);
