import React from 'react';
import Room from './Room';
import Corridor from './Corridor';
import Staircase, {FloorHeight as StaircaseFloorHeight} from './Staircase';
import {selectFirebaseFeatures} from '../../selectors/state/firebase';
import {connect} from 'react-redux';
import WhyDidYouUpdate from '../../utilities/WhyDidYouUpdate';

function Features(props) {
	const features = [];
	const components = {
		'room': props => <Room {...props}/>,
		'corridor': props => <Corridor {...props}/>,
		'staircase': props => <Staircase {...props}/>
	};

	Object.keys(props.features || {})
		.filter(key => components[props.features[key].type] !== undefined)
		.forEach((key) => {
			const feature = props.features[key];

			features.push(components[feature.type]({
				theme: props.theme,
				...feature,
				key,
				label: key,
				mode: 'play',
				...props
			}));
		});
	return (
		<>
			<WhyDidYouUpdate {...props}/>
			{features}
		</>
	);
}

export function FloorHeight(feature, x, y) {
	if (feature.type === 'staircase') {
		return StaircaseFloorHeight(feature, x, y) + 0.5;
	} else {
		return 0;
	}
}

const mapStateToProps = store => ({
	features: selectFirebaseFeatures(store)
});

export default connect(mapStateToProps, {})(Features);
