import {FIREBASE_PUSH_CHILD, FIREBASE_REMOVE, FIREBASE_SET, FIREBASE_TRACK_USER_SCENES} from './firebaseActions';

export function addCharacterToScene(scene, characterKey, character, animation, position, rotation) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${scene}/characters/${characterKey}`,
		value: {
			added: Date.now(),
			animation,
			position,
			rotation,
			user: character.user
		}
	};
}

export function addMonsterToScene(scene, monster, animation, position, rotation) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: `scenes/${scene}/monsters`,
		child: {
			animation,
			monster,
			position,
			rotation
		}
	};
}

export function createScene(campaign, scenario, name, user) {
	const created = Date.now();

	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'scenes',
		child: {
			campaign,
			created,
			name,
			scenario,
			user
		}
	};
}

export function deleteScene(sceneKey) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenes/${sceneKey}`
	};
}

export function removeCharacterFromScene(scene, character) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenes/${scene}/characters/${character}`
	};
}

export function removeMonsterFromScene(sceneKey, monsterKey) {
	return {
		type: FIREBASE_REMOVE,
		path: `scenes/${sceneKey}/monsters/${monsterKey}`
	};
}

export function trackUserScenes(user) {
	return {
		type: FIREBASE_TRACK_USER_SCENES,
		user
	};
}

export function updateScene(sceneKey, member, value) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/${member}`,
		value
	};
}
