import React, {useState} from 'react';
import {Search} from '@material-ui/icons';
import {fade, InputBase} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
	search: {
		position: 'relative',
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		'&:hover': { backgroundColor: fade(theme.palette.common.white, 0.25) },
		flexGrow: 1
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: '100%',
		position: 'absolute',
		pointerEvents: 'none',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	inputRoot: {color: 'inherit',},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`
	}
}));

export default function SearchTool(props) {
	const delayTime = 300;
	const classes = useStyles();
	const [value, setValue] = useState('');
	const [delay, setDelay] = useState();
	const handleChange = event => {
		const deferEvent = () => setTimeout(() => {
			const interval = Date.now() - delay;

			if (interval >=  delayTime) {
				props.onChange(event);
			} else {
				deferEvent();
			}
		}, delayTime);

		deferEvent();
		setValue(event.target.value);
		setDelay(Date.now() + delayTime);
	};

	return (
		<div key='search' className={classes.search}>
			<div className={classes.searchIcon}>
				<Search/>
			</div>
			<InputBase
				placeholder={props.placeholder} fullWidth
				classes={{root: classes.inputRoot, input: classes.inputInput}}
				inputProps={{'aria-label': 'search'}} onChange={handleChange} value={value}/>
		</div>
	);
}
