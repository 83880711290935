import React from 'react';
import {connect} from 'react-redux';
import {selectFirebaseScenarios} from '../../../selectors/state/firebase';
import {selectAppAuthentication} from '../../../selectors/state/app';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

const toolbar = [];

export const DefaultImage = `${Images}/ui/campaign.jpg`;

function CampaignItem(props) {
	return (
		<ImageLeftItem
			image={props.campaign.image || DefaultImage} target={props.campaignKey} onClick={props.onClick}
			toolbar={toolbar} title={props.campaign.name} description={props.campaign.description}
			date={props.campaign.created} favourite={{
				key: props.campaignKey,
				view: props.auth.key === props.campaign.user ? 'campaign' : 'campaignPlayer',
				data: props.campaignKey
			}}/>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	scenarios: selectFirebaseScenarios(store)
});

export default connect(mapStateToProps, {})(CampaignItem);
