import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {CircularProgress, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText,
	Tab, Tabs, TextField} from '@material-ui/core';
import firebase from '@firebase/app';
import '@firebase/app-check';
import '@firebase/functions';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectFirebaseFriends, selectFirebaseInvites, selectFirebaseUserPublic}
	from '../../../selectors/state/firebase';
import {selectAppAdmin, selectAppAuthentication} from '../../../selectors/state/app';
import {addInvite, updateUserPublic} from '../../../actions/firebaseActions';
import FriendItem, {DefaultImage} from '../items/FriendItem';
import SearchTool from '../items/SearchTool';
import {CancelScheduleSend, Drafts, Mail, PersonAdd, Send} from '@material-ui/icons';
import {OnFirebaseException} from '../../../middleware/firebaseService';
import UserListItem from '../lists/UserListItem';

const searchTime = 1000;

function UserInvite(props) {
	const [email, setEmail] = useState('');
	const [sending, setSending] = useState(false);
	const handleClickCancelInvite = () => {
		const cancelInvite = firebase.functions().httpsCallable('cancelInvite');

		setSending(true);
		cancelInvite({inviteKey: props.inviteKey}).catch(OnFirebaseException).then(response => {
			setSending(false);
			console.log(response);
		});
	};
	const handleClickSendInvite = () => {
		const inviteUser = firebase.functions().httpsCallable('inviteUser');

		setSending(true);
		inviteUser({inviteKey: props.inviteKey, email}).catch(OnFirebaseException).then(response => {
			setSending(false);
			console.log(response);
		});
	};
	const handleEmailChange = (event) => {
		setEmail(event.currentTarget.value);
	};

	if (props.userInvite.email) {
		return (
			<ListItem>
				<ListItemIcon><Mail/></ListItemIcon>
				<ListItemText
					primary={props.userInvite.email}
					secondary={new Date(props.userInvite.invited).toLocaleDateString()}/>
				<ListItemSecondaryAction>
					<IconButton
						color='primary' disabled={sending} title='Cancel invite' edge="end" aria-label="delete"
						onClick={handleClickCancelInvite}>
						<CancelScheduleSend/>
						{sending && <CircularProgress style={{position: 'absolute'}} size={36}/>}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		);
	} else if (props.userInvite.invitedUser) {
		return (
			<UserListItem
				user={props.userInvite.invitedUser}
				secondary={`Accepted ${new Date(props.userInvite.accepted).toLocaleDateString()}`}/>
		);
	} else {
		return (
			<ListItem>
				<ListItemIcon><Drafts/></ListItemIcon>
				<ListItemText>
					<TextField fullWidth label='Email' value={email} onChange={handleEmailChange}/>
				</ListItemText>
				<ListItemSecondaryAction>
					<IconButton
						color='primary' disabled={sending} title='Invite friend' edge="end" aria-label="delete"
						onClick={handleClickSendInvite}>
						<Send/>
						{sending && <CircularProgress style={{position: 'absolute'}} size={36}/>}
					</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		);
	}
}

function SocialPanel(props) {
	if (props.view !== 'social') return null;

	const [search, setSearch] = useState('');
	const [searchResults, setSearchResults] = useState({});
	const handleSetImage = event => {
		props.updateUserPublic(props.auth.key, 'image', event.currentTarget.value);
	};
	const handleSetNickname = event => {
		const nickname = event.currentTarget.value || '';

		props.updateUserPublic(props.auth.key, 'nickname', nickname);
		props.updateUserPublic(props.auth.key, 'nickname_sort', nickname.toLowerCase());
	};
	const handleSetBio = event => {
		props.updateUserPublic(props.auth.key, 'bio', event.currentTarget.value);
	};
	const handleSearchChange = event => {
		const searchTerm = event.target.value;
		const updateSearch = () => {
			setSearch(searchTerm);
		};

		if (searchTerm !== '') {
			setTimeout(updateSearch, searchTime);
		} else {
			setSearch(searchTerm);
		}
	};
	const handleClickFriend = event => {
		props.setView('friend', event.currentTarget.dataset.target);
	};
	const handleClickUser = event => {
		props.setView('user', event.currentTarget.dataset.target);
	};
	const [tab, setTab] = useState(0);
	const handleTabChange = (event, newTab) => {
		setTab(newTab);
	};
	const tabs = <Tabs key='tabs' value={tab} onChange={handleTabChange} style={{flexGrow: 2}}>
		<Tab label='Friends'/>
		<Tab label='Invites'/>
	</Tabs>;
	const content = [
		search === '' ?
			Object.keys(props.friends || {}).map(friendKey => {
				const friend = props.friends[friendKey];

				return (
					<FriendItem key={friendKey} friendKey={friendKey} friend={friend} onClick={handleClickFriend}/>
				);
			}) :
			Object.keys(searchResults || {}).map(friendKey => {
				const friend = searchResults[friendKey];

				return (
					<FriendItem key={friendKey} friendKey={friendKey} friend={friend} onClick={handleClickUser}>
						{friend.nickname}
					</FriendItem>
				);
			}),
		<List key='invites'>
			{Object.keys(props.invites || {}).map(inviteKey => {
				const userInvite = props.invites[inviteKey];

				return <UserInvite key={inviteKey} inviteKey={inviteKey} userInvite={userInvite}/>;
			})}
		</List>
	];
	const handleClickAddInvite = () => {
		props.addInvite(props.auth.key);
	};
	const tabTools = [
		[<SearchTool key='search' placeholder='Search for friends…' onChange={handleSearchChange}/>],
		[props.admin &&
			<IconButton key='addInvite' color='secondary' onClick={handleClickAddInvite}><PersonAdd/></IconButton>]
	];
	const userPublic = props.userPublic || {};

	useEffect(() => {
		if (search !== '') {
			return firebase.database().ref('usersPublic').orderByChild('nickname_sort')
				.startAt(search.toLowerCase()).endAt(search.toLowerCase() + 'z').limitToFirst(20)
				.once('value', snapshot => {
					setSearchResults(snapshot.val());
				});
		}
	}, [search]);
	useEffect(() => {
		props.setTitle('Social', 'home');
	}, []);
	return (
		<Panel visible={true}>
			<Cover
				image={userPublic.image || DefaultImage} setImage={handleSetImage}
				name={userPublic.nickname} setName={handleSetNickname}
				toolbar={[tabs, ...tabTools[tab]]}
			>
				<TextField
					fullWidth onBlur={handleSetNickname} defaultValue={userPublic.nickname} label='Nickname'
					margin='dense'>{userPublic.nickname}</TextField>
				<TextField
					onBlur={handleSetBio} defaultValue={userPublic.bio} label='Bio'
					margin='dense' fullWidth multiline rows={4}>{userPublic.bio}</TextField>
			</Cover>
			{content[tab]}
		</Panel>
	);
}

const mapStateToProps = store => ({
	admin: selectAppAdmin(store),
	auth: selectAppAuthentication(store),
	friends: selectFirebaseFriends(store),
	invites: selectFirebaseInvites(store),
	userPublic: selectFirebaseUserPublic(store)
});

export default connect(mapStateToProps, {
	addInvite,
	updateUserPublic
})(SocialPanel);
