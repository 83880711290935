import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/character.jpg`;

export default function CharacterItem(props) {
	return (
		<ImageLeftItem
			image={props.character.image || DefaultImage} target={props.characterKey} onClick={props.onClick}
			title={props.character.name} description={props.character.bio}
			favourite={{key: props.characterKey, data: { character: props.characterKey}, view: 'character'}}/>
	);
}
