import React, {} from 'react';
import {BufferGeometry, Vector3} from 'three';

const size = 100;
const horizontalGeometry = new BufferGeometry().setFromPoints((size => {
	const points = [];
	let shuttle = 0;
	let direction = 1;

	for (let x = 0; x <= size; ++x) {
		points.push(new Vector3(x, shuttle, 0));
		shuttle += direction * size;
		direction *= -1;
		points.push(new Vector3(x, shuttle, 0));
	}
	points.push(new Vector3(0, shuttle, 0));
	points.push(new Vector3(0, 0, 0));
	for (let y = 0; y <= size; ++y) {
		points.push(new Vector3(shuttle, y, 0));
		shuttle += direction * size;
		direction *= -1;
		points.push(new Vector3(shuttle, y, 0));
	}
	return points;
})(size));
export default function EditPlane(props) {
	return (
		<group position={[-size / 2 - 0.5, -size / 2 - 0.5, props.zPlane - 0.5]}>
			<line geometry={horizontalGeometry}>
				<lineBasicMaterial attach='material' color='white' linewidth={10}/>
			</line>
		</group>
	);
}