import { createReducer } from '.';
import {APP_SET_MODE, APP_SET} from '../actions/appActions';

function getVersion() {
	const [major,
		minor,
		updates] = (process.env.REACT_APP_VERSION || '?.?.?').split('.');
	return {
		major,
		minor,
		updates
	};
}

const updateStore = (appStore, action) => {
	return {
		...appStore,
		...action.data
	};
};

const updateMode = (store, action) => {
	return {
		...store,
		mode: {
			name: action.data.name,
			data: {
				...store.mode.data,
				...action.data.data
			}
		}
	};
};
const initialState = {
	version: getVersion(),
	mode: { name: 'campaigns' },
	screenShot: { state: 'default' }
};

export default createReducer(initialState, {
	[APP_SET_MODE]: updateMode,
	[APP_SET]: updateStore
});
