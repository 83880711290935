import React, {useEffect, useState} from 'react';
import {Avatar, IconButton, ListItem, ListItemAvatar, ListItemText} from '@material-ui/core';
import firebase from '@firebase/app';
import {selectFirebaseFriends} from '../../../selectors/state/firebase';
import {connect} from 'react-redux';
import {DefaultImage} from './FriendItem';
import PanelItem from './PanelItem';
import {AddCircle, Delete} from '@material-ui/icons';
import {acceptInvite, deleteMessage, removeFriend} from '../../../actions/firebaseActions';
import {acceptCampaignInvite} from '../../../actions/inviteActions';
import {selectAppAuthentication} from '../../../selectors/state/app';

function MessageItem(props) {
	const [content, setContent] = useState(props.message.content);
	const [user, setUser] = useState();
	const handleClickAcceptFriendInvite = () => {
		props.acceptInvite(props.authentication.key, props.message.user);
		props.deleteMessage(props.authentication.key, props.messageKey);
	};
	const handleClickAcceptCampaignInvite = () => {
		props.acceptCampaignInvite(props.authentication.key, props.message.campaign, props.message.user);
		props.deleteMessage(props.authentication.key, props.messageKey);
	};
	const handleClickDeleteMessage = () => {
		props.removeFriend(props.authentication.key, props.message.user);
		props.deleteMessage(props.authentication.key, props.messageKey);
	};

	useEffect(() => {
		if (props.friends[props.message.user]) {
			setUser(props.friends[props.message.user]);
		} else {
			return firebase.database().ref(`usersPublic/${props.message.user}`).once('value', snapshot => {
				setUser(snapshot.val());
			});
		}
	}, [props.message.user]);
	useEffect(() => {
		if (user) {
			if (props.message.type === 'friend') {
				setContent(`Friend request from ${user.nickname}`);
			} else if (props.message.type === 'invite') {
				setContent(`Invite to join ${props.message.name} from ${user.nickname}`);
			} else {
				setContent(user.body);
			}
		}
	}, [user]);
	if (! user) return null;
	return (
		<PanelItem>
			<ListItem alignItems="flex-start">
				<ListItemAvatar>
					<Avatar alt={user.nickname} src={user.image || DefaultImage}/>
				</ListItemAvatar>
				<ListItemText primary={user.nickname} secondary={content}/>
				{props.message.type === 'friend' &&
					<IconButton aria-label="add friend" onClick={handleClickAcceptFriendInvite}>
						<AddCircle/>
					</IconButton>
				}
				{props.message.type === 'invite' &&
					<IconButton aria-label="accept invite" onClick={handleClickAcceptCampaignInvite}>
						<AddCircle/>
					</IconButton>
				}
				<IconButton edge="end" aria-label="delete message" onClick={handleClickDeleteMessage}>
					<Delete/>
				</IconButton>
			</ListItem>
		</PanelItem>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	friends: selectFirebaseFriends(store)
});

export default connect(mapStateToProps, {
	acceptInvite,
	acceptCampaignInvite,
	deleteMessage,
	removeFriend
})(MessageItem);
