import React, {useEffect, useState} from 'react';
import {selectFirebaseCharacters, selectFirebaseModels, selectFirebasePlayerModels, selectFirebaseUserModels}
	from '../../selectors/state/firebase';
import {connect} from 'react-redux';
import AnimatedModel from './AnimatedModel';

function Character(props) {
	const [model, setModel] = useState();
	const character = props.characters && props.characters[props.character];

	useEffect(() => {
		if (! props.characters || ! props.characters[props.character] ||
			! props.characters[props.character].model) return null;

		const modelKey = props.characters[props.character].model;
		const definition = props.userModels[modelKey] || props.playerModels[modelKey];

		if (props.models[definition?.model]) {
			const model = {
				...props.models[definition.model],
				...definition
			};

			props.onSetModel && props.onSetModel(model);
			setModel(model);
		}
	}, [props.models, props.characters]);

	if (! model || ! character) return null;
	return (
		<AnimatedModel
			onPointerDown={props.onPointerDown}
			model={model}
			animation={props.animation}
			name={character.name}/>
	);
}

const mapStateToProps = store => ({
	characters: selectFirebaseCharacters(store),
	models: selectFirebaseModels(store),
	playerModels: selectFirebasePlayerModels(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {
})(Character);

