import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
	selectFirebaseCampaigns,
	selectFirebaseScenarios,
	selectFirebaseScenes
} from '../../../selectors/state/firebase';
import Panel from './Panel';
import {DefaultImage} from '../items/ScenarioItem';
import SceneItem from '../items/SceneItem';
import {selectAppAuthentication} from '../../../selectors/state/app';
import ContentCoverStatic from '../items/ContentCoverStatic';
import Notes from '../items/Notes';
import ToolbarTabs from '../items/ToolbarTabs';

function ScenarioPlayerPanel(props) {
	if (props.view !== 'scenarioPlayer') return null;

	const scenario = props.scenarios[props.data.scenario];
	const campaign = props.campaigns[props.data.campaign];
	const [selectedTab, setSelectedTab] = useState('Notes');
	const handleSetTab = tab => setSelectedTab(tab);
	const handleSceneClick = event => {
		props.setView('scenePlayer', {
			campaign: props.data.campaign,
			scenario: props.data.scenario,
			scene: event.currentTarget.dataset.target
		});
	};
	const scenes = Object.keys(props.scenes || {}).filter(sceneKey => {
		return props.scenes[sceneKey].scenario === props.data.scenario;
	})
		.map(sceneKey => ({ key: sceneKey, scene: props.scenes[sceneKey]}))
		.sort((lhs, rhs) =>
			lhs.scene.order - rhs.scene.order
		);
	const tabs = {
		Notes: {
			content: <Notes key='notes' notes={scenario.notes} owner={scenario.user} players={campaign.players}/>,
			tools: []
		},
		Scenes: {
			content:  scenes.map(item => <SceneItem
				key={item.key} sceneKey={item.key} scenarioKey={props.data.scenario} campaignKey={props.data.campaign}
				scene={item.scene} scenes={scenes} onClick={handleSceneClick}/>),
			tools: []
		}
	};

	useEffect(() => {
		props.setTitle(scenario.name, ['campaignPlayer', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<ContentCoverStatic
				image={scenario.image || DefaultImage} name={scenario.name} description={scenario.description}
				toolbar={[
					<ToolbarTabs key='tabs' tabs={tabs} selected={selectedTab} setTab={handleSetTab}/>
				]}
			/>
			{tabs[selectedTab].content}
		</Panel>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	campaigns: selectFirebaseCampaigns(store),
	scenarios: selectFirebaseScenarios(store),
	scenes: selectFirebaseScenes(store)
});

export default connect(mapStateToProps, {
})(ScenarioPlayerPanel);
