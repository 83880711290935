import {
	FIREBASE_CREATE_CAMPAIGN,
	FIREBASE_DELETE_CAMPAIGN,
	FIREBASE_INVITE_CAMPAIGN_PLAYER, FIREBASE_REMOVE,
	FIREBASE_REMOVE_CAMPAIGN_PLAYER, FIREBASE_SET
} from './firebaseActions';

export function addCharacterToCampaign(campaign, player, character) {
	return {
		type: FIREBASE_SET,
		path: `campaigns/${campaign}/players/${player}/characters/${character}`,
		value: { timestamp: Date.now() }
	};
}

export function createCampaign(name) {
	return {
		type: FIREBASE_CREATE_CAMPAIGN,
		name
	};
}

export function deleteCampaign(key, campaign) {
	return {
		type: FIREBASE_DELETE_CAMPAIGN,
		key,
		campaign
	};
}

export function inviteCampaignPlayer(user, campaign, name, player) {
	return {
		type: FIREBASE_INVITE_CAMPAIGN_PLAYER,
		user,
		campaign,
		name,
		player
	};
}

export function removeCampaignPlayer(campaign, player, invite) {
	return {
		type: FIREBASE_REMOVE_CAMPAIGN_PLAYER,
		campaign,
		invite,
		player
	};
}

export function removeCharacterFromCampaign(campaign, player, character) {
	return {
		type: FIREBASE_REMOVE,
		path: `campaigns/${campaign}/players/${player}/characters/${character}`
	};
}
