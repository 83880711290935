import React, {} from 'react';
import {selectAppAuthentication, selectScreenShot} from '../../selectors/state/app';
import {connect} from 'react-redux';
import {clearScreenShot} from '../../actions/appActions';
import {Box, Card, CardActions, CardMedia, fade, IconButton, makeStyles} from '@material-ui/core';
import {CloudUpload, Delete} from '@material-ui/icons';
import {addUserImage} from '../../actions/firebaseActions';

const useStyles = makeStyles(() => ({
	box: {
		backgroundColor: fade('#000', 0.5),
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0
	},
	media: {
		height: 0,
		paddingTop: '56.25%'
	}
}));

function Review(props) {
	const classes = useStyles();
	const handleDiscardClick = () => {
		props.clearScreenShot();
	};
/*
	const handleDownloadClick = () => {
		const a = document.createElement('a');

		a.href = URL.createObjectURL(props.screenShot.blob);
		a.download = 'screenshot.jpg';
		a.click();
	};
*/
	const handleUploadClick = () => {
		props.addUserImage(props.auth.key, props.screenShot.blob,
			`${props.prefix}: ${new Date().toLocaleString('en-GB')}`);
		props.clearScreenShot();
	};

	if (props.screenShot.state !== 'taken') {
		return null;
	}
	return (
		<Box className={classes.box} p={2}>
			<Card display={'flex'}>
				<CardMedia className={classes.media} image={URL.createObjectURL(props.screenShot.blob)}/>
				<CardActions>
					<IconButton title='Upload' color='inherit' onClick={handleUploadClick}>
						<CloudUpload/>
					</IconButton>
					<IconButton title='Discard' color='inherit' onClick={handleDiscardClick}>
						<Delete/>
					</IconButton>
				</CardActions>
			</Card>
		</Box>
	);
}
const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
	screenShot: selectScreenShot(store)
});

export default connect(mapStateToProps, {
	clearScreenShot,
	addUserImage
})(Review);
