import React, {Suspense} from 'react';
import {connect} from 'react-redux';
import Tile from './Tile';
import {ExcludesBox, CeilingHeight, FloorHeight} from './Staircase';
import {selectHighlightState} from '../../selectors/state/app';
import Decoration from './Decoration';
import {selectSelectedDecoration} from '../../selectors/state/edit';
import {setSelectedDecoration} from '../../actions/editActions';
import WhyDidYouUpdate from '../../utilities/WhyDidYouUpdate';

function TiledRoom(props) {
	const onPointerDown = (event, tilePosition) => {
		let handled = false;

		if (props.onTileSelected && event.buttons === 1) {
			handled = props.onTileSelected(props.label, tilePosition);
		}
		if (props.onPointerDown) {
			handled = props.onPointerDown(event, tilePosition) || handled;
		}
		return handled;
	};
	const onPointerUp = (event, tilePosition) => {
		if (props.onPointerUp) {
			return props.onPointerUp(event, tilePosition);
		}
	};
	const onPointerMove = (event, position) => {
		if (props.onPointerMove) {
			return props.onPointerMove(event, position);
		}
		if (props.onTileHover && props.highlightState === 'highlight') {
			return props.onTileHover(position);
		}
	};
	const { label, orientation, position, size } = props;
	const tiles = [];
	const staircaseFloor = (x, y, z) => {
		return FloorHeight(props, x, y) === z;
	};
	const staircaseCeiling = (x, y, z) => {
		return CeilingHeight(props, x, y) === z;
	};
	const getRoomSides = (x, y, z) => {
		return {
			north: y === size[1] - 1 ? 'wall' : 'void',
			east: x === size[0] - 1 ? 'wall' : 'void',
			south: y === 0 ? 'wall' : 'void',
			west: x === 0 ? 'wall' : 'void',
			floor: z === 0 ? 'floor' : 'void',
			ceiling: z === size[2] - 1 ? 'ceiling' : 'void'
		};
	};
	const getStaircaseSides = (x, y, z) => {
		return {
			north: y === size[1] - 1 && staircaseFloor(x, y, z) && (orientation === 'x+' || orientation === 'x-') ||
				!staircaseFloor(x, y, z) && (y === size[1] - 1 || ExcludesBox(props, [x, y + 1, z])) ? 'wall' : 'void',
			east: x === size[0] - 1 && staircaseFloor(x, y, z) && (orientation === 'y+' || orientation === 'y-') ||
				!staircaseFloor(x, y, z) && (x === size[0] - 1 || ExcludesBox(props, [x + 1, y, z])) ? 'wall' : 'void',
			south: y === 0 && staircaseFloor(x, y, z) && (orientation === 'x+' || orientation === 'x-') ||
				!staircaseFloor(x, y, z) && (y === 0 || ExcludesBox(props, [x, y - 1, z])) ? 'wall' : 'void',
			west: x === 0 && staircaseFloor(x, y, z) && (orientation === 'y+' || orientation === 'y-') ||
				!staircaseFloor(x, y, z) && (x === 0 || ExcludesBox(props, [x - 1, y, z])) ? 'wall' : 'void',
			floor: staircaseFloor(x, y, z) ? 'stair' : 'void',
			ceiling: staircaseCeiling(x, y, z) || ExcludesBox(props, [x, y, z + 1]) ? 'ceiling' : 'void'
		};
	};
	const onDecorationSelected = (event, decoration) => {
		event.stopPropagation();
		props.setSelectedDecoration(decoration, props.featureKey);
	};
	const decorations = Object.keys(props.decorations || {}).map(decorationKey => {
		const decoration = props.decorations[decorationKey];

		return <Decoration
			key={decorationKey} featurePosition={position} readonly={props.readonly}
			sceneKey={props.sceneKey} featureKey={props.featureKey} decorationKey={decorationKey}
			decoration={decoration} selected={props.selectedDecoration?.decoration === decorationKey}
			onDraggingChanged={props.onDraggingChanged} onDecorationSelected={onDecorationSelected}/>;
	});

	for (let x = 0; x < size[0]; ++x) {
		for (let y = 0; y < size[1]; ++y) {
			for (let z = 0; z < size[2]; ++z) {
				if (props.type === 'staircase' && ExcludesBox(props, [x, y, z]))
					continue;

				const sides = props.type === 'staircase' ? getStaircaseSides(x, y, z) : getRoomSides(x, y, z);
				const tilePosition = [position[0] + x, position[1] + y, position[2] + z];
				const key = `${label}tile${tiles.length}`;
				const tileDecorations = {};
				const boxKey = `${x}_${y}_${z}`;
				const box = props.boxes && props.boxes[boxKey] || {};
				const theme = box.theme || props.theme;

				tiles.push(<Suspense key={key} fallback={null}>
					<Tile
						theme={theme}
						staircase={props.type === 'staircase' && staircaseFloor(x, y, z)}
						label={key} position={tilePosition} orientation={orientation} sides={{...sides, ...box.sides}}
						sceneKey={props.sceneKey} featureKey={props.featureKey}
						decorations={tileDecorations}
						onDraggingChanged={props.onDraggingChanged}
						onPointerDown={onPointerDown}
						onPointerUp={onPointerUp}
						onPointerMove={onPointerMove}
					/>
				</Suspense>);
			}
		}
	}
	return (
		<group key={label}>
			<WhyDidYouUpdate {...props}/>
			{tiles}
			{decorations}
		</group>
	);
}

const mapStateToProps = store => ({
	highlightState: selectHighlightState(store),
	selectedDecoration: selectSelectedDecoration(store)
});

export default connect(mapStateToProps, {setSelectedDecoration})(TiledRoom);
