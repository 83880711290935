import PlayScene from './PlayScene';
import {Canvas} from 'react-three-fiber';
import React, {useEffect, useState} from 'react';
import {connect, ReactReduxContext} from 'react-redux';
import {useContextBridge} from '@react-three/drei';
import {selectAppAuthentication, selectHighlightState, selectHighlightTile, selectViewMode}
	from '../../selectors/state/app';
import {selectFirebaseCharacters, selectFirebaseScenario, selectFirebaseScenes} from '../../selectors/state/firebase';
import {setViewMode} from '../../actions/appActions';
import {Box, fade, makeStyles, Typography} from '@material-ui/core';
import Hud from '../hud/Hud';
import Chat from '../chat/Chat';
import '../../css/view.css';
import ScreenShot from '../screenshot/ScreenShot';
import Flash from '../screenshot/Flash';
import Review from '../screenshot/Review';
import {trackFeatures, untrackFeatures} from '../../actions/featureActions';

const useStyles = makeStyles(() => ({
	annotation: {
		position: 'absolute',
		top: 60,
		right: 0,
		width: '20%',
		height: 200,
		padding: 10,
		color: 'white',
		backgroundColor: fade('#000', 0.5)
	}
}));

function PlayView(props) {
	const ContextBridge = useContextBridge(ReactReduxContext);
	const [infoBox, setInfoBox] = useState(null);
	const [scene, setScene] = useState(null);
	const classes = useStyles();
	const [photo, setPhoto] = useState(false);

	useEffect(() => {
		setScene(props.scenes && props.scenes[props.viewMode.data.sceneKey]);
	}, [props.scenes]);
	useEffect(() => {
		if (! scene) return;
		if (props.highlightState === 'highlight' && props.highlightTile) {
			const characters = scene.characters || {};
			const avatarKey = Object.keys(characters)
				.find(characterKey => props.characters[characterKey].user === props.authentication.key);
			const avatar = characters[avatarKey];
			const rangeX = Math.abs(Math.round(avatar.position[0] - props.highlightTile[0]));
			const rangeY = Math.abs(Math.round(avatar.position[1] - props.highlightTile[1]));
			const rangeOrthogonal = Math.max(rangeX, rangeY);
			const rangeHalfDiagonal = rangeOrthogonal + Math.floor(Math.min(rangeX, rangeY) / 2);
			const rangeActual = Math.sqrt(rangeX * rangeX + rangeY * rangeY).toPrecision(2);

			setInfoBox(
				<Box className={classes.annotation} m={1} p={1} border={1} borderRadius={16}>
					<Typography>Floor Tile</Typography>
					<Typography>X: {props.highlightTile[0]}</Typography>
					<Typography>Y: {props.highlightTile[1]}</Typography>
					<Typography>Orthogonal Range: {rangeOrthogonal}</Typography>
					<Typography>Half Diagonal Range: {rangeHalfDiagonal}</Typography>
					<Typography>Actual Range: {rangeActual}</Typography>
				</Box>
			);
		} else {
			setInfoBox(null);
		}
	}, [scene, props.highlightState, props.highlightTile]);
	useEffect(() => {
		props.trackFeatures(props.viewMode.data.sceneKey);
		return () => {
			props.untrackFeatures(props.viewMode.data.sceneKey);
		};
	}, []);
	if (! scene) return null;
	return (
		<>
			<div className='View PlayContents'>
				<Canvas shadowMap>
					<fog attach='fog' args={['black', 6]}/>
					<ContextBridge>
						<PlayScene scene={scene}/>
						<ScreenShot/>
					</ContextBridge>
				</Canvas>
			</div>
			<Hud title={scene.name}/>
			{infoBox}
			<Chat/>
			<Flash/>
			<Review prefix={scene.name}/>
		</>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	characters: selectFirebaseCharacters(store),
	highlightState: selectHighlightState(store),
	highlightTile: selectHighlightTile(store),
	scenario: selectFirebaseScenario(store),
	scenes: selectFirebaseScenes(store),
	viewMode: selectViewMode(store)
});

export default connect(mapStateToProps, {
	setViewMode,
	trackFeatures,
	untrackFeatures
})(PlayView);
