import {Box, Tab, Tabs, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import srd from '../../data/srd/srd5eBestiary.json';
import MonsterActions from '../monsters/srd/MonsterActions';
import MonsterStats from '../monsters/srd/MonsterStats';
import {makeStyles} from '@material-ui/core/styles';
import Scrollbars from '../portal/Scrollbars';

const useStyles = makeStyles(theme => ({
	content: {
		display: 'flex',
		height: '100%',
		pointerEvents: 'all'
	},
	tabs: {
		backgroundColor: 'rgba(0,0,0,0.4)',
		border: 'solid 1px',
		borderRadius: theme.shape.borderRadius,
		color: 'white',
		margin: theme.spacing(1),
		padding: theme.spacing(2),
		zIndex: theme.zIndex.modal
	},
	tab: {
		height: '100%'
	}
}));

function MonsterDetails(props) {
	const classes = useStyles();
	const monsterDetails = props.scene.monsters[props.monster];
	const monster = monsterDetails && srd.monsters[monsterDetails.monster];
	const [tab, setTab] = useState(0);
	const selectTab = (event, newTab) => {
		setTab(newTab);
	};
	const tabs = [
		<Box key='actions' m={1} className={classes.tab}>
			<Scrollbars>
				<MonsterActions monsterKey={props.monster} monster={monster} details={monsterDetails}/>
			</Scrollbars>
		</Box>,
		<Box key='stats' m={1} className={classes.tab}>
			<Scrollbars>
				<MonsterStats monster={monster}/>
			</Scrollbars>
		</Box>
	];

	if (! monster) return null;
	return (
		<Box className={classes.content}>
			<Box display='flex' flexDirection='column' className={classes.tabs}>
				<Typography variant='h6'>{monster.name}</Typography>
				<Tabs value={tab} onChange={selectTab}>
					<Tab label='Actions'/>
					<Tab label='Details'/>
				</Tabs>
				{tabs[tab]}
			</Box>
		</Box>
	);
}

const mapStateToProps = store => ({
});

export default connect(mapStateToProps, {
})(MonsterDetails);
