import React, {} from 'react';
import {connect} from 'react-redux';
import {makeStyles, Slide} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	panel: {
		display: 'flex',
		flexDirection: 'column'
	}
}));
function Panel(props) {
	const classes = useStyles();

	return (
		<Slide style={{display:props.visible ? 'block' : 'none'}} direction='left' in={props.visible} mountOnEnter unmountOnExit>
			<div className={classes.panel}>
				{props.children}
			</div>
		</Slide>
	);
}

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(Panel);
