import * as THREE from 'three';
import {useThree} from 'react-three-fiber';

function SkyBox(props) {
	const { scene } = useThree();
	const loader = new THREE.CubeTextureLoader();

	loader.load(props.textures, background => {
		scene.background = background;
	});
	return null;
}
export default SkyBox;
