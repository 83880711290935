import React from 'react';
import {Box, Typography} from '@material-ui/core';
import PanelItem from './PanelItem';

export default function TextItem(props) {

	return (
		<PanelItem>
			<Box m={2}>
				<Typography variant='body1'>
					{props.children}
				</Typography>
			</Box>
		</PanelItem>
	);
}
