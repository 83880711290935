import React from 'react';
import ImageLeftItem from './ImageLeftItem';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/social.jpg`;

export default function FriendItem(props) {
	const datePrefix = props.status === 'friend' ? 'Friend since' : 'Invited on';

	return (
		<ImageLeftItem
			image={props.image || DefaultImage} target={props.friendKey} onClick={props.onClick}
			title={props.friend.nickname} description={props.friend.bio} date={props.friend.timestamp}
			datePrefix={datePrefix} favourite={{key: props.friendKey, data: props.friendKey, view: 'friend'}}/>
	);
}
