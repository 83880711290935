import {
	FIREBASE_CREATE_CHARACTER,
	FIREBASE_DELETE_CHARACTER,
	FIREBASE_EXTEND,
	FIREBASE_SET,
	FIREBASE_TRACK_BY_CHILD,
	FIREBASE_TRACK_FRIEND_CHARACTERS
} from './firebaseActions';

export function createCharacter(name, avatar) {
	return {
		type: FIREBASE_CREATE_CHARACTER,
		name,
		avatar
	};
}

export function deleteCharacter(characterKey) {
	return {
		type: FIREBASE_DELETE_CHARACTER,
		characterKey
	};
}

export function setCharacterAnimation(sceneKey, characterKey, animation) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/characters/${characterKey}/animation`,
		value: animation
	};
}

export function firebaseSetCharacterBio(characterKey, value) {
	return {
		type: FIREBASE_SET,
		path: `characters/${characterKey}/bio`,
		value
	};
}

export function setCharacterDetails(sceneKey, characterKey, position, rotation, animation) {
	return {
		type: FIREBASE_EXTEND,
		path: `scenes/${sceneKey}/characters/${characterKey}`,
		member: [
			'firebase',
			'scenes',
			sceneKey,
			'characters',
			characterKey
		],
		value: {
			position,
			rotation,
			animation
		}
	};
}

export function firebaseSetCharacterModel(characterKey, value) {
	return {
		type: FIREBASE_SET,
		path: `characters/${characterKey}/model/key`,
		value
	};
}

export function setCharacterPosition(sceneKey, character, position) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/characters/${character}/position`,
		value: position
	};
}

export function setCharacterRotation(sceneKey, character, rotation) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/characters/${character}/rotation`,
		value: rotation
	};
}

export function trackUserCharacters(userKey) {
	return {
		type: FIREBASE_TRACK_BY_CHILD,
		path: 'characters',
		child: 'user',
		from: userKey,
		to: userKey
	};
}

export function trackFriendCharacters(user) {
	return {
		type: FIREBASE_TRACK_FRIEND_CHARACTERS,
		user
	};
}
