import {Box, Divider, fade, Fade, IconButton, makeStyles} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ChatPanel from './ChatPanel';
import {selectAppAuthentication, selectViewMode} from '../../selectors/state/app';
import {trackChat, untrackChat} from '../../actions/chatActions';
import {Maximize, ShortText, Sort} from '@material-ui/icons';
import ToolbarTabs from '../portal/items/ToolbarTabs';

const useStyles = makeStyles(() => ({
	body: {
		color: 'white',
		backgroundColor: fade('#000', 0.5),
		display: 'flex',
		flexDirection: 'column',
		width: '100%'
	},
	chat: {
		display: 'flex',
		flexGrow: 1,
		width: 400,
		pointerEvents: 'all'
	},
	header: { height: 60 },
	max: { height: '100%' },
	short: { height: 400 }
}));

function ChatHeader(props) {
	const classes = useStyles();
	const icons = {
		min: <Maximize/>,
		short: <ShortText/>,
		max: <Sort/>
	};

	if (props.state === 'min') {
		return (
			<Box className={classes.header} display='flex' flexDirection='row'>
				<IconButton color='inherit' onClick={props.changeState}>
					{icons.min}
				</IconButton>
				<Box flexGrow={1}/>
			</Box>
		);
	}
	return (
		<Box className={classes.header} display='flex' flexDirection='row'>
			<IconButton color='inherit' onClick={props.changeState}>{icons[props.state]}</IconButton>
			<Divider orientation='vertical' flexItem/>
			<ToolbarTabs key='tabs' tabs={props.tabs} selected={props.tab} setTab={props.changeTab}/>
		</Box>
	);
}
function Chat(props) {
	const [tab, setTab] = useState('Party');
	const [mouseOver, setMouseOver] = useState(false);
	const [recentMessage, setRecentMessage] = useState(false);
	const [state, setState] = useState('max');
	const handleChatUpdate = () => {
		setRecentMessage(true);
		setTimeout(() => {
			setRecentMessage(false);
		}, 2000);
	};
	const chatHeight = {
		min: 60,
		max: '100%',
		short: 200
	};
	const states = Object.keys(chatHeight);
	const handleChangeState = () => {
		setState(states[(states.indexOf(state) + 1) % states.length]);
	};
	const classes = useStyles();
	const chatPanels = {
		Party: <ChatPanel
			user={props.authentication.key} channel={props.viewMode.data.campaignKey}
			value={tab} index={0} chat='party' label='Party' onChatUpdate={handleChatUpdate}/>,
		World: <ChatPanel
			user={props.authentication.key} channel='world'
			value={tab} index={1} chat='world' label='World' onChatUpdate={handleChatUpdate}/>
	};

	useEffect(() => {
		props.viewMode && props.trackChat(props.viewMode.data.campaignKey);
		return () => {
			props.untrackChat();
		};
	}, [props.viewMode]);
	return (
		<Box>
			<div
				className={classes.chat} style={{ height: chatHeight[state] }}
				onMouseEnter={() => setMouseOver(true)}
				onMouseLeave={() => setMouseOver(false)}>
				<Fade in={mouseOver || recentMessage}>
					<Box className={classes.body} display='flex' m={1} p={1} border={1} borderRadius={16}>
						<ChatHeader changeState={handleChangeState} changeTab={setTab} state={state} tab={tab} tabs={chatPanels}/>
						{state !== 'min' && chatPanels[tab]}
					</Box>
				</Fade>
			</div>
		</Box>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	viewMode: selectViewMode(store)
});
export default connect(mapStateToProps, {
	trackChat,
	untrackChat
})(Chat);
