import {connect} from 'react-redux';
import {selectAppAdmin, selectAppAuthentication, selectAppUser} from '../../../selectors/state/app';
import {Box, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from '@material-ui/core';
import React from 'react';
import {Delete, People, Person, Whatshot} from '@material-ui/icons';
import {selectFirebaseModels, selectFirebaseUserModels} from '../../../selectors/state/firebase';

function AssignmentList(props) {
	const userDescriptions = {
		default: {title: 'All users', icon: <People/>},
		premium: {title: 'Premium users', icon: <Whatshot/>},
		[props.auth.key]: {title: 'User rule', icon: <Person/>}
	};
	const handleClick = event => {
		const details = props.assignments[event.currentTarget.dataset.key];
		const admin = props.auth.key !== details.user;

		props.setView('assignment', {assignmentKey: event.currentTarget.dataset.key, admin});
	};

	return (
		<Box>
			<List>
				{Object.keys(props.assignments)
					.filter(key => props.monsterKey === undefined || props.monster === undefined ||
						props.assignments[key].resolveMonster(props.monsterKey, props.monster))
					.map(key => {
						const assignment = props.assignments[key];
						const userDescription = userDescriptions[assignment.user];
						const editable = (props.auth.key === assignment.user) || props.admin;
	
						return (
							<ListItem key={key} button disabled={!editable} data-key={key} onClick={handleClick}>
								<ListItemIcon title={userDescription.title}>{userDescription.icon}</ListItemIcon>
								<ListItemText
									primary={assignment.describeAssignment(props.models, props.userModels)}
									secondary={assignment.describePredicate(props.monsters)}/>
								{editable &&
									<ListItemSecondaryAction>
										<IconButton data-key={key} onClick={props.removeAssignment}>
											<Delete/>
										</IconButton>
									</ListItemSecondaryAction>}
							</ListItem>
						);
					})}
			</List>
		</Box>
	);
}
const mapStateToProps = store => ({
	admin: selectAppAdmin(store),
	auth: selectAppAuthentication(store),
	models: selectFirebaseModels(store),
	user: selectAppUser(store),
	userModels: selectFirebaseUserModels(store)
});

export default connect(mapStateToProps, {
})(AssignmentList);
