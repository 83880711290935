import React, {useRef} from 'react';
import * as THREE from 'three';
import 'firebase/storage';
import {useGLTF} from '@react-three/drei';
import {Content} from '../../config';
//import {BoxHelper} from 'three';

const highlightScale = [1.02, 1.02, 1.02];

function HighlightModel(props) {
	const clone = props.model.clone(true);
	const getMeshes = parent => {
		if (parent.type === 'Mesh') {
			return [parent];
		} else {
			return parent.children.reduce((meshes, child) => {
				return meshes.concat(getMeshes(child));
			}, []);
		}
	};
	const material = new THREE.MeshBasicMaterial( { color: 0x00ff00, side: THREE.BackSide } );

	getMeshes(clone).forEach(mesh => {
		mesh.material = material;
	});
	return (
		<mesh
			{...props} scale={props.scale}>
			<primitive object={clone} dispose={null}/>
		</mesh>
	);
}

export default function Model(props) {
	const gltf = useGLTF(`${Content}/${props.path}`);
	const clone = gltf.scene.clone(true);
	const rotation = props.rotation || [0, 0, 0];
	const position = props.position || [0, 0, 0];
	const scale = props.scale || [1, 1, 1];
	const meshRef = useRef();
	const handlePointerDown = event => {
		props.onPointerDown(event);
	};

//	useHelper(meshRef, BoxHelper, 'cyan');
	return (
		<group position={position} rotation={rotation} scale={scale}>
			{props.selected && <HighlightModel model={gltf.scene} scale={highlightScale}/>}
			<mesh
				ref={meshRef}
				castShadow receiveShadow
				onPointerDown={handlePointerDown}>
				<primitive object={clone} dispose={null}/>
			</mesh>
		</group>
	);
}
