import React, {} from 'react';
import {selectUserImages} from '../../selectors/state/firebase';
import {connect} from 'react-redux';
import {CardMedia, makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
	avatar: { width: 200, height: 200, borderRadius: 100},
	banner: {height: 240},
	full: {height: 400},
	tile: {width: 160, height: 120},
	wrapper: {overflow: 'hidden', flexShrink: 0}
}));
const defaultTranslation = { x: 0, y: 0};
const defaultScale = 1;

function CardImage(props) {
	const classes = useStyles();
	const image = {
		scale: {
			avatar: defaultScale,
			banner: defaultScale,
			full: defaultScale,
			tile: defaultScale
		},
		translate: {
			avatar: defaultTranslation,
			banner: defaultTranslation,
			full: defaultTranslation,
			tile: defaultTranslation
		},
		url: props.image,
		...props.images[props.image]
	};
	const translate = props.translate || image.translate[props.format] || defaultTranslation;
	const scale = props.scale || image.scale[props.format] || defaultScale;
	const transformScale = `scale(${scale})`;
	const transformTranslate = `translate(${translate.x || 0}%, ${translate.y || 0}%)`;

	return (
		<div className={[classes.wrapper, classes[props.format]].join(' ')}>
			<CardMedia
				className={classes[props.format]} image={image.url}
				style={{transform: `${transformScale} ${transformTranslate}`}}/>
		</div>
	);

}

const mapStateToProps = store => ({
	images: selectUserImages(store)
});

export default connect(mapStateToProps, {})(CardImage);
