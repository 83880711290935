import {FIREBASE_PUSH_CHILD, FIREBASE_REMOVE, FIREBASE_SET, FIREBASE_TRACK_MONSTERS} from './firebaseActions';

export function setMonsterPosition(sceneKey, monsterKey, position) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/monsters/${monsterKey}/position`,
		value: position
	};
}

export function setMonsterRotation(sceneKey, monsterKey, rotation) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/monsters/${monsterKey}/rotation`,
		value: rotation
	};
}

export function setMonsterAnimation(sceneKey, monsterKey, animation) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/monsters/${monsterKey}/animation`,
		value: animation
	};
}

export function setMonsterStat(sceneKey, monsterKey, stat, value) {
	return {
		type: FIREBASE_SET,
		path: `scenes/${sceneKey}/monsters/${monsterKey}/stats/${stat}`,
		value
	};
}

export function trackMonsters(groups) {
	return {
		type: FIREBASE_TRACK_MONSTERS,
		groups
	};
}

export function setMonsterModel(key, value) {
	return {
		type: FIREBASE_SET,
		path: `monsters/${key}/model`,
		value
	};
}

export function setMonsterBio(key, value) {
	return {
		type: FIREBASE_SET,
		path: `monsters/${key}/bio`,
		value
	};
}

export const FIREBASE_TRACK_ASSIGNMENTS = 'FIREBASE_TRACK_ASSIGNMENTS';
export function trackAssignments(user) {
	return {type: FIREBASE_TRACK_ASSIGNMENTS, user};
}

export function addAssignment(user, rules, child) {
	return {
		type: FIREBASE_PUSH_CHILD,
		path: 'assignments',
		child: {...child, rules, user}
	};
}

export function removeAssignment(rule) {
	return {
		type: FIREBASE_REMOVE,
		path: `assignments/${rule}`
	};
}

export function updateAssignment(assignment, member, value) {
	return {
		type: FIREBASE_SET,
		path: `assignments/${assignment}/${member}`,
		value
	};
}
