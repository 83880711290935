import Drawer from './Drawer';
import {Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar} from '@material-ui/core';
import {
	Casino, ChevronLeft, Explore, Home, LibraryBooks, Map, MenuBook, NaturePeople, People, PhotoLibrary, Storefront,
	SupervisedUserCircle
} from '@material-ui/icons';
import React from 'react';
import {connect} from 'react-redux';

function LeftBar(props) {
	const handleIconClick = event => props.setView(event.currentTarget.dataset.target);

	return (
		<Drawer variant='permanent' open={props.open} width={props.drawerWidth}>
			<Toolbar style={{
				display: 'flex', alignItems: 'center', justifyContent: 'flex-end', px: [1]
			}}>
				<IconButton onClick={props.toggleOpen}>
					<ChevronLeft />
				</IconButton>
			</Toolbar>
			<Divider/>
			<List>
				<ListItem button data-target='home' onClick={handleIconClick}>
					<ListItemIcon>
						<Home color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Home"/>
				</ListItem>
				<ListItem data-target='shop' button onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<Storefront color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Shop"/>
				</ListItem>
				<ListItem data-target='models' button onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<NaturePeople color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Models"/>
				</ListItem>
				<ListItem data-target='social' button onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<SupervisedUserCircle color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Social"/>
				</ListItem>
				<ListItem data-target='gallery' button onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<PhotoLibrary color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Gallery"/>
				</ListItem>
				<Divider/>
				<ListItem button data-target='player' onClick={handleIconClick}>
					<ListItemIcon>
						<Casino color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Player"/>
				</ListItem>
				<ListItem button data-target='playing' onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<Explore color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Playing"/>
				</ListItem>
				<ListItem button data-target='characters' onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<People color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Characters"/>
				</ListItem>
				<Divider/>
				<ListItem button data-target='referee' onClick={handleIconClick}>
					<ListItemIcon>
						<MenuBook color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Referee"/>
				</ListItem>
				<ListItem button data-target='running' onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<Map color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Running"/>
				</ListItem>
				<ListItem button data-target='rules' onClick={handleIconClick}>
					<ListItemIcon style={{ marginLeft: 18 }}>
						<LibraryBooks color='primary'/>
					</ListItemIcon>
					<ListItemText primary="Rules"/>
				</ListItem>
			</List>
		</Drawer>
	);
}

const mapStateToProps = store => ({});

export default connect(mapStateToProps, {})(LeftBar);
