import React from 'react';
import {IconButton} from '@material-ui/core';
import ImageLeftItem from './ImageLeftItem';
import {ArrowDownward, ArrowUpward} from '@material-ui/icons';
import {connect} from 'react-redux';
import {updateScene} from '../../../actions/sceneActions';
import {selectAppAuthentication} from '../../../selectors/state/app';
import {Images} from '../../../config';

export const DefaultImage = `${Images}/ui/scene.jpg`;

function SceneItem(props) {
	const index = props.scenes?.findIndex(scene => scene.key === props.sceneKey);
	const handleClickMoveUp = event => {
		const target = props.scenes[index - 1];

		props.updateScene(target.key, 'order', index);
		props.updateScene(props.sceneKey, 'order', index - 1);
		event.stopPropagation();
	};
	const handleClickMoveDown = event => {
		const target = props.scenes[index + 1];

		props.updateScene(target.key, 'order', index);
		props.updateScene(props.sceneKey, 'order', index + 1);
		event.stopPropagation();
	};
	const toolbar = [];

	if (props.scenes && index !== 0) {
		toolbar.push(<IconButton key='moveUp' title='Move Up' aria-label='move up' onClick={handleClickMoveUp}>
			<ArrowUpward/>
		</IconButton>);
	}
	if (props.scenes && index !== props.scenes.length - 1) {
		toolbar.push(<IconButton key='moveDown' title='Move Down' aria-label='move down' onClick={handleClickMoveDown}>
			<ArrowDownward/>
		</IconButton>);
	}
	return (
		<ImageLeftItem
			image={props.scene.image || DefaultImage} target={props.sceneKey} onClick={props.onClick}
			toolbar={toolbar} title={props.scene.name} description={props.scene.description}
			date={props.scene.created} favourite={{
				key: props.sceneKey,
				view: props.auth.key === props.scene.user ? 'scene' : 'scenePlayer',
				data: {
					campaign: props.campaignKey,
					scenario: props.scenarioKey,
					scene: props.sceneKey
				}
			}}/>
	);
}

const mapStateToProps = store => ({
	auth: selectAppAuthentication(store),
});

export default connect(mapStateToProps, {
	updateScene
})(SceneItem);
