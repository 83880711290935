import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {Button, Typography} from '@material-ui/core';
import Panel from './Panel';
import Cover from '../items/Cover';
import {selectFirebaseFriends} from '../../../selectors/state/firebase';
import {DefaultImage} from '../items/FriendItem';
import {removeFriend} from '../../../actions/firebaseActions';
import {selectAppAuthentication} from '../../../selectors/state/app';

function FriendPanel(props) {
	if (props.view !== 'friend') return null;

	const friend = props.friends[props.data];
	const handleDropFriendClick = () => {
		props.removeFriend(props.authentication.key, props.data);
		props.setView('social');
	};

	useEffect(() => {
		props.setTitle(friend.nickname, ['social', 'player']);
	}, []);
	return (
		<Panel visible={true}>
			<Cover
				image={friend.image || DefaultImage}
				name={friend.nickname}
				toolbar={[
					<Button color='inherit' key='invite' onClick={handleDropFriendClick}>Drop Friend</Button>
				]}
			>
				<Typography variant='h5'>{friend.nickname}</Typography>
				<Typography variant='body1'>{friend.bio}</Typography>
			</Cover>
		</Panel>
	);
}

const mapStateToProps = store => ({
	authentication: selectAppAuthentication(store),
	friends: selectFirebaseFriends(store)
});

export default connect(mapStateToProps, {removeFriend})(FriendPanel);
